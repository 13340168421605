// @flow

import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { media } from 'Styles/style-utils'
import { fetchSimpleArticle } from 'Utils/prismic-configuration'
import { getLanguageData } from 'Utils/getLanguageData'

const Wrapper = styled.div`
	width: ${(props) => props.theme.desktophd.col10};

	a {
		width: 100%;
		display: flex;
		justify-content: space-between;
		text-align: center;
		margin: 2em 0;
	}

	${media.tablet`
    width: ${(props) => props.theme.tablet.col8};
    margin: ${(props) => props.theme.tablet.vSpace} 0;
  `}
	${media.mobile`
    a{
    flex-direction: column;
    }
    width: ${(props) => props.theme.mobile.col5};
    margin: ${(props) => props.theme.mobile.vSpace} 0;
  `}
`

// const ContentType = styled.p`
// 	${(props) => props.theme.desktophd.typeMixins.sans_p1};

// 	${media.tablet`
//     ${(props) => props.theme.tablet.sans_p1};
//   `}
// 	${media.mobile`
//     ${(props) => props.theme.mobile.typeMixins.sans_p1};
//   `}
// `

const ImageWrapper = styled.div`
	width: ${(props) => props.theme.desktophd.col5};
	img {
		width: 100%;
	}

	${media.tablet`
  width: ${(props) => props.theme.tablet.col4};
  `}
	${media.mobile`
  width: ${(props) => props.theme.mobile.col4};
  `}
`

const TextWrapper = styled.div`
	width: ${(props) => props.theme.desktophd.col5};
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	text-align: left;

	${media.tablet`
   width: ${(props) => props.theme.tablet.col4};
  `}
	${media.mobile`
   width: ${(props) => props.theme.mobile.col4};
  `}
`

const Title = styled.h3`
	margin-bottom: 14px;
`

const Subtitle = styled.div`
	${(props) => props.theme.desktophd.typeMixins.sans_p2};
	margin-bottom: 25px;
`

const AuthorName = styled.p`
	${(props) => props.theme.desktophd.typeMixins.sans_p4};
	color: ${(props) => props.theme.colors.lightdustypink};
`

class ArticleModuleFullWidth extends React.Component {
	_isMounted = false

	state = {}

	async componentDidMount() {
		this._isMounted = true
		const { uid } = this.props
		const raw = await fetchSimpleArticle(uid, this.props.api)
		const data = {}

		const { authorAvatar, authorName, thumbnail } = raw.english

		data.english = {
			uid,
			authorAvatar,
			authorName,
			thumbnail,
			title: raw.english.title || null,
			subtitle: raw.english.subtitle || null,
			blurb: raw.english.blurb || null,
			illustration_byline: raw.english.illustration_byline || null,
		}

		data.spanish = raw.spanish
			? {
					title: raw.spanish.title || null,
					subtitle: raw.spanish.subtitle || null,
					blurb: raw.spanish.blurb || null,
					illustration_byline: raw.spanish.illustration_byline || null,
			  }
			: null

		if (this._isMounted) {
			this.setState({ data })
		}
	}
	componentWillUnmount() {
		this._isMounted = false
	}

	render() {
		if (this.state.data) {
			const { uid, authorName, thumbnail } = this.state.data.english

			const { subtitle, title } = getLanguageData(
				this.state.data,
				this.props.language,
			)
			return (
				<Wrapper>
					<Link to={`/articles/${uid}`}>
						<ImageWrapper>
							<img src={thumbnail} alt="" />
						</ImageWrapper>

						<TextWrapper>
							{/* <ContentType>Article</ContentType> */}
							<Title>{title}</Title>
							<Subtitle>{subtitle}</Subtitle>
							<AuthorName>{authorName}</AuthorName>
						</TextWrapper>
					</Link>
				</Wrapper>
			)
		} else {
			return null
		}
	}
}

export default ArticleModuleFullWidth
