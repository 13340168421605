import React, { Component, Fragment } from 'react'
import Prismic from 'prismic-javascript'
import styled from 'styled-components'
import Filter from 'Components/Filter'
import { ResultThumb } from 'Components/SearchResults'
import { getText } from 'Utils/translatedText'

import {
	apiEndpoint,
	fetchTagData,
	fetchRelatedArticles,
	fetchRelatedResources,
} from 'Utils/prismic-configuration'

// TODO: HAVE VARIABLE FOR PADDING-TOP;
// TODO: FIGURE OUT MICHAEL'S SPACING SYSTEM

const ViewContainer = styled.div`
	${(props) => props.theme.layout.container};
`

const GridContainer = styled.div`
	${(props) => props.theme.layout.gridContainer};
`

const PageSubtitle = styled.h1`
	${(props) => props.theme.responsiveType.pageSubtitle};
	color: black;
`

const PageTitle = styled.h1`
	${(props) => props.theme.responsiveType.pageTitle};
`

const FilterWrap = styled.div`
	margin-top: 2.25rem;
	margin-bottom: 2.125rem;
	display: flex;
`
const FilterItem = styled.button`
	${(props) => props.theme.buttonMixins.filterButton};
	color: ${(props) => (props.active ? 'white' : 'black')};
	background-color: ${(props) => (props.active ? 'black' : 'white')};
`

class TopicDetail extends Component {
	_isMounted = false
	state = {}

	compare = (a, b) => {
		const { language } = this.props
		const contentA = a[language]
		const contentB = b[language]
		if (contentA && contentB) {
			if (contentA.title < contentB.title) return -1
			if (contentA.title > contentB.title) return 1
			return 0
		} else if (contentA && !contentB) {
			return 1
		} else if (contentB && !contentA) {
			return -1
		}
		return 0
	}

	pageTitle = null
	tagData = null

	async componentDidMount() {
		this._isMounted = true
		const uid = this.props.match.params.id

		const api = await Prismic.api(apiEndpoint)
		this.tagData = await fetchTagData(uid, api)

		if (this.props.language === 'english') {
			this.pageTitle = this.tagData ? this.tagData.english.title : null
		} else {
			this.pageTitle = this.tagData.spanish.title
		}

		// this speeds up the queries so they can happen at the same time
		const [queriedArticles, queriedResources] = await Promise.all([
			fetchRelatedArticles(uid),
			fetchRelatedResources(uid),
		])
		let data =
			queriedArticles && queriedResources
				? queriedArticles.concat(queriedResources)
				: null

		if (!data) {
			return null
		}

		// sort in ABC order depending on language
		data = data.sort(this.compare)

		if (this._isMounted) {
			this.setState({ data, pageTitle: this.pageTitle, tagData: this.tagData })
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.language !== this.props.language) {
			if (this.props.language === 'english') {
				this.pageTitle = this.tagData.english.title
			} else if (
				this.props.language === 'spanish' &&
				this.tagData.spanish &&
				this.tagData.spanish.title
			) {
				this.pageTitle = this.tagData.spanish.title
			} else this.pageTitle = null

			let data = this.state.data.sort(this.compare)

			this.setState({ data, pageTitle: this.pageTitle })
		}
	}

	componentWillUnmount() {
		this._isMounted = false
	}

	render() {
		if (this.state.data) {
			return (
				<ViewContainer>
					<PageSubtitle>
						{getText('links.topics', this.props.language)}
					</PageSubtitle>
					<PageTitle>
						{this.props.language === 'english'
							? this.state.tagData.english.title
							: this.state.tagData.spanish.title}
					</PageTitle>
					<Filter
						initialValue="all"
						render={({ currentFilterValue, setFilterValue }) => (
							<Fragment>
								<FilterWrap>
									<FilterItem
										active={currentFilterValue === 'all'}
										onClick={() => {
											setFilterValue('all')
										}}
									>
										{getText('all', this.props.language)}
									</FilterItem>
									<FilterItem
										active={currentFilterValue === 'article'}
										onClick={() => {
											setFilterValue('article')
										}}
									>
										{getText('articles', this.props.language)}
									</FilterItem>
									<FilterItem
										active={currentFilterValue === 'resource'}
										onClick={() => {
											setFilterValue('resource')
										}}
									>
										{getText('resources', this.props.language)}
									</FilterItem>
								</FilterWrap>

								<GridContainer>
									{this.state.data
										.filter(
											(i) =>
												currentFilterValue === 'all' ||
												i.type === currentFilterValue,
										)
										.map((item, index) => {
											return (
												<ResultThumb
													language={this.props.language}
													content={item}
													type={item.type}
													key={item.uid}
												/>
											)
										})}
								</GridContainer>
							</Fragment>
						)}
					/>
				</ViewContainer>
			)
		} else return ''
	}
}

export default TopicDetail
