// @flow

import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
	width: ${(props) => props.theme.desktophd.col2};
	margin: 2em 0;
	background-color: lightpink;
	text-align: center;
`

const ResultThumbSmall = (props) => {
	const { title, thumbnail } = props.data
	return (
		<Wrapper>
			<img src={thumbnail} alt="" />
			<h1>{title}</h1>
		</Wrapper>
	)
}

export default ResultThumbSmall
