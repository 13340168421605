import React from 'react'
import styled from 'styled-components'
import { media } from 'Styles/style-utils'
import { getText } from 'Utils/translatedText'

const ViewContainer = styled.div`
	padding-top: 220px;
	margin: 0 auto;
	background: black;
	width: 100%;
	height: 100%;
	padding-top: 160px;

	.inner {
		width: ${(props) => props.theme.desktophd.col12};
		margin: 0 auto;

		${media.tablet`
      width: ${(props) => props.theme.tablet.col8};
    `};
		${media.mobile`
      width: ${(props) => props.theme.mobile.col5};
    `};
	}
`

const BodyContainer = styled.div`
	text-align: center;
	width: 100%;
	padding: 0;
	width: ${(props) => props.theme.desktophd.col12};
	margin-bottom: ${(props) => props.theme.desktophd.vSpace};
	h1 {
		text-align: center;
		color: white;
	}
	.iframe-wrapper {
		width: 100%;
		text-align: left;
		margin: 0 auto;
		iframe {
			width: 100%;
		}
		a {
			color: ${(props) => props.theme.colors.pink};
		}
	}
	${media.tablet`
    margin-bottom: ${(props) => props.theme.tablet.vSpace};
  `};
	${media.mobile`
    margin-bottom: ${(props) => props.theme.mobile.vSpace};
  `};
`

const Title = styled.h1`
	${(props) => props.theme.responsiveType.pageTitle};
	margin-bottom: 2rem !important;
`

const InteractiveModel = (props) => {
	return (
		<ViewContainer>
			<div className="inner">
				<BodyContainer>
					<Title>{getText('links.3dPussy', props.language)}</Title>
					<div className="iframe-wrapper">
						<iframe
							title="Interactive Model"
							id="embedded-human"
							frameBorder="0"
							width="600"
							height="450"
							allowFullScreen={true}
							src="https://human.biodigital.com/widget/?be=2wO0&background.colors=1,0.949,0.949&ui-info=true&ui-fullscreen=false&ui-zoom=true&ui-share=false&load-rotate=90&initial.button=true&camera=35.645,70.237,-152.572,0.014,122.374,-6.627,0.112,0.891,-0.439&dk=e8394b04ab3f831ed511848735672e69f666a66a"
						/>
					</div>
				</BodyContainer>
			</div>
		</ViewContainer>
	)
}

export default InteractiveModel
