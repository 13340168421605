import React from 'react'
import styled from 'styled-components'
import { fetchModule } from 'Utils/prismic-configuration'
import { media } from 'Styles/style-utils'

const MailingListModuleWrapper = styled.div`
	width: ${(props) => (props.width === 'full' ? '100%' : '50%')};
	min-height: 400px;
	background-color: ${(props) => props.theme.colors.darkGray};
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;

	${media.tablet`
    margin-bottom: ${(props) => props.theme.tablet.vSpace};
  `}

	${media.mobile`
    width: 100%;
    margin: ${(props) => props.theme.mobile.vSpace} 0;
  `}

  p {
		${(props) => props.theme.desktophd.typeMixins.sans_p2};
		color: white;

		${media.tablet`
      ${(props) => props.theme.desktophd.typeMixins.sans_p3};
      text-align: center;
    `}
	}
`

const Form = styled.form`
	margin-top: 48px;
	display: flex;
	justify-content: center;
	max-width: 90%;

	${media.tablet`
    width: ${(props) => props.theme.mobile.col5};
  `}

	${media.mobile`
    width: ${(props) => props.theme.mobile.col5};
  `}

  input {
		width: 305px;
		background-color: ${(props) => props.theme.colors.middleGray};
		color: white;
		padding-left: 24px;
		border-radius: 4px 0px 0px 4px;
		${(props) => props.theme.desktophd.typeMixins.sans_p2};

		${media.tablet`
      padding-left: 16px;
      ${(props) => props.theme.desktophd.typeMixins.sans_p5};
      width: 80%;
      min-width: 60%;
    `}

		${media.mobile`
      padding-left: 16px;
    `}

    ::placeholder {
			color: white;
		}
	}
`

const Button = styled.button`
	${(props) => props.theme.buttonMixins.buttonOne};
	border-radius: 0 4px 4px 0;
	${media.tablet`
      ${(props) => props.theme.desktophd.typeMixins.sans_p5};
      width: 40%;
      padding: 14px 16px;
  `}
`

class MailingListModule extends React.Component {
	_isMounted = false
	state = {}

	async componentDidMount() {
		this._isMounted = true

		const raw = await fetchModule('newsletter')

		let cta
		let placeholder
		let form_cta

		// read the state and determine whether or not the app is in english or spanish, return the data appropriately
		if (this.props.language === 'english') {
			cta = raw.english.cta[0].text
			placeholder = raw.english.placeholder[0].text
			form_cta = raw.english.form_cta[0].text
		} else if (this.props.language === 'spanish') {
			cta = raw.spanish.cta ? raw.spanish.cta[0].text : undefined
			placeholder = raw.spanish.placeholder
				? raw.spanish.placeholder[0].text
				: undefined
			form_cta = raw.spanish.form_cta ? raw.spanish.form_cta[0].text : undefined
		}

		const data = {
			cta,
			form_cta,
			placeholder,
		}

		if (this._isMounted) {
			this.setState({ data })
		}
	}

	componentWillUnmount() {
		this._isMounted = false
	}

	render() {
		if (this.state.data) {
			const { cta, form_cta, placeholder } = this.state.data
			return (
				<MailingListModuleWrapper width={this.props.width}>
					<label htmlFor="tlemail">
						<p>{cta}</p>
					</label>
					<Form
						action="https://tinyletter.com/Pwordpedia"
						method="post"
						onSubmit="window.open('https://tinyletter.com/Pwordpedia', 'popupwindow', 'scrollbars=yes,width=800,height=600');return true"
						target="popupwindow"
					>
						<input
							type="text"
							name="email"
							id="tlemail"
							placeholder={placeholder}
						/>
						<Button type="submit">{form_cta}</Button>
					</Form>
				</MailingListModuleWrapper>
			)
		} else {
			return null
		}
	}
}

export default MailingListModule
