import React from 'react'
import styled from 'styled-components'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from 'Utils/prismic-configuration'
import { generateKey } from 'Utils/helpers'
import { media } from 'Styles/style-utils'

const NotesWrap = styled.div`
	width: ${(props) => props.theme.desktophd.col6};
	margin: ${(props) => props.theme.desktophd.vHeight} auto;
	margin-bottom: 0;

	.notes-title {
		${(props) => props.theme.desktophd.header_7}
		margin-bottom: 1rem;
		display: block;
		width: 100%;

		${media.tablet`
      display: none;
    `}
	}

	${media.tablet`
    margin: ${(props) => props.theme.tablet.vHeight} auto;
    margin-left: auto;
    margin-right: ${(props) => props.theme.tablet.marginHorizontal};
    width: ${(props) => props.theme.tablet.col7};
  `};
	${media.mobile`
    margin: ${(props) => props.theme.mobile.vHeight} auto;
      width: ${(props) => props.theme.mobile.col5};
  `};
`

const NoteWrap = styled.div`
	margin: 10px 0;
	font-size: 14px;
	display: flex;
	word-break: break-word;
`

const Index = styled.div`
	display: block;
	margin-right: 0.5rem;
	color: ${(props) => props.theme.colors.darkpink};
	word-break: normal;
	width: 24px !important;

	p {
		font-family: ${(props) => props.theme.fontFamily.plain};
		margin-top: 0 !important;
		margin-bottom: 0 !important;
		font-size: 14px;
		line-height: 21px;
	}
`
const Note = styled.div`
	display: inline-block;
	color: black;
	max-width: 100%;
	width: calc(100% - 30px);

	* {
		font-family: ${(props) => props.theme.fontFamily.plain};
	}

	a {
		color: ${(props) => props.theme.colors.darkpink};
		word-wrap: break-word;
	}

	* {
		font-size: 14px;
		line-height: 21px;
	}

	div {
		display: inline;
	}

	& > p {
		margin-top: 0 !important;
	}

	&:last-of-type {
		p {
			margin-bottom: 0;
		}
	}

	${media.tablet`

  `}
`

const Notes = (props) => {
	let { notes } = props
	if (notes) {
		return (
			<NotesWrap>
				<div className="notes-title">
					<h3>Sources</h3>
				</div>
				{notes.map((n, index) => {
					if (n.note[0] && n.note[0].text) {
						return (
							<NoteWrap key={generateKey(n.note[0].text)}>
								<Index>
									<p>{index + 1}.</p>
								</Index>
								<Note> {RichText.render(n.note, linkResolver)}</Note>
							</NoteWrap>
						)
					} else {
						return null
					}
				})}
			</NotesWrap>
		)
	} else return null
}

export default Notes
