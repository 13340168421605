import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
	margin: 2rem;
	width: ${(props) => props.theme.desktophd.col4};
	margin-right: ${(props) => props.theme.desktophd.col2};
	float: right;
	img {
		width: 100%;
	}
`

const ImageInline = (props) => {
	if (props.img) {
		return (
			<Wrapper>
				<img src={props.img} alt={props.img} />
			</Wrapper>
		)
	} else return null
}

export default ImageInline
