import React from 'react'
import { RichText } from 'prismic-reactjs'
import styled from 'styled-components'

import { linkResolver } from 'Utils/prismic-configuration'

const SidebarWrap = styled.div`
	margin: 2em;
	width: ${(props) => props.theme.desktophd.col4};
	margin-right: ${(props) => props.theme.desktophd.col2};
	background-color: black;
	color: white;
	float: right;
	padding: 36px 48px;
	li {
		${(props) => props.theme.desktophd.typeMixins.sans_p3};
	}
`

const Sidebar = (props) => {
	return <SidebarWrap>{RichText.render(props.text, linkResolver)}</SidebarWrap>
}

export default Sidebar
