import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { fetchTags } from 'Utils/prismic-configuration'
import { media } from 'Styles/style-utils'
import Filter from 'Components/Filter'

const ViewContainer = styled.div`
	${(props) => props.theme.layout.container};
	padding-bottom: ${(props) => props.theme.desktophd.vHeight};
	${media.tablet`
    padding-bottom: ${(props) => props.theme.tablet.vHeight};
  `}
	${media.mobile`
    padding-bottom: ${(props) => props.theme.mobile.vHeight};
  `}
`

const Title = styled.h1`
	${(props) => props.theme.responsiveType.pageTitle};
	margin-bottom: 2.25rem;
`

const ViewInner = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-start;
	${media.tablet`
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `}
`

const TagContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	width: ${(props) => props.theme.desktophd.col6};
`

const TagName = styled.h3`
	${(props) => props.theme.desktophd.headerMixins.header_4};
	margin-bottom: 1.5rem;
	color: ${(props) => props.theme.colors.dustypink};
	${media.tablet`
    ${(props) => props.theme.desktophd.headerMixins.header_6};
      margin-bottom: 1rem;
  `}
	${media.mobile`
    ${(props) => props.theme.desktophd.headerMixins.header_7};
  `}
`

const FilterButton = styled.button`
	display: none;
	${media.mobile`
    ${(props) => props.theme.buttonMixins.filterButton};
    display: block;
    background: black;
    color: white;
    width: 100%;
    height: 48px;
    margin-bottom: ${(props) => props.theme.mobile.vSpace};
  `}
`

const FilterContainer = styled.div`
	width: ${(props) => props.theme.desktophd.col3};
	margin-right: ${(props) => props.theme.desktophd.gutter};
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;

	${media.tablet`
    width: 100%;
    flex-direction: row;
    margin-right: 0;
    justify-content: center;
    margin-bottom: ${(props) => props.theme.tablet.vSpace};
  `}

	${media.mobile`
    flex-direction: column;
    position: fixed;
    z-index: 11;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    background: black;
    justify-content: center;
    visibility: ${(props) => (props.shouldDisplay ? 'visible' : 'hidden')};
    opacity: ${(props) => (props.shouldDisplay ? '1' : '0')};
    transition: .5s opacity;
  `}
`

const FilterTitle = styled.h2`
	display: none;
	color: white;
	margin-bottom: ${(props) => props.theme.tablet.vSpace};

	${media.mobile`
    display: block;
    margin-bottom: ${(props) => props.theme.mobile.vSpace};
  `}
`

const FilterItem = styled.button`
	${(props) => props.theme.buttonMixins.filterButton};
	color: ${(props) => (props.active ? 'white' : 'black')};
	background-color: ${(props) => (props.active ? 'black' : 'transparent')};
	margin-bottom: 0.5rem;

	${media.mobile`
    margin-bottom: 0;
    color: ${(props) => (props.active ? 'black' : 'white')};
    background-color: ${(props) => (props.active ? 'white' : 'black')};
  `}
`

class Tags extends Component {
	state = {
		filterExpanded: false,
		allTags: [],
	}

	onResize = () => {
		if (window.innerWidth > 768 && this.state.filterExpanded) {
			this.setState({ filterExpanded: false })
		}
	}

	filterAndSortTags = (allTags = this.state.allTags) => {
		const { language } = this.props
		const tags = allTags
			.filter((t) => Boolean(t[language]))
			.sort((a, b) => {
				const titleA = a[language].title
				const titleB = b[language].title
				if (titleA < titleB) return -1
				if (titleA > titleB) return 1
				return 0
			})
		return tags
	}

	async componentDidMount() {
		window.addEventListener('resize', this.onResize)
		const allTags = await fetchTags()
		this.setState({ allTags, tags: this.filterAndSortTags(allTags) })
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.language !== this.props.language) {
			this.setState({
				tags: this.filterAndSortTags(),
			})
		}
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.onResize)
	}

	createArrayAtoZ = () => {
		return Array.apply(null, { length: 26 }).map((x, i) =>
			String.fromCharCode(65 + i),
		)
	}

	toggleFilter = () => {
		if (window.innerWidth < 768) {
			this.setState({ filterExpanded: !this.state.filterExpanded })
			this.props.toggleFilterNavMobile()
		}
	}

	renderFilterItems = (currentFilterValue, setFilterValue, tags) => (
		<Fragment>
			<FilterButton onClick={this.toggleFilter}>Filter</FilterButton>
			<FilterContainer
				onClick={this.toggleFilter}
				shouldDisplay={this.state.filterExpanded}
			>
				<FilterTitle>Filter By</FilterTitle>
				<FilterItem
					active={
						currentFilterValue[0] === 'A' && currentFilterValue[1] === 'Z'
					}
					onClick={() => {
						setFilterValue(['A', 'Z'])
					}}
				>
					All
				</FilterItem>
				<FilterItem
					active={
						currentFilterValue[0] === 'A' && currentFilterValue[1] === 'F'
					}
					onClick={() => {
						setFilterValue(['A', 'F'])
					}}
				>
					A - F
				</FilterItem>
				<FilterItem
					active={
						currentFilterValue[0] === 'G' && currentFilterValue[1] === 'K'
					}
					onClick={() => {
						setFilterValue(['G', 'K'])
					}}
				>
					G - K
				</FilterItem>
				<FilterItem
					active={
						currentFilterValue[0] === 'L' && currentFilterValue[1] === 'Q'
					}
					onClick={() => {
						setFilterValue(['L', 'Q'])
					}}
				>
					L - Q
				</FilterItem>
				<FilterItem
					active={
						currentFilterValue[0] === 'R' && currentFilterValue[1] === 'V'
					}
					onClick={() => {
						setFilterValue(['R', 'V'])
					}}
				>
					R - V
				</FilterItem>
				<FilterItem
					active={
						currentFilterValue[0] === 'W' && currentFilterValue[1] === 'Z'
					}
					onClick={() => {
						setFilterValue(['W', 'Z'])
					}}
				>
					W - Z
				</FilterItem>
			</FilterContainer>

			<TagContainer>
				{tags
					.filter((i) => {
						const alphabet = this.createArrayAtoZ()
						const indexOne = alphabet.indexOf(currentFilterValue[0])
						const indexTwo = alphabet.indexOf(currentFilterValue[1]) + 1
						const filterAlphabet = alphabet.slice(indexOne, indexTwo)

						if (this.props.language === 'english') {
							return filterAlphabet.indexOf(i.english.title.charAt(0)) > -1
						} else if (
							this.props.language === 'spanish' &&
							i.spanish &&
							i.spanish.title
						) {
							return filterAlphabet.indexOf(i.spanish.title.charAt(0)) > -1
						} else {
							return null
						}
					})
					.map((tag, index) => {
						const to = `/tags/${tag.uid}`
						const title = tag[this.props.language].title || tag.english.title
						return (
							<Link key={tag.uid} to={to}>
								<TagName>{title}</TagName>
							</Link>
						)
					})}
			</TagContainer>
		</Fragment>
	)

	render() {
		if (!this.state.allTags) return null
		const tags = this.filterAndSortTags(this.state.allTags)
		if (tags) {
			return (
				<ViewContainer>
					<Title>Tags</Title>
					<ViewInner>
						<Filter
							language={this.props.language}
							initialValue={['A', 'Z']}
							render={({ currentFilterValue, setFilterValue }) =>
								this.renderFilterItems(currentFilterValue, setFilterValue, tags)
							}
						/>
					</ViewInner>
				</ViewContainer>
			)
		} else return null
	}
}

export default Tags
