import React, { Component } from 'react'
import styled from 'styled-components'
import Prismic from 'prismic-javascript'
import { apiEndpoint } from 'Utils/prismic-configuration'
import ResultsGrid from './ResultsGrid'
import { media } from 'Styles/style-utils'

const Page = styled.div`
	background-color: ${(props) => props.theme.colors.darkGray};
	width: 100vw;
	min-height: 100vh;
	padding-bottom: ${(props) => props.theme.spacing.vspace};
`

const Wrapper = styled.div`
	width: ${(props) => props.theme.desktophd.col12};
	margin: 0 auto;
	padding-top: calc(${(props) => props.theme.spacing.vspace} + 100px);
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-content: center;
	text-align: center;

	.input-wrapper {
		margin: 0 auto;
		width: 100%;
	}

	${media.tablet`
    width: ${(props) => props.theme.tablet.col8};
  `} ${media.mobile`
    width: ${(props) => props.theme.mobile.col5};
  `};
`

const Input = styled.input`
	background: ${(props) => props.theme.colors.middleGray}
		url(images/search_white.svg) no-repeat scroll 30px center;
	padding: 2rem;
	padding-left: 6rem;
	margin-bottom: ${(props) => props.theme.spacing.vspace};
	width: ${(props) => props.theme.desktophd.col10};
	${(props) => props.theme.desktophd.headerMixins.header_5};
	color: white;
	&:focus {
		color: black;
		background: ${(props) => props.theme.colors.lightpink}
			url(images/search_black.svg) no-repeat scroll 30px center;
	}

	${media.tablet`
    width: 100%;
    margin-bottom: ${(props) => props.theme.tablet.vSpace};
    ${(props) => props.theme.desktophd.headerMixins.header_6};
  `} ${media.mobile`
    width: ${(props) => props.theme.mobile.col5};
    margin-bottom: ${(props) => props.theme.mobile.vSpace};
    font-size: 18px;
    padding: 1rem;
    padding-left: 6rem;
  `};
`

const createTagsByNameMap = (results) =>
	new Map(
		results
			.map((result) =>
				result.data.tag && result.data.tag[0].text
					? [result.data.tag[0].text.toLowerCase(), result]
					: null,
			)
			.filter(Boolean),
	)

const createTagsByIdMap = (results) =>
	new Map(
		results
			.map((result) =>
				result.data.tag && result.data.tag[0].text ? [result.id, result] : null,
			)
			.filter(Boolean),
	)

const uniqueBy = (prop) => (list) => {
	const uniques = {}
	return list.reduce((result, item) => {
		if (uniques[item[prop]]) return result
		uniques[item[prop]] = item
		return [...result, item]
	}, [])
}

const uniqueByUid = uniqueBy('uid')

class Search extends Component {
	state = {
		results: [],
		tags: [],
		loading: false,
		searchQuery: '',
	}

	api = null

	async componentDidMount() {
		window.scrollTo(0, 0)
		this.api = await Prismic.api(apiEndpoint)
		this.queryTags()
		// this.totalSearchQuery()
	}

	async queryTags(page = 1, prevResults = []) {
		// fetch initial tags so we can get their IDs and map them to search terms
		const tagsResponse = await this.api.query(
			[Prismic.Predicates.at('document.type', 'tag')],
			{ pageSize: 100, page, lang: this.getLanguage() },
		)
		const { results } = tagsResponse
		const combinedResults = [...prevResults, ...results]
		if (tagsResponse.page < tagsResponse.total_pages) {
			this.queryTags(page + 1, combinedResults)
			return
		}
		this.tagsByName = createTagsByNameMap(combinedResults)
		this.tagsById = createTagsByIdMap(combinedResults)
	}

	getTagId(term) {
		const tag = this.tagsByName.get(term.toLowerCase())
		return tag ? tag.id : undefined
	}

	getTagName(id) {
		const tag = this.tagsById.get(id)
		return tag && tag.data.tag && tag.data.tag[0]
			? tag.data.tag[0].text
			: undefined
	}

	getTags(resource) {
		if (!resource.data.tags) return []
		return resource.data.tags
			.map((tagDoc) => this.getTagName(tagDoc.tag.id))
			.filter(Boolean)
	}

	componentDidUpdate(prevProps) {
		if (prevProps.language !== this.props.language) this.queryTags()
	}

	compare = (a, b) => {
		if (a.title < b.title) return -1
		if (a.title > b.title) return 1
		return 0
	}

	updateInputValue = (evt) => {
		if (this.searchTimeout) clearTimeout(this.searchTimeout)
		this.setState(
			{
				loading: true,
				searchQuery: evt.target.value,
			},
			() => {
				this.searchTimeout = setTimeout(() => this.totalSearchQuery(), 600)
			},
		)
	}

	totalSearchQuery = async () => {
		this.queryLang = this.props.language === 'spanish' ? 'es-mx' : 'en-us'

		const [articles, resources] = await Promise.all([
			this.articlesQuery(),
			this.resourcesQuery(),
		])
		const results = articles.concat(resources)
		let sortedResults = results.sort(this.compare)
		this.setState({ results: sortedResults, loading: false })
	}

	getLanguage() {
		return this.props.language === 'spanish' ? 'es-mx' : 'en-us'
	}

	articlesQuery = async () => {
		const { searchQuery } = this.state
		const tagId = this.getTagId(searchQuery)
		const textResponse = await this.api.query(
			[
				Prismic.Predicates.at('document.type', 'article'),
				Prismic.Predicates.fulltext('document', searchQuery),
			],
			{ pageSize: 100, lang: this.getLanguage() },
		)

		const tagsResponse = tagId
			? await this.api.query(
					[
						Prismic.Predicates.at('document.type', 'article'),
						Prismic.Predicates.at('my.article.tags.tag', tagId),
					],
					{ pageSize: 100, lang: this.getLanguage() },
			  )
			: null

		const allResults = tagsResponse
			? uniqueByUid([...textResponse.results, ...tagsResponse.results])
			: textResponse.results

		const articles = allResults.map((article) => {
			const thumbnail = article.data.thumbnail.url
			const title = article.data.title[0].text
			const tags = this.getTags(article)

			let uid
			const { type, id } = article
			if (this.props.language === 'english') {
				uid = article.uid
			} else if (
				article.alternate_languages &&
				article.alternate_languages[0] &&
				article.alternate_languages[0].uid
			) {
				uid = article.alternate_languages[0].uid
			} else {
				console.log('no alt language...')
				uid = 'none'
			}

			const articleData = { tags, thumbnail, title, type, uid, id }
			return articleData
		})
		return articles
	}

	resourcesQuery = async () => {
		const { searchQuery } = this.state
		const tagId = this.getTagId(searchQuery)
		const textResponse = await this.api.query(
			[
				Prismic.Predicates.at('document.type', 'resource'),
				Prismic.Predicates.fulltext('document', searchQuery),
			],
			{ pageSize: 100, lang: this.getLanguage() },
		)

		const tagsResponse = tagId
			? await this.api.query(
					[
						Prismic.Predicates.at('document.type', 'resource'),
						Prismic.Predicates.at('my.resource.tags.tag', tagId),
					],
					{ pageSize: 100, lang: this.getLanguage() },
			  )
			: null

		const allResults = tagsResponse
			? uniqueByUid([...textResponse.results, ...tagsResponse.results])
			: textResponse.results

		const resources = allResults.map((resource) => {
			const thumbnail = resource.data.thumbnail.url
			const tags = this.getTags(resource)
			const title = resource.data.title[0].text
			const resource_link = resource.data.resource_link.url
			const { type, uid } = resource
			const resourceData = { tags, thumbnail, title, type, uid, resource_link }
			return resourceData
		})

		return resources
	}

	render() {
		return (
			<Page>
				<Wrapper>
					<div className="input-wrapper">
						<Input type="text" onChange={this.updateInputValue} />
					</div>
					<ResultsGrid
						loading={this.state.loading}
						searchQuery={this.state.searchQuery}
						results={this.state.results}
					/>
				</Wrapper>
			</Page>
		)
	}
}

export default Search
