import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components'
import ReactCSSTransitionReplace from 'react-css-transition-replace'
import { globalStyles } from 'Styles/global'
import { theme } from 'Styles/themes'
import ReactGA from 'react-ga'
import About from './Views/About'
import ArticleDetail from './Views/ArticleDetail'
import Header from './Views/Header'
import Footer from './Views/Footer'
import NewsletterSuccess from './Views/NewsletterSuccess'
import Homepage from './Views/Homepage'
import InteractiveModel from './Views/InteractiveModel'
import Search from './Views/Search'
import Tags from './Views/Tags'
import TagDetail from './Views/TagDetail'
import TextOnly from './Views/TextOnly'
import Topics from './Views/Topics'
import TopicDetail from './Views/TopicDetail'
import Contributors from './Views/Contributors'
import Contributor from './Views/Contributor'
import DesktopHdGrid from 'Components/Layout'
import ScrollToTop from 'Components/ScrollToTop'
import { setCookie, getCookie } from 'Utils/cookie'

import {
	fetchContact,
	fetchContribute,
	fetchPussyDefinition,
	fetchPrivacyPolicy,
	fetchTermsOfUse,
} from 'Utils/prismic-configuration'

const GlobalStyles = createGlobalStyle`
	${globalStyles}
`

const GridToggle = styled.button`
	padding: 20px;
	background: red;
	color: white;
	position: fixed;
	bottom: 80px;
	right: 80px;
	z-index: 100000000;
	font-size: 14px;
	border-radius: 4px;
	display: none;
`

const PageWrap = styled.div`
	min-height: 100vh;
`

const LANG_COOKIE = 'pp_lang'

function getUserLang() {
	const userLang = (navigator.language || navigator.userLanguage).substring(
		0,
		2,
	)

	switch (userLang) {
		case 'es':
			return 'spanish'
		case 'en':
			return 'english'
		default:
			return null
	}
}

class App extends Component {
	state = {
		navExpanded: false,
		displayGrid: false,
		scrollNav: false,
		filterNavMobile: false,
		language: getCookie(LANG_COOKIE) || getUserLang() || 'english',
	}

	toggleFilterNavMobile = () => {
		this.setState((prevState) => ({
			filterNavMobile: !prevState.filterNavMobile,
		}))
	}

	toggleGrid = () => {
		this.setState((prevState) => ({
			displayGrid: !prevState.displayGrid,
		}))
	}

	toggleNav = () => {
		this.setState((prevState) => ({
			navExpanded: !prevState.navExpanded,
		}))
	}

	setLanguage = (event) => {
		setCookie(LANG_COOKIE, event.target.value)
		this.setState({ language: event.target.value })
	}

	onResize = () => {
		this.setState({ windowWidth: window.innerWidth })

		// custom logic to set filterNavMobile to false if window size goes past tablet or mobile
		if (
			this.props.location.pathname === '/resources' &&
			window.innerWidth > 1024
		) {
			this.setState({ filterNavMobile: false })
		} else if (
			this.props.location.pathname === '/tags' &&
			window.innerWidth > 768
		) {
			this.setState({ filterNavMobile: false })
		}
	}

	async componentDidMount() {
		let [
			contactData,
			contributeData,
			pussyDefinition,
			termsOfUse,
			privacyPolicy,
		] = await Promise.all([
			fetchContact(),
			fetchContribute(),
			fetchPussyDefinition(),
			fetchTermsOfUse(),
			fetchPrivacyPolicy(),
		])

		this.setState({ windowWidth: window.innerWidth })
		window.addEventListener('resize', this.onResize)

		this.setState({
			contactData,
			contributeData,
			pussyDefinition,
			termsOfUse,
			privacyPolicy,
		})
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.onResize)
	}

	render() {
		const { location } = this.props
		return (
			<ScrollToTop>
				<ReactCSSTransitionReplace
					transitionName="cross-fade"
					transitionEnterTimeout={500}
					transitionLeaveTimeout={500}
				>
					<div key={location.pathname}>
						<GridToggle onClick={this.toggleGrid}>Toggle Grid</GridToggle>
						<DesktopHdGrid display={this.state.displayGrid} />
						<Header
							{...this.props}
							filterNavMobile={this.state.filterNavMobile}
							inverted={
								location.pathname === '/search' ||
								location.pathname === '/interactive-model'
							}
							toggleNav={this.toggleNav}
							navExpanded={this.state.navExpanded}
							setLanguage={this.setLanguage}
							language={this.state.language}
							windowWidth={this.state.windowWidth}
						/>
						<PageWrap>
							<Switch location={location}>
								<Route
									path="/"
									exact
									render={(props) => (
										<Homepage
											{...props}
											slices={this.state.homeData}
											language={this.state.language}
										/>
									)}
								/>
								<Route
									path="/contributors"
									exact
									render={(props) => (
										<Contributors {...props} language={this.state.language} />
									)}
								/>

								<Route
									path="/contributors/:uid"
									exact
									render={(props) => (
										<Contributor {...props} language={this.state.language} />
									)}
								/>

								<Route
									path="/articles/:id"
									exact
									render={(props) => (
										<ArticleDetail {...props} language={this.state.language} />
									)}
								/>
								<Route
									path="/tags"
									exact
									render={(props) => (
										<Tags
											toggleFilterNavMobile={this.toggleFilterNavMobile}
											language={this.state.language}
											{...props}
										/>
									)}
								/>
								<Route
									path="/tags/:uid"
									exact
									render={(props) => (
										<TagDetail language={this.state.language} {...props} />
									)}
								/>
								<Route
									path="/topics"
									exact
									render={(props) => (
										<Topics {...props} language={this.state.language} />
									)}
								/>
								<Route
									path="/topics/:id"
									exact
									render={(props) => (
										<TopicDetail {...props} language={this.state.language} />
									)}
								/>
								<Route
									path="/search"
									exact
									render={(props) => <Search language={this.state.language} />}
								/>
								<Route
									path="/about"
									exact
									render={(props) => (
										<About
											data={this.state.aboutData}
											language={this.state.language}
											{...props}
										/>
									)}
								/>
								<Route
									path="/contact"
									exact
									render={(props) => (
										<TextOnly
											language={this.state.language}
											data={this.state.contactData}
											{...props}
										/>
									)}
								/>
								<Route
									path="/pussy-definition"
									exact
									render={(props) => (
										<TextOnly
											language={this.state.language}
											data={this.state.pussyDefinition}
											{...props}
										/>
									)}
								/>
								<Route
									path="/privacy-policy"
									exact
									render={(props) => (
										<TextOnly
											language={this.state.language}
											data={this.state.privacyPolicy}
											{...props}
										/>
									)}
								/>
								<Route
									path="/terms-of-use"
									exact
									render={(props) => (
										<TextOnly
											language={this.state.language}
											data={this.state.termsOfUse}
											{...props}
										/>
									)}
								/>
								<Route
									path="/contribute"
									exact
									render={(props) => (
										<TextOnly
											language={this.state.language}
											data={this.state.contributeData}
											{...props}
										/>
									)}
								/>
								<Route
									path="/newsletter-signup"
									exact
									render={(props) => (
										<NewsletterSuccess
											language={this.state.language}
											data={this.state.newsletterSuccess}
											{...props}
										/>
									)}
								/>
								<Route
									path="/interactive-model"
									exact
									render={(props) => (
										<InteractiveModel
											language={this.state.language}
											data={this.state.newsletterSuccess}
											{...props}
										/>
									)}
								/>
							</Switch>
						</PageWrap>

						<Footer
							language={this.state.language}
							setLanguage={this.setLanguage}
						/>
					</div>
				</ReactCSSTransitionReplace>
			</ScrollToTop>
		)
	}
}

ReactGA.initialize('UA-141905202-1')

class GAListener extends React.Component {
	componentDidMount() {
		if (
			window.location.hostname === 'pussypedia.net' ||
			window.location.hostname === 'www.pussypedia.net'
		) {
			this.sendPageView(this.props.history.location)
			this.props.history.listen(this.sendPageView)
		}
	}

	sendPageView(location) {
		ReactGA.set({ page: location.pathname })
		ReactGA.pageview(location.pathname)
	}

	render() {
		const { children } = this.props
		return children ? children : null
	}
}

const Main = () => (
	<ThemeProvider theme={theme}>
		<React.Fragment>
			<GlobalStyles />
			<Route
				render={(routeProps) => (
					<React.Fragment>
						<GAListener {...routeProps} />
						<App {...routeProps} />
					</React.Fragment>
				)}
			/>
		</React.Fragment>
	</ThemeProvider>
)

export default Main
