import React, { Component } from 'react'
import Prismic from 'prismic-javascript'
import styled from 'styled-components'
import { ResultThumb } from 'Components/SearchResults'
import { media } from 'Styles/style-utils'
import {
	apiEndpoint,
	fetchTagData,
	fetchRelatedArticles,
	fetchRelatedResources,
} from 'Utils/prismic-configuration'

const ViewContainer = styled.div`
	${(props) => props.theme.layout.container};
`

const GridContainer = styled.div`
	${(props) => props.theme.layout.gridContainer};
`

const PageSubtitle = styled.h1`
	${(props) => props.theme.responsiveType.pageSubtitle};
	color: black;
	margin-bottom: 0.8rem;
`

const PageTitle = styled.h1`
	${(props) => props.theme.responsiveType.pageTitle};
	margin-bottom: ${(props) => props.theme.desktophd.vHeight};

	${media.tablet`
      margin-bottom: ${(props) => props.theme.tablet.vHeight};
    `};

	${media.mobile`
      margin-bottom: ${(props) => props.theme.mobile.vHeight};
    `};
`

class TagDetail extends Component {
	state = {}

	compare = (a, b) => {
		const { language } = this.props
		const contentA = a[language]
		const contentB = b[language]
		if (contentA && contentB) {
			if (contentA.title < contentB.title) return -1
			if (contentA.title > contentB.title) return 1
			return 0
		} else if (contentA && !contentB) {
			return 1
		} else if (contentB && !contentA) {
			return -1
		}
		return 0
	}

	displayName = (tag) => {
		if (this.props.language === 'english') {
			return tag.englishData.tagRichText
		} else {
			if (tag.spanishData && tag.spanishData.tagName) {
				return tag.spanishData.tagRichText
			} else return null
		}
	}
	tagData = null
	pageTitle = null

	async componentDidMount() {
		const uid = this.props.match.params.uid
		const api = await Prismic.api(apiEndpoint)
		this.tagData = await fetchTagData(uid, api)

		if (this.props.language === 'english') {
			this.pageTitle = this.tagData.english.title
		} else if (
			this.props.language === 'spanish' &&
			this.tagData.spanish &&
			this.tagData.spanish.title
		) {
			this.pageTitle = this.tagData.spanish.title
		} else this.pageTitle = null

		const [queriedArticles, queriedResources] = await Promise.all([
			fetchRelatedArticles(uid),
			fetchRelatedResources(uid),
		])

		let data = queriedArticles.concat(queriedResources)

		// sort in ABC order depending on language
		data = data.sort(this.compare)

		this.setState({ data, pageTitle: this.pageTitle })
	}

	componentDidUpdate(prevProps, prevState) {
		// only update tag sorting if language changed
		if (prevProps.language !== this.props.language) {
			if (this.props.language === 'english') {
				this.pageTitle = this.tagData.english.title
			} else if (
				this.props.language === 'spanish' &&
				this.tagData.spanish &&
				this.tagData.spanish.title
			) {
				this.pageTitle = this.tagData.spanish.title
			} else this.pageTitle = null

			let data = this.state.data.sort(this.compare)

			this.setState({ data, pageTitle: this.pageTitle })
		}
	}

	render() {
		if (this.state.data) {
			return (
				<ViewContainer>
					<PageSubtitle>Tags</PageSubtitle>
					<PageTitle>{this.state.pageTitle}</PageTitle>

					<GridContainer>
						{this.state.data.map((item, index) => {
							const content = item[this.props.language]
							console.log(content)
							if (!content) return null
							return (
								<ResultThumb
									language={this.props.language}
									content={item}
									type={content.type}
									key={content.uid}
								/>
							)
						})}
					</GridContainer>
				</ViewContainer>
			)
		} else return null
	}
}

export default TagDetail
