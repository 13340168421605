import { css } from 'styled-components'
import { media } from 'Styles/style-utils'

export const theme = {
	mixins: {
		flex_row_wrap: css`
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;
		`,
	},
	buttonMixins: {
		buttonOne: css`
			padding: 15px 18px;
			border-radius: 4px;
			font-size: 18px;
			color: white;
			background: ${(props) => props.theme.colors.pink};
			font-family: ${(props) => props.theme.fontFamily.plain};
		`,
		buttonOneTablet: css`
			padding: 12px 14px;
			border-radius: 4px;
			font-size: 14px;
			color: white;
			background: ${(props) => props.theme.colors.pink};
			font-family: ${(props) => props.theme.fontFamily.plain};
		`,
		buttonTwo: css`
			padding: 20px 24px;
			border-radius: 4px;
			font-size: 12px;
		`,
		filterButton: css`
			${(props) => props.theme.desktophd.typeMixins.sans_p4};
			border-radius: 4px;
			padding: 4px 12px;
			margin: 0 5px;
		`,
	},
	colors: {
		darkGray: '#171313',
		lightGray: '#9b9b9b',
		middleGray: '#302828',
		red: '#E53D3D',
		darkpink: '#C33D64',
		dustypink: '#ba6a6e',
		lightdustypink: '#d3797d',
		pink: '#FF7E79',
		lightpink: '#FFF2F2',
		eggplant: '#302226',
		mauve: '#73444F',
		lightWatermelon: '#D6A9B2',
	},
	layout: {
		gridContainer: css`
			width: 100%;
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			column-gap: ${(props) => props.theme.desktophd.gutter};
			${media.tablet`
            grid-template-columns: 1fr 1fr;
          `};

			${media.mobile`
            grid-template-columns: 1fr;  
          `};
		`,
		container: css`
			text-align: center;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			padding: ${(props) => props.theme.desktophd.marginHorizontal};
			padding-top: 220px;

			${media.tablet`
                padding: ${(props) => props.theme.tablet.marginHorizontal};
                padding-top: 220px;
             `}

			${media.mobile`
              padding: ${(props) => props.theme.mobile.marginHorizontal};
              padding-top: 160px;
             `}
		`,
	},
	responsiveType: {
		pageTitle: css`
			${(props) => props.theme.desktophd.headerMixins.header_2};

			${media.tablet`
              ${(props) => props.theme.desktophd.headerMixins.header_4};
              font-size: 60px;
              margin-bottom: ${(props) => props.theme.tablet.vHeight};
            `};

			${media.mobile`
              ${(props) => props.theme.desktophd.headerMixins.header_4};
              margin-bottom: ${(props) => props.theme.mobile.vHeight};
            `};
		`,
		pageSubtitle: css`
			${(props) => props.theme.desktophd.typeMixins.sans_p0};
			color: ${(props) => props.theme.colors.pink};
			margin-bottom: 0.8rem;

			${media.tablet`
              ${(props) => props.theme.desktophd.headerMixins.sans_p2};
            `};

			${media.mobile`
              ${(props) => props.theme.desktophd.typeMixins.sans_p2};
            `};
		`,
	},
	desktophd: {
		x: '18px',
		vSpace: '5.33rem',
		col1: '5.5vw',
		col2: `${
			2 * 5.5 + (2 - 1) * 2.2 // colNum * 5.5 + (colNum - 1 ) * 2.2 // calculated width of column based on pixels, translated to VW
		}vw`,
		col3: `${3 * 5.5 + (3 - 1) * 2.2}vw`,
		col4: `${4 * 5.5 + (4 - 1) * 2.2}vw`,
		col5: `${5 * 5.5 + (5 - 1) * 2.2}vw`,
		col6: `${6 * 5.5 + (6 - 1) * 2.2}vw`,
		col7: `${7 * 5.5 + (7 - 1) * 2.2}vw`,
		col8: `${8 * 5.5 + (8 - 1) * 2.2}vw`,
		col9: `${9 * 5.5 + (9 - 1) * 2.2}vw`,
		col10: `${10 * 5.5 + (10 - 1) * 2.2}vw`,
		col11: `${11 * 5.5 + (11 - 1) * 2.2}vw`,
		col12: `${12 * 5.5 + (12 - 1) * 2.2}vw`,
		marginHorizontal: '4.9vw',
		gutter: '2.2vw',
		vHeight: '96px',
		headerMixins: {
			header_1: css`
				font-size: 87px;
				line-height: 116px;
				letter-spacing: 0.3px;
				font-family: ${(props) => props.theme.fontFamily.leituraRoman};
			`,
			header_2: css`
				font-size: 72px;
				line-height: 96px;
				letter-spacing: 0.3px;
				font-family: ${(props) => props.theme.fontFamily.leituraRoman};
			`,
			header_3: css`
				font-size: 60px;
				line-height: 80px;
				letter-spacing: 0.3px;
				font-family: ${(props) => props.theme.fontFamily.leituraRoman};
			`,
			header_4: css`
				font-size: 48px;
				line-height: 64px;
				letter-spacing: 0.3px;
				font-family: ${(props) => props.theme.fontFamily.leituraRoman};
			`,
			header_5: css`
				font-size: 40px;
				line-height: 56px;
				letter-spacing: 0.3px;
				font-family: ${(props) => props.theme.fontFamily.leituraRoman};
			`,
			header_6: css`
				font-size: 30px;
				line-height: 40px;
				letter-spacing: 0.3px;
				font-family: ${(props) => props.theme.fontFamily.leituraRoman};
			`,
			header_7: css`
				font-size: 24px;
				line-height: 32px;
				letter-spacing: 0.3px;
				font-family: ${(props) => props.theme.fontFamily.leituraRoman};
			`,
		},
		typeMixins: {
			sans_p0: css`
				font-size: 30px;
				line-height: 48px;
				letter-spacing: 0.4px;
				font-family: ${(props) => props.theme.fontFamily.plain};
			`,
			sans_p1: css`
				font-size: 24px;
				line-height: 48px;
				letter-spacing: 0.4px;
				font-family: ${(props) => props.theme.fontFamily.plain};
			`,
			sans_p2: css`
				font-size: 18px;
				letter-spacing: 0.4px;
				font-family: ${(props) => props.theme.fontFamily.plain};
			`,
			sans_p3: css`
				font-size: 16px;
				line-height: 24px;
				letter-spacing: 0.4px;
				font-family: ${(props) => props.theme.fontFamily.plain};
			`,
			sans_p4: css`
				font-size: 14px;
				line-height: 28px;
				letter-spacing: 0.4px;
				font-family: ${(props) => props.theme.fontFamily.plain};
			`,
			sans_p5: css`
				font-size: 12px;
				line-height: 24px;
				letter-spacing: 0.4px;
				font-family: ${(props) => props.theme.fontFamily.plain};
			`,
			serif_p1: css`
				font-size: 24px;
				line-height: 48px;
				letter-spacing: 0.3px;
				font-family: ${(props) => props.theme.fontFamily.leituraRoman};
			`,
			serif_p2: css`
				font-size: 18px;
				letter-spacing: 0.3px;
				font-family: ${(props) => props.theme.fontFamily.leituraRoman};
			`,
			serif_p3: css`
				font-size: 16px;
				line-height: 32px;
				letter-spacing: 0.3px;
				font-family: ${(props) => props.theme.fontFamily.leituraRoman};
			`,
			serif_p4: css`
				font-size: 14px;
				line-height: 28px;
				letter-spacing: 0.3px;
				font-family: ${(props) => props.theme.fontFamily.leituraRoman};
			`,
			serif_p5: css`
				font-size: 12px;
				line-height: 24px;
				letter-spacing: 0.3px;
				font-family: ${(props) => props.theme.fontFamily.leituraRoman};
			`,
			serif_p6: css`
				font-size: 10px;
				line-height: 20px;
				letter-spacing: 0.3px;
				font-family: ${(props) => props.theme.fontFamily.leituraRoman};
			`,
		},
	}, // begin tablet
	tablet: {
		x: '3.125vw',
		pageTitle: css`
			${(props) => props.theme.desktophd.headerMixins.header_2};
		`,
		vSpace: '75px',
		col1: '8.2vw',
		col2: `${
			2 * 8.2 + (2 - 1) * 3.125 // colNum * 8.2 + (colNum - 1 ) * 3.125 // calculated width of column based on pixels, translated to VW
		}vw`,
		col3: `${3 * 8.2 + (3 - 1) * 3.125}vw`,
		col4: `${4 * 8.2 + (4 - 1) * 3.125}vw`,
		col5: `${5 * 8.2 + (5 - 1) * 3.125}vw`,
		col6: `${6 * 8.2 + (6 - 1) * 3.125}vw`,
		col7: `${7 * 8.2 + (7 - 1) * 3.125}vw`,
		col8: `${8 * 8.2 + (8 - 1) * 3.125}vw`,
		marginHorizontal: '6.25vw',
		gutter: '3.125vw',
		vHeight: '75px',
		headerMixins: {
			header_1: css`
				font-size: 48px;
				line-height: 64px;
				letter-spacing: 0.3px;
				font-family: ${(props) => props.theme.fontFamily.leituraRoman};
			`,
			header_2: css`
				font-size: 72px;
				line-height: 96px;
				letter-spacing: 0.3px;
				font-family: ${(props) => props.theme.fontFamily.leituraRoman};
			`,
			header_3: css`
				font-size: 60px;
				line-height: 80px;
				letter-spacing: 0.3px;
				font-family: ${(props) => props.theme.fontFamily.leituraRoman};
			`,
			header_4: css`
				font-size: 48px;
				line-height: 64px;
				letter-spacing: 0.3px;
				font-family: ${(props) => props.theme.fontFamily.leituraRoman};
			`,
			header_5: css`
				font-size: 24px;
				line-height: 32px;
				letter-spacing: 0.3px;
				font-family: ${(props) => props.theme.fontFamily.leituraRoman};
			`,
			header_6: css`
				font-size: 30px;
				line-height: 40px;
				letter-spacing: 0.3px;
				font-family: ${(props) => props.theme.fontFamily.leituraRoman};
			`,
			header_7: css`
				font-size: 24px;
				line-height: 32px;
				letter-spacing: 0.3px;
				font-family: ${(props) => props.theme.fontFamily.leituraRoman};
			`,
			header_8: css`
				font-size: 36px;
				line-height: 48px;
				letter-spacing: 0.3px;
				font-family: ${(props) => props.theme.fontFamily.leituraRoman};
			`,
		},
		typeMixins: {
			sans_p0: css`
				font-size: 30px;
				line-height: 48px;
				letter-spacing: 0.4px;
				font-family: ${(props) => props.theme.fontFamily.plain};
			`,
			sans_p1: css`
				font-size: 24px;
				line-height: 48px;
				letter-spacing: 0.4px;
				font-family: ${(props) => props.theme.fontFamily.plain};
			`,
			sans_p2: css`
				font-size: 18px;
				letter-spacing: 0.4px;
				font-family: ${(props) => props.theme.fontFamily.plain};
			`,
			sans_p3: css`
				font-size: 16px;
				line-height: 24px;
				letter-spacing: 0.4px;
				font-family: ${(props) => props.theme.fontFamily.plain};
			`,
			sans_p4: css`
				font-size: 14px;
				line-height: 28px;
				letter-spacing: 0.4px;
				font-family: ${(props) => props.theme.fontFamily.plain};
			`,
			sans_p5: css`
				font-size: 12px;
				line-height: 24px;
				letter-spacing: 0.4px;
				font-family: ${(props) => props.theme.fontFamily.plain};
			`,
			serif_p1: css`
				font-size: 24px;
				line-height: 48px;
				letter-spacing: 0.3px;
				font-family: ${(props) => props.theme.fontFamily.leituraRoman};
			`,
			serif_p2: css`
				font-size: 18px;
				letter-spacing: 0.3px;
				font-family: ${(props) => props.theme.fontFamily.leituraRoman};
			`,
			serif_p3: css`
				font-size: 16px;
				line-height: 32px;
				letter-spacing: 0.3px;
				font-family: ${(props) => props.theme.fontFamily.leituraRoman};
			`,
			serif_p4: css`
				font-size: 14px;
				line-height: 28px;
				letter-spacing: 0.3px;
				font-family: ${(props) => props.theme.fontFamily.leituraRoman};
			`,
			serif_p5: css`
				font-size: 12px;
				line-height: 24px;
				letter-spacing: 0.3px;
				font-family: ${(props) => props.theme.fontFamily.leituraRoman};
			`,
			serif_p6: css`
				font-size: 10px;
				line-height: 20px;
				letter-spacing: 0.3px;
				font-family: ${(props) => props.theme.fontFamily.leituraRoman};
			`,
		},
	}, // endtablet

	// begin mobile
	mobile: {
		x: '4.266vw',
		pageTitle: css`
			${(props) => props.theme.desktophd.headerMixins.header_2};
		`,
		vSpace: '50px',
		col1: '14.4vw',
		col2: `${
			2 * 14.4 + (2 - 1) * 3.125 // colNum * 14.4 + (colNum - 1 ) * 3.125 // calculated width of column based on pixels, translated to VW
		}vw`,
		col3: `${3 * 14.4 + (3 - 1) * 4.266}vw`,
		col4: `${4 * 14.4 + (4 - 1) * 4.266}vw`,
		col5: `${5 * 14.4 + (5 - 1) * 4.266}vw`,
		marginHorizontal: '5.5vw',
		gutter: '4.266vw',
		vHeight: '50px',
		headerMixins: {
			header_1: css`
				font-size: 48px;
				line-height: 64px;
				letter-spacing: 0.3px;
				font-family: ${(props) => props.theme.fontFamily.leituraRoman};
			`,
			header_2: css`
				font-size: 72px;
				line-height: 96px;
				letter-spacing: 0.3px;
				font-family: ${(props) => props.theme.fontFamily.leituraRoman};
			`,
			header_3: css`
				font-size: 60px;
				line-height: 80px;
				letter-spacing: 0.3px;
				font-family: ${(props) => props.theme.fontFamily.leituraRoman};
			`,
			header_4: css`
				font-size: 48px;
				line-height: 64px;
				letter-spacing: 0.3px;
				font-family: ${(props) => props.theme.fontFamily.leituraRoman};
			`,
			header_5: css`
				font-size: 24px;
				line-height: 32px;
				letter-spacing: 0.3px;
				font-family: ${(props) => props.theme.fontFamily.leituraRoman};
			`,
			header_6: css`
				font-size: 30px;
				line-height: 40px;
				letter-spacing: 0.3px;
				font-family: ${(props) => props.theme.fontFamily.leituraRoman};
			`,
			header_7: css`
				font-size: 24px;
				line-height: 32px;
				letter-spacing: 0.3px;
				font-family: ${(props) => props.theme.fontFamily.leituraRoman};
			`,
		},
		typeMixins: {
			sans_p0: css`
				font-size: 30px;
				line-height: 48px;
				letter-spacing: 0.4px;
				font-family: ${(props) => props.theme.fontFamily.plain};
			`,
			sans_p1: css`
				font-size: 24px;
				line-height: 48px;
				letter-spacing: 0.4px;
				font-family: ${(props) => props.theme.fontFamily.plain};
			`,
			sans_p2: css`
				font-size: 18px;
				letter-spacing: 0.4px;
				font-family: ${(props) => props.theme.fontFamily.plain};
			`,
			sans_p3: css`
				font-size: 16px;
				line-height: 24px;
				letter-spacing: 0.4px;
				font-family: ${(props) => props.theme.fontFamily.plain};
			`,
			sans_p4: css`
				font-size: 14px;
				line-height: 28px;
				letter-spacing: 0.4px;
				font-family: ${(props) => props.theme.fontFamily.plain};
			`,
			sans_p5: css`
				font-size: 12px;
				line-height: 24px;
				letter-spacing: 0.4px;
				font-family: ${(props) => props.theme.fontFamily.plain};
			`,
			serif_p1: css`
				font-size: 24px;
				line-height: 48px;
				letter-spacing: 0.3px;
				font-family: ${(props) => props.theme.fontFamily.leituraRoman};
			`,
			serif_p2: css`
				font-size: 18px;
				letter-spacing: 0.3px;
				font-family: ${(props) => props.theme.fontFamily.leituraRoman};
			`,
			serif_p3: css`
				font-size: 16px;
				line-height: 32px;
				letter-spacing: 0.3px;
				font-family: ${(props) => props.theme.fontFamily.leituraRoman};
			`,
			serif_p4: css`
				font-size: 14px;
				line-height: 28px;
				letter-spacing: 0.3px;
				font-family: ${(props) => props.theme.fontFamily.leituraRoman};
			`,
			serif_p5: css`
				font-size: 12px;
				line-height: 24px;
				letter-spacing: 0.3px;
				font-family: ${(props) => props.theme.fontFamily.leituraRoman};
			`,
			serif_p6: css`
				font-size: 10px;
				line-height: 20px;
				letter-spacing: 0.3px;
				font-family: ${(props) => props.theme.fontFamily.leituraRoman};
			`,
		},
	}, // end mobile

	height: { nav_desktop: '90px', header: '95.5px' },
	z: {
		titleBar: 50,
		nav: 80,
		header: 90,
		overlay: 100,
		threeDot: 110,
		modal: 120,
		alert: 150,
	},
	spacing: {
		eighth: '2px',
		quarter: '4px',
		half: '7px',
		single: '14px',
		double: '24px',
		triple: '36px',
		quadruple: '48px',
		vspace: '5.33rem',
	},
	padding: { article: '60px' },
	fontSize: {
		h1: '25px',
		h2: '20px',
		h3: '16px',
		p: '16px',
		h4: '14px',
		h5: '12px',
	},
	fontFamily: {
		plain: "'Plain', Helvetica, sans-serif",
		leituraRoman: "'LeituraNewsRoman', 'Times New Roman', serif",
		leituraItalic: "'LeituraNewsItalic', 'Times New Roman', serif",
	},
	fontWeight: {
		light: '200',
		regular: '400',
		medium: '600',
		heavy: '800',
	},
} // padding and margins // end mixins // layout // end layout // type // end padding and margins

// end type
