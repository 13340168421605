import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import { ResultThumb } from 'Components/SearchResults'
import { fetchTopics, fetchTopicsLanding } from 'Utils/prismic-configuration'
import { media } from 'Styles/style-utils'

const ViewContainer = styled.div`
	${(props) => props.theme.layout.container};
`

const GridContainer = styled.div`
	${(props) => props.theme.layout.gridContainer};
`

const Title = styled.h1`
	${(props) => props.theme.desktophd.headerMixins.header_2};
	margin-bottom: 2.25rem;
`

const SubTitle = styled.h3`
	${(props) => props.theme.desktophd.headerMixins.header_4};
	width: ${(props) => props.theme.desktophd.col8};
	text-align: center;
	margin-bottom: ${(props) => props.theme.desktophd.vHeight};
	color: black;

	${media.tablet`
    ${(props) => props.theme.desktophd.headerMixins.header_6};
    width: ${(props) => props.theme.tablet.col6};
    margin-bottom: ${(props) => props.theme.tablet.vHeight};
  `};

	${media.mobile`
    width: ${(props) => props.theme.mobile.col5};
    margin-bottom: ${(props) => props.theme.mobile.vHeight};
  `};
`

class Topics extends Component {
	_isMounted = false

	state = {}

	compare = (a, b) => {
		if (this.props.language === 'english') {
			if (a.english.title < b.english.title) return -1
			if (a.english.title > b.english.title) return 1
			return 0
		} else {
			if (a.spanish && b.spanish) {
				if (a.spanish.title < b.spanish.title) return -1
				if (a.spanish.title > b.spanish.title) return 1
			} else if (a.spanish && !b.spanish) {
				return 1
			} else if (b.spanish && !a.spanish) {
				return -1
			} else {
				return 0
			}
		}
	}

	async componentDidMount() {
		this._isMounted = true

		if (this._isMounted) {
			let [topics_landing, topics] = await Promise.all([
				fetchTopicsLanding(),
				fetchTopics(),
			])

			// sort in ABC order depending on language
			console.log(topics)
			topics = topics.sort(this.compare)

			if (this._isMounted) {
				this.setState({ topics_landing, topics })
			}
		}
	}

	componentDidUpdate(prevProps, prevState) {
		// only update tag sorting if language changed

		if (this._isMounted) {
			if (prevProps.language !== this.props.language) {
				let topics = this.state.topics.sort(this.compare)
				this.setState({ topics })
			}
		}
	}

	componentWillUnmount() {
		this._isMounted = false
	}

	render() {
		return this.state.topics ? (
			<Fragment>
				<ViewContainer>
					<Title>
						{this.props.language === 'english'
							? this.state.topics_landing.english.title
							: this.state.topics_landing.spanish.title}
					</Title>
					<SubTitle>
						{this.props.language === 'english'
							? this.state.topics_landing.english.subtitle
							: this.state.topics_landing.spanish.subtitle}
					</SubTitle>

					<GridContainer>
						{this.state.topics.map((topic, index) => (
							<ResultThumb
								language={this.props.language}
								key={topic.uid}
								content={topic}
								type="topic"
							/>
						))}
					</GridContainer>
				</ViewContainer>
			</Fragment>
		) : null
	}
}

export default Topics
