export const getLanguageData = (data, language) => {
	if (language === 'spanish') {
		return data.spanish
	}
	if (language === 'english') {
		return data.english
	}

	throw new Error(`"${language}" is not a valid language`)
}
