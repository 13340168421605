import React from 'react'
import styled from 'styled-components'
import { generateKey } from 'Utils/helpers'
import { media } from 'Styles/style-utils'

const RelatedContentWrap = styled.div`
	width: ${(props) => props.theme.desktophd.col6};
	margin: ${(props) => props.theme.desktophd.vHeight} auto;
	margin-bottom: 0;
	display: block;

	p {
		margin: 0 !important;
		margin-bottom: 0.25rem !important;
	}

	${media.tablet`
    margin: ${(props) => props.theme.tablet.vHeight} auto;
    margin-bottom: 0;
    width: ${(props) => props.theme.tablet.col7};
    margin-left: auto;
    margin-right: ${(props) => props.theme.tablet.marginHorizontal};
  `};

	${media.mobile`
    margin: ${(props) => props.theme.mobile.vHeight} auto;
    margin-bottom: 0;
    width: ${(props) => props.theme.mobile.col5};
    margin-left: auto;
    margin-right: auto;
  `};
`

const ContentModule = styled.div`
	padding: 15px;
	border: 1px solid ${(props) => props.theme.colors.lightGray};
	display: flex;
	align-items: center;
	margin: 20px auto;
`

const ContentText = styled.div`
	text-align: left;
	padding-left: 20px;
`

const ContentType = styled.p`
	text-transform: uppercase;
	${(props) => props.theme.desktophd.typeMixins.sans_p5};
	font-size: 11px;
`

const ContentTitle = styled.p`
	${(props) => props.theme.desktophd.typeMixins.serif_p3};
	line-height: 20px;
	margin: 0;
`

const ContentThumb = styled.div`
	height: 80px;
	width: auto;
	img {
		height: 100%;
		width: auto;
		max-width: unset;
	}
`

const ContentSource = styled.p`
	${(props) => props.theme.desktophd.typeMixins.sans_p5};
	color: ${(props) => props.theme.colors.darkpink};
`

const RelatedContent = (props) => {
	const { relatedContent } = props

	if (
		relatedContent &&
		relatedContent.length > 0 &&
		relatedContent[0] &&
		relatedContent[0] !== undefined
	) {
		return (
			<RelatedContentWrap>
				{relatedContent.map((content, index) => {
					if (content) {
						return (
							<ContentModule key={generateKey(index)}>
								<a
									target="_blank"
									rel="noopener noreferrer"
									href={content.link ? content.link : '#'}
								>
									<ContentThumb>
										<img src={content.thumbnail} alt="" />
									</ContentThumb>
								</a>
								<ContentText>
									<ContentType>Resource</ContentType>
									<a
										target="_blank"
										rel="noopener noreferrer"
										href={content.link ? content.link : '#'}
									>
										<ContentTitle>{content.title}</ContentTitle>{' '}
									</a>
									<a
										target="_blank"
										rel="noopener noreferrer"
										href={content.link ? content.link : '#'}
									>
										<ContentSource>{content.source}</ContentSource>
									</a>
								</ContentText>
							</ContentModule>
						)
					} else return null
				})}
			</RelatedContentWrap>
		)
	} else return null
}

export default RelatedContent
