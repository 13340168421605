import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { media } from 'Styles/style-utils'

const TagsWrap = styled.div`
	margin: ${(props) => props.theme.desktophd.vHeight} auto;
	margin-left: ${(props) => props.theme.desktophd.col4};
	margin-bottom: 0;
	width: ${(props) => props.theme.desktophd.col4};
	display: flex;
	flex-wrap: wrap;

	.tags-title {
		${(props) => props.theme.desktophd.header_7}
		margin-bottom: 1rem;
		display: block;
		width: 100%;

		${media.tablet`
      display: none;
    `}
	}

	${media.tablet`
    margin: ${(props) => props.theme.tablet.vHeight} auto;
    margin-top: 0;
    width: ${(props) => props.theme.tablet.col8};
    padding-left: calc(${(props) => props.theme.tablet.col2} + ${(props) =>
		props.theme.tablet.gutter});
  `};

	${media.mobile`
      margin: ${(props) => props.theme.mobile.vHeight} auto;
      margin-top: 0;
      width: ${(props) => props.theme.mobile.col5};
      padding-left: 0;
  `};
`

const Tag = styled.span`
	${(props) => props.theme.desktophd.typeMixins.sans_p4};
	color: white;
	background: ${(props) => props.theme.colors.darkpink};
	padding: 6px 8px;
	border-radius: 4px;
	margin-right: 10px;
	margin-bottom: 10px;
	display: inline-block;
`

const Tags = (props) => {
	if (props.language === 'english') {
		return (
			<TagsWrap>
				<div className="tags-title">
					<h3>Tags</h3>
				</div>
				{props.tags.map((tag, index) => {
					if (tag) {
						return (
							<Link key={tag.uid} to={`/tags/${tag.uid}`}>
								<Tag key={tag.id + index}>{tag.english.title}</Tag>
							</Link>
						)
					} else {
						return null
					}
				})}
			</TagsWrap>
		)
	} else {
		return (
			<TagsWrap>
				<div className="tags-title">
					<h3>Tags</h3>
				</div>
				{props.tags.map((tag) => {
					if (tag && tag.spanish) {
						return (
							<Link key={tag.uid} to={`/tags/${tag.uid}`}>
								<Tag key={tag.id}>{tag.spanish ? tag.spanish.title : null}</Tag>{' '}
							</Link>
						)
					} else {
						return null
					}
				})}
			</TagsWrap>
		)
	}
}

export default Tags
