import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { fetchModule } from 'Utils/prismic-configuration'
import { media } from 'Styles/style-utils'
import { getLanguageData } from 'Utils/getLanguageData'

const PussyModuleWrapper = styled.div`
	width: ${(props) => (props.width === 'full' ? '100vw' : '50vw')};
	background-color: ${(props) => props.theme.colors.lightWatermelon};
	display: flex;
	flex-direction: ${(props) => (props.width === 'full' ? 'row' : 'column')};
	justify-content: center;
	align-items: center;
	padding: ${(props) => props.theme.desktophd.marginHorizontal};
	margin-bottom: ${(props) => props.theme.desktophd.vSpace};

	${media.tablet`
    margin-bottom: ${(props) => props.theme.tablet.vSpace};
  `}

	${media.mobile`
    width: 100%;
    margin: ${(props) => props.theme.mobile.vSpace} 0;
  `}

  a {
		color: ${(props) => props.theme.colors.pink};
		${(props) => props.theme.desktophd.typeMixins.sans_p2};
	}
`

const Heading = styled.p`
	${(props) => props.theme.desktophd.typeMixins.sans_p1};
	margin-bottom: 0px;
	color: white;

	${media.tablet`
    ${(props) => props.theme.desktophd.typeMixins.sans_p2};
  `}
`

const Body = styled.p`
	${(props) => props.theme.desktophd.typeMixins.sans_p2};
	color: black;
	margin-bottom: 30px;

	${media.tablet`
    ${(props) => props.theme.desktophd.typeMixins.sans_p4};
    line-height: 20px;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
  `}
`

const Button = styled.button`
	${(props) => props.theme.buttonMixins.buttonOne};
	background: black;
	color: white;

	${media.tablet`
    ${(props) => props.theme.buttonMixins.buttonOneTablet};
    background: black;
    color: white;
  `}

	${media.mobile`
    ${(props) => props.theme.buttonMixins.buttonOneMobile};
    background: black;
    color: white;
  `}
`

const FlexItem = styled.div`
	width: ${(props) => (props.width === 'full' ? '40%' : '80%')};
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	${media.mobile`
    width: 80%;
  `}
`

const ImageFlexItem = styled.div`
	width: ${(props) => (props.width === 'full' ? '40%' : '80%')};
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	${media.tablet`

      display: ${(props) => (props.width === 'full' ? 'block' : 'none')};
    }
  `}

	${media.mobile`

      display:  none;

  `}
`

class PussyDefinitionModule extends React.Component {
	_isMounted = false
	state = {}

	async componentDidMount() {
		this._isMounted = true
		const raw = await fetchModule('pussydefinition_module')
		const data = {}

		if (raw) {
			let image = raw.english ? raw.english.image.url : null

			data.english = {
				title: raw.english.title ? raw.english.title[0].text : null,
				body: raw.english.body ? raw.english.body[0].text : null,
				button_cta: raw.english.button_cta
					? raw.english.button_cta[0].text
					: null,
				image,
			}

			if (raw.spanish) {
				data.spanish = {
					title:
						raw.spanish.title && raw.spanish.title[0]
							? raw.spanish.title[0].text
							: null,
					body: raw.spanish.body ? raw.spanish.body[0].text : null,
					button_cta: raw.spanish.button_cta
						? raw.spanish.button_cta[0].text
						: null,
				}
			}

			if (this._isMounted) {
				this.setState({ data })
			}
		}
	}

	componentWillUnmount() {
		this._isMounted = false
	}

	render() {
		if (this.state.data) {
			const { image } = this.state.data.english

			const { title, body, button_cta } = getLanguageData(
				this.state.data,
				this.props.language,
			)
			return (
				<PussyModuleWrapper width={this.props.width}>
					<ImageFlexItem className="img-wrap" width={this.props.width}>
						<img src={image} alt="" />
					</ImageFlexItem>
					<FlexItem width={this.props.width}>
						<Heading>{title}</Heading>
						<Body>{body}</Body>
						<Link to="pussy-definition">
							<Button>{button_cta}</Button>
						</Link>
					</FlexItem>
				</PussyModuleWrapper>
			)
		} else {
			return ' '
		}
	}
}

export default PussyDefinitionModule
