import React from 'react'
import styled from 'styled-components'
import { media } from 'Styles/style-utils'

const EmbedWrapper = styled.div`
	width: 100%;
	margin: ${(props) => props.theme.desktophd.vSpace} 0;

	div,
	iframe {
		width: 100%;
	}

	${media.tablet`
    margin: ${(props) => props.theme.tablet.vSpace} 0;
  `};

	${media.mobile`
    margin: ${(props) => props.theme.mobile.vSpace} 0;
  `};
`

const Embed = (props) => {
	const createMarkup = () => {
		return { __html: props.iframe }
	}

	return <EmbedWrapper dangerouslySetInnerHTML={createMarkup()} />
}

export default Embed
