import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { media } from 'Styles/style-utils'
import { getText } from 'Utils/translatedText'

const Wrapper = styled.div`
	width: 100%;
	text-align: left;
	margin-bottom: 2.125rem;

	${media.mobile`
    text-align: center;
    img {
      width: 100%;
    }
  `};
`

const Title = styled.h3`
	margin-top: 1rem;
	margin-bottom: 0.5625rem;
	${(props) => props.theme.desktophd.headerMixins.header_6};

	${media.tablet`
    ${(props) => props.theme.tablet.headerMixins.header_5};
  `};
`
const Subtitle = styled.h3`
	${(props) => props.theme.desktophd.typeMixins.sans_p3};

	a {
		color: ${(props) => props.theme.colors.pink};
	}

	& + & {
		margin-top: 0.8em;
	}

	${media.tablet`
    ${(props) => props.theme.tablet.headerMixins.sans_p3};
  `};
`
/* return content info, falling back to english if no spanish */
const getContent = (content, language) => {
	const currentLang = content[language]
	const getContentKey = (key) => {
		return (
			(currentLang ? currentLang[key] : null) ||
			(content.english ? content.english[key] : null) ||
			content[key]
		)
	}

	return {
		title: getContentKey('title'),
		subtitle: getContentKey('subtitle'),
		thumbnail: getContentKey('thumbnail'),
		link: getContentKey('link'),
	}
}

const ResultThumb = (props) => {
	let { type, language } = props
	let { uid } = props.content
	if (!props.content[language]) return null
	let { title, subtitle, thumbnail, link } = getContent(props.content, language)

	if (type === 'article' || type === 'topic') {
		link = `/${type}s/${uid}`
	}
	if (!link) return null

	if (type === 'resource') {
		return (
			<Wrapper key={uid}>
				<a target="_blank" rel="noopener noreferrer" href={link}>
					<img src={thumbnail} alt="" />
				</a>
				<Title>
					<a target="_blank" rel="noopener noreferrer" href={link}>
						{title}
					</a>
				</Title>
				{subtitle ? (
					<Subtitle>
						<a target="_blank" rel="noopener noreferrer" href={link}>
							{subtitle}
						</a>
					</Subtitle>
				) : null}
				<Subtitle>
					<a target="_blank" rel="noopener noreferrer" href={link}>
						{getText('readMore', language)}
					</a>
				</Subtitle>
			</Wrapper>
		)
	} else if (type === 'article') {
		return (
			<Wrapper key={uid}>
				<Link to={link}>
					<img src={thumbnail} alt="" />
				</Link>
				<Title>
					<Link to={link}>{title}</Link>
				</Title>
				{subtitle ? (
					<Subtitle>
						<Link to={link}>{subtitle}</Link>
					</Subtitle>
				) : null}
				<Subtitle>
					<Link to={link}>{getText('readMore', language)}</Link>
				</Subtitle>
			</Wrapper>
		)
	} else if (type === 'topic') {
		return (
			<Wrapper key={uid}>
				<Link to={link}>
					<img src={thumbnail} alt="" />
				</Link>
				<Title>
					<Link to={link}>{title}</Link>
				</Title>
				{subtitle ? (
					<Subtitle>
						<Link to={link}>
							{subtitle}
							View more
						</Link>
					</Subtitle>
				) : null}
				<Subtitle>
					<Link to={link}>{getText('readMore', language)}</Link>
				</Subtitle>
			</Wrapper>
		)
	}
	return null
}

export default ResultThumb
