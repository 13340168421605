import React from 'react'
import styled from 'styled-components'
import { linkResolver } from 'Utils/prismic-configuration'
import { RichText } from 'prismic-reactjs'

const ViewContainer = styled.div`
	${(props) => props.theme.layout.container};
	align-items: flex-start;

	img {
		width: ${(props) => props.theme.desktophd.col6};
		margin: 0 auto;
	}
`

const BodyContainer = styled.div`
	margin: ${(props) => props.theme.spacing.vspace} auto;
	width: ${(props) => props.theme.desktophd.col6};
	text-align: left;
	a {
		color: ${(props) => props.theme.colors.pink};
	}
`

const NewsletterSuccess = (props) => {
	const { data, language } = props
	if (data) {
		let text
		let image = data.english.image.url
		if (language === 'english') {
			text = props.data.english.text
		} else {
			if (props.data.spanish) {
				text = props.data.spanish.text
			} else return null
		}
		return (
			<ViewContainer>
				<img src={image} alt="" />
				<BodyContainer>{RichText.render(text, linkResolver)}</BodyContainer>
			</ViewContainer>
		)
	} else return null
}

export default NewsletterSuccess
