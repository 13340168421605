import React, { Component } from 'react'
import styled from 'styled-components'
import { linkResolver } from 'Utils/prismic-configuration'
import { RichText } from 'prismic-reactjs'
import { fetchAbout } from 'Utils/prismic-configuration'
import { media } from 'Styles/style-utils'

const ViewContainer = styled.div`
	${(props) => props.theme.layout.container};
	align-items: flex-start;

	h1,
	h2,
	h3,
	h4,
	h5 {
		margin-bottom: 2rem;
	}
	p {
		margin-bottom: 1rem;
	}
`

const BodyContainer = styled.div`
	margin-left: calc(
		${(props) => props.theme.desktophd.col3} +
			${(props) => props.theme.desktophd.gutter}
	);
	width: ${(props) => props.theme.desktophd.col6};
	text-align: left;
	a {
		color: ${(props) => props.theme.colors.pink};
	}

	${media.tablet`
    margin-left: calc(
        ${(props) => props.theme.tablet.col1} +
          ${(props) => props.theme.tablet.gutter}
      );
    text-align: left;
    width: ${(props) => props.theme.tablet.col7};
  `};

	${media.mobile`
    margin-left: 0;
    width: ${(props) => props.theme.mobile.col5};
  `};
`

const Title = styled.h1`
	${(props) => props.theme.responsiveType.pageTitle};
	margin-left: calc(
		${(props) => props.theme.desktophd.col3} +
			${(props) => props.theme.desktophd.gutter}
	);
	margin-bottom: 2.25rem;

	${media.tablet`
    margin-left: calc(
        ${(props) => props.theme.tablet.col1} +
          ${(props) => props.theme.tablet.gutter}
      );
    text-align: left;
  `};

	${media.mobile`
    margin-left: 0;
  `};
`

const Subtitle = styled.h1`
	${(props) => props.theme.desktophd.headerMixins.header_4};
	margin-left: calc(
		${(props) => props.theme.desktophd.col3} +
			${(props) => props.theme.desktophd.gutter}
	);
	margin-bottom: 2.25rem;
	text-align: left;
	width: ${(props) => props.theme.desktophd.col8};
	color: ${(props) => props.theme.colors.dustypink};

	${media.tablet`
    ${(props) => props.theme.desktophd.headerMixins.header_8};
    margin-left: calc(
        ${(props) => props.theme.tablet.col1} +
          ${(props) => props.theme.tablet.gutter}
      );
    text-align: left;
    width: ${(props) => props.theme.tablet.col7};
  `};

	${media.mobile`
    ${(props) => props.theme.desktophd.headerMixins.header_6};
    margin-left: 0;
    ${(props) => props.theme.mobile.col5};
  `};
`

class About extends Component {
	_isMounted = false
	state = {}

	async componentDidMount() {
		this._isMounted = true

		if (this._isMounted) {
			let data = await fetchAbout()

			if (this._isMounted) {
				this.setState({ data })
			}
		}
	}

	render() {
		const { language } = this.props
		const { data } = this.state
		if (data) {
			let title, subtitle, body
			if (language === 'english') {
				title = data.english.title[0] ? data.english.title[0].text : null
				subtitle = data.english.subtitle[0]
					? data.english.subtitle[0].text
					: null
				body = data.english.body
				body = RichText.render(body, linkResolver)
			} else {
				if (data.spanish) {
					title = data.spanish.title[0] ? data.spanish.title[0].text : null
					subtitle = data.spanish.subtitle[0]
						? data.spanish.subtitle[0].text
						: null
					body = data.spanish.body
					body = RichText.render(body, linkResolver)
				} else return null
			}
			return (
				<ViewContainer>
					<Title>{title}</Title>
					<Subtitle>{subtitle}</Subtitle>
					<BodyContainer>{body}</BodyContainer>
				</ViewContainer>
			)
		} else return null
	}
}

export default About
