// @flow
import styled from 'styled-components'
import { media } from 'Styles/style-utils'

export const h1 = '25px'
export const h2 = '20px'
export const h3 = '16px'
export const p = '16px'
export const h4 = '14px'
export const h5 = '12px'

export const light = '200'
export const regular = '400'
export const semi = '600'
export const strong = '800'

export const SansP1 = styled.p`
	${(props) => props.theme.desktophd.typeMixins.sans_p1};
`

export const SansP2 = styled.p`
	${(props) => props.theme.desktophd.typeMixins.sans_p2};
`

export const SansP3 = styled.p`
	${(props) => props.theme.desktophd.typeMixins.sans_p3};
`

export const SansP4 = styled.p`
	${(props) => props.theme.desktophd.typeMixins.sans_p4};

	${media.tablet`
  ${(props) => props.theme.desktophd.typeMixins.sans_p5};  `}
`

export const SansP5 = styled.p`
	${(props) => props.theme.desktophd.typeMixins.sans_p5};
`
