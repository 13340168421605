import React from 'react'
import styled from 'styled-components'
import { linkResolver } from 'Utils/prismic-configuration'
import { RichText } from 'prismic-reactjs'
import { media } from 'Styles/style-utils'

const ViewContainer = styled.div`
	${(props) => props.theme.layout.container};

	h1,
	h2,
	h3,
	h4,
	h5 {
		margin-bottom: 2rem;
	}
	p {
		margin-bottom: 1rem;
	}
`

const BodyContainer = styled.div`
	text-align: center;
	.body-text {
		width: ${(props) => props.theme.desktophd.col6};
		text-align: left;
		margin: 0 auto;
		a {
			color: ${(props) => props.theme.colors.pink};
		}
	}

	${media.tablet`
    text-align: left;
    width:  ${(props) => props.theme.tablet.col7};
    margin-left: auto;
    .body-text {
      width: 100%;   
    }
  `}

	${media.mobile`
    width:  ${(props) => props.theme.mobile.col5};
  `}
`

const Title = styled.h1`
	${(props) => props.theme.responsiveType.pageTitle};
	margin-bottom: 2rem !important;
`

const TextOnly = (props) => {
	const { data, language } = props
	console.log(data)
	if (data) {
		let title, body
		if (language === 'english') {
			title = data.english.title[0].text
			body = data.english.body
			body = RichText.render(body, linkResolver)
		} else {
			if (data.spanish) {
				title = data.spanish.title[0].text
				body = data.spanish.body
				body = RichText.render(body, linkResolver)
			} else return null
		}
		return (
			<ViewContainer>
				<BodyContainer>
					<Title>{title}</Title>
					<div className="body-text">{body}</div>
				</BodyContainer>
			</ViewContainer>
		)
	} else return null
}

export default TextOnly
