import React from 'react'
import { RichText } from 'prismic-reactjs'
import styled from 'styled-components'
import { media } from 'Styles/style-utils'

import { linkResolver } from 'Utils/prismic-configuration'

const QuoteWrap = styled.div`
	margin: 2em;
	width: ${(props) => props.theme.desktophd.col4};
	margin-right: ${(props) => props.theme.desktophd.col2};
	color: black;
	float: right;
	padding: 36px 48px;
	font-family: ${(props) => props.theme.fontFamily.leituraItalic};
	p {
		padding: 0;
		padding-bottom: 36px;
		font-family: ${(props) => props.theme.fontFamily.leituraItalic};
		font-size: 30px;
		line-height: 40px;
	}

	${media.tablet`
    width: 100%;
    padding-left: calc(${(props) => props.theme.tablet.gutter} + ${(props) =>
		props.theme.tablet.col1});
    padding-right: 0;
    margin: 0;
    margin-right: 0;
    padding-bottom: 0;
  `};

	${media.mobile`
    width: 100%;
    padding-top: 0;
    padding-left: calc(${(props) => props.theme.mobile.marginHorizontal} + ${(
		props,
	) => props.theme.mobile.col1});
    p{
      font-size: 24px;
      line-height: 32px
    }
  `};
`

const AuthorName = styled.div`
	border-top: 2px solid black;
	display: inline-block;
	p {
		font-size: 12px;
		${(props) => props.theme.desktophd.typeMixins.sans_p4};
		margin-top: 0.5rem !important;
	}
`

const Pullquote = (props) => {
	return (
		<QuoteWrap>
			{RichText.render(props.text, linkResolver)}
			<AuthorName>{RichText.render(props.author, linkResolver)}</AuthorName>
		</QuoteWrap>
	)
}

export default Pullquote
