import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import Filter from 'Components/Filter'
import { media } from 'Styles/style-utils'

const ResultsWrap = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	grid-column-gap: ${(props) => props.theme.desktophd.gutter};
	width: ${(props) => props.theme.desktophd.col10};
	margin: 0 auto;

	${media.tablet`
    grid-template-columns: 1fr 1fr 1fr
    grid-column-gap: ${(props) => props.theme.tablet.gutter};
    width: ${(props) => props.theme.tablet.col8};
  `} ${media.mobile`
    grid-template-columns: 1fr;
    grid-column-gap: 0;
    width: ${(props) => props.theme.mobile.col5};
  `};
`

const ResultThumb = styled.div`
	width: 100%;
	margin-bottom: 1.5rem;

	img {
		width: 100%;
	}

	p {
		${(props) => props.theme.desktophd.typeMixins.sans_p4};
		color: white;
		max-width: 100%;
		line-height: 1.4em;
	}
`

const ResultType = styled.h5`
	${({ theme }) => css`
		${theme.desktophd.typeMixins.sans_p5};
		color: white;
	`}
`
const TagsList = styled.div`
	margin-top: 8px;
`
const ResultTag = styled.h5`
	${({ theme }) => css`
		${theme.desktophd.typeMixins.sans_p5};
		color: black;
		background-color: ${theme.colors.lightWatermelon};
		padding: 2px 5px;
		line-height: 1.4em;
		display: inline-block;
		margin: 0 2px 3px;
		border-radius: 3px;
	`}
`

const FilterWrap = styled.div`
	display: flex;
	width: ${(props) => props.theme.desktophd.col10};
	margin: 2.25rem auto 2.125rem auto;

	${media.tablet`
    width: 100%;
  `} ${media.mobile`
    flex-direction: column;
  `};
`

const FilterTitle = styled.div`
	flex-grow: 2;
	h3 {
		${(props) => props.theme.desktophd.headerMixins.header_6};
		color: white;
	}
	${media.mobile`
    width: 100%;
    text-align: center;
    margin-bottom: 1rem;
  `};
`

const FilterItemWrap = styled.div`
	margin-left: auto;

	${media.mobile`
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
  `};
`

const FilterItem = styled.button`
	${(props) => props.theme.buttonMixins.filterButton};
	color: ${(props) => (props.active ? 'black' : 'white')};
	background-color: ${(props) => (props.active ? 'white' : 'black')};
`

const ResultsGrid = (props) => {
	const count = props.results.length
	const query = props.searchQuery

	if (props.loading) return <p>Loading..</p>

	const articlesCount = props.results.filter((r) => r.type === 'article').length
	const resourcesCount = props.results.filter(
		(r) => r.type === 'resource',
	).length

	const countText = `There are ${count} results for "${query}"`
	if (props.searchQuery !== '') {
		return (
			<Filter
				initialValue="all"
				render={({ currentFilterValue, setFilterValue }) => (
					<Fragment>
						<FilterWrap>
							<FilterTitle>
								<h3>{countText}</h3>
							</FilterTitle>
							<FilterItemWrap>
								<FilterItem
									active={currentFilterValue === 'all'}
									onClick={() => {
										setFilterValue('all')
									}}
								>
									All
								</FilterItem>
								<FilterItem
									active={currentFilterValue === 'article'}
									onClick={() => {
										setFilterValue('article')
									}}
								>
									Articles ({articlesCount})
								</FilterItem>
								<FilterItem
									active={currentFilterValue === 'resource'}
									onClick={() => {
										setFilterValue('resource')
									}}
								>
									Resources ({resourcesCount})
								</FilterItem>
							</FilterItemWrap>
						</FilterWrap>

						<ResultsWrap>
							{props.results
								.filter(
									(i) =>
										currentFilterValue === 'all' ||
										i.type === currentFilterValue,
								)
								.map((result) => {
									if (result.type === 'resource') {
										return (
											<a key={result.uid} href={result.resource_link}>
												<ResultThumb>
													<img src={result.thumbnail} alt="" />{' '}
													<ResultType>{result.type.toUpperCase()}</ResultType>
													<p>{result.title}</p>
													<TagsList>
														{result.tags.map((tag) => (
															<ResultTag key={tag}>{tag}</ResultTag>
														))}
													</TagsList>
												</ResultThumb>
											</a>
										)
									} else {
										return (
											<Link key={result.uid} to={`/articles/${result.uid}`}>
												<ResultThumb>
													<img src={result.thumbnail} alt="" />{' '}
													<ResultType>{result.type.toUpperCase()}</ResultType>
													<p>{result.title}</p>
													<TagsList>
														{result.tags.map((tag) => (
															<ResultTag key={tag}>{tag}</ResultTag>
														))}
													</TagsList>
												</ResultThumb>
											</Link>
										)
									}
								})}
						</ResultsWrap>
					</Fragment>
				)}
			/>
		)
	} else return ''
}

export default ResultsGrid
