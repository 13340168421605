import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Byline from 'Components/Byline'
import { media } from 'Styles/style-utils'
import { fetchSimpleArticle } from 'Utils/prismic-configuration'
import { getLanguageData } from 'Utils/getLanguageData'

const Wrapper = styled.div`
	width: ${(props) => props.theme.desktophd.col12};
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: ${(props) => props.theme.desktophd.vSpace} 0;
	img {
		width: 100%;
		margin-top: ${(props) => props.theme.spacing.vspace};
	}

	${media.tablet`
    margin: 1rem 0;
    width: ${(props) => props.theme.tablet.col8};
    padding: ${(props) => props.theme.tablet.vHeight} 0;
    img {
      margin-top: ${(props) => props.theme.tablet.vHeight};
    }
  `}
	${media.mobile`
    width: 100vw;
    padding: ${(props) => props.theme.mobile.vHeight} 0;
    img {
      margin-top: ${(props) => props.theme.mobile.vHeight};
    }
  `}
`

const TextWrap = styled.div`
	width: ${(props) => props.theme.desktophd.col8};
	margin: auto;

	${media.tablet`
    width: ${(props) => props.theme.tablet.col6};
  `}
	${media.mobile`
    width: ${(props) => props.theme.mobile.col4};
  `}
`

const Title = styled.h1`
	/* margin-top: 1.5rem; */
	margin-bottom: 1.125rem;
	line-height: 1;
	${(props) => props.theme.desktophd.headerMixins.header_1};

	${media.tablet`
    ${(props) => props.theme.desktophd.headerMixins.header_4};
    line-height: 1;
  `}
	${media.mobile`
  `}
`
const Subtitle = styled.h3`
	${(props) => props.theme.desktophd.headerMixins.header_5};
	font-family: ${(props) => props.theme.fontFamily.plain};
	margin-bottom: 2rem;

	${media.tablet`
    ${(props) => props.theme.desktophd.typeMixins.sans_p1};
    line-height: 32px;
  `}
	${media.mobile`
  `}
`

const Blurb = styled.h6`
	display: ${(props) => (props.shouldDisplay ? 'block' : 'none')};
	padding-bottom: ${(props) => props.theme.desktophd.vHeight};
	${media.tablet`
    padding-bottom: ${(props) => props.theme.tablet.vHeight};
    line-height: 30px;
  `}
	${media.mobile`
    padding-bottom: ${(props) => props.theme.mobile.vHeight};
  `}
`

const Button = styled.button`
	${(props) => props.theme.buttonMixins.buttonOne};
	background: black;
	color: white;
	${media.tablet`
    ${(props) => props.theme.buttonMixins.buttonOneTablet};
    background: black;
  `}
`

const Small = styled.p`
	${(props) => props.theme.desktophd.typeMixins.sans_p5};
	color: ${(props) => props.theme.colors.lightGray};
	margin-top: 0.5rem;
	margin-bottom: ${(props) => props.theme.desktophd.vSpace};

	${media.tablet`
    ${(props) => props.theme.tablet.typeMixins.sans_p5};
      margin-bottom: ${(props) => props.theme.tablet.vSpace};
  `}
	${media.mobile`
    ${(props) => props.theme.mobile.typeMixins.sans_p5};
      margin-bottom: ${(props) => props.theme.mobile.vSpace};
  `}
`

// const ContentType = styled.p`
// 	${(props) => props.theme.desktophd.typeMixins.sans_p1};
// 	margin-bottom: 0.625rem;

// 	${media.tablet`
//     ${(props) => props.theme.desktophd.typeMixins.sans_p2};
//   `}
// 	${media.mobile`
//     /* ${(props) => props.theme.mobile.typeMixins.sans_p1}; */
//   `}
// `

class ArticleModuleHero extends React.PureComponent {
	_isMounted = false
	state = {}

	async componentDidMount() {
		this._isMounted = true
		const { uid } = this.props
		const raw = await fetchSimpleArticle(uid, this.props.api)
		const data = {}

		const { authorAvatar, authorName, authorUid, hero_image } = raw.english

		data.english = {
			uid,
			authorAvatar,
			authorName,
			authorUid,
			hero_image,
			title: raw.english.title || null,
			subtitle: raw.english.subtitle || null,
			blurb: raw.english.blurb || null,
			illustration_byline: raw.english.illustration_byline || null,
		}

		data.spanish = raw.spanish
			? {
					title: raw.spanish.title || null,
					subtitle: raw.spanish.subtitle || null,
					blurb: raw.spanish.blurb || null,
					illustration_byline: raw.spanish.illustration_byline || null,
			  }
			: null

		if (this._isMounted) {
			this.setState({ data })
		}
	}

	componentWillUnmount() {
		this._isMounted = false
	}

	render() {
		if (this.state.data) {
			const { uid, authorAvatar, authorName, authorUid, hero_image } =
				this.state.data.english

			const { subtitle, title, blurb, illustration_byline } = getLanguageData(
				this.state.data,
				this.props.language,
			)
			const to = `/articles/${uid}`
			return (
				<Wrapper>
					<Title>{title}</Title>
					<Byline
						authorUid={authorUid}
						authorName={authorName}
						authorAvatar={authorAvatar}
					/>
					<Link to={to}>
						<img src={hero_image} alt="" />
						<Small>{illustration_byline}</Small>
						<TextWrap>
							<Subtitle>{subtitle}</Subtitle>
							<Blurb shouldDisplay={blurb}>{blurb}</Blurb>
							<Button>Keep Reading</Button>
						</TextWrap>
					</Link>
				</Wrapper>
			)
		} else {
			return null
		}
	}
}

export default ArticleModuleHero
