import React from 'react'

const Search = (props) => (
	<svg width="24px" height="24px" viewBox="0 0 24 24">
		<g
			id="Symbols"
			stroke="none"
			strokeWidth="1"
			fill="none"
			fillRule="evenodd"
		>
			<g
				id="Desktop-/-Nav-/-Black-/-Transparent"
				transform="translate(-1284.000000, -36.000000)"
			>
				<g
					id="Icon-/-Black-/-Search"
					transform="translate(1280.000000, 32.494663)"
				>
					<g id="icon-/-blk-/-search">
						<path
							d="M20.6666667,18.6666667 L19.6133333,18.6666667 L19.24,18.3066667 C20.5466667,16.7866667 21.3333333,14.8133333 21.3333333,12.6666667 C21.3333333,7.88 17.4533333,4 12.6666667,4 C7.88,4 4,7.88 4,12.6666667 C4,17.4533333 7.88,21.3333333 12.6666667,21.3333333 C14.8133333,21.3333333 16.7866667,20.5466667 18.3066667,19.24 L18.6666667,19.6133333 L18.6666667,20.6666667 L25.3333333,27.32 L27.32,25.3333333 L20.6666667,18.6666667 Z M12.6666667,18.6666667 C9.34666667,18.6666667 6.66666667,15.9866667 6.66666667,12.6666667 C6.66666667,9.34666667 9.34666667,6.66666667 12.6666667,6.66666667 C15.9866667,6.66666667 18.6666667,9.34666667 18.6666667,12.6666667 C18.6666667,15.9866667 15.9866667,18.6666667 12.6666667,18.6666667 Z"
							id="Shape"
							fill={props.fill}
							fillRule="nonzero"
						/>
						<polygon id="Shape" points="0 0 32 0 32 32 0 32" />
					</g>
				</g>
			</g>
		</g>
	</svg>
)

export default Search
