import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { fetchModule } from 'Utils/prismic-configuration'
import { media } from 'Styles/style-utils'
import { getText } from 'Utils/translatedText'

const DonateModuleWrapper = styled.div`
	width: ${(props) => (props.width === 'full' ? '100%' : '50%')};
	min-height: 400px;
	background-color: ${(props) => props.theme.colors.darkGray};
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-bottom: ${(props) => props.theme.desktophd.vSpace};

	${media.tablet`
    min-height: 300px;
    margin-bottom: ${(props) => props.theme.tablet.vSpace};
  `}

	${media.mobile`
    width: 100%;
    margin: ${(props) => props.theme.mobile.vSpace} 0;
  `}

  p {
		${(props) => props.theme.desktophd.typeMixins.sans_p2};
		color: white;

		${media.tablet`
      ${(props) => props.theme.desktophd.typeMixins.sans_p3};
      text-align: center;
    `}

		${media.mobile`
      ${(props) => props.theme.desktophd.typeMixins.sans_p4};
      text-align: center;
    `}
	}
`

export const Button = styled(Link)`
	background: red;
	margin-top: 48px;
	color: white !important;
	text-align: center;
	display: inline-block;
	${(props) => props.theme.buttonMixins.buttonOne};

	${media.tablet`
      ${(props) => props.theme.buttonMixins.buttonOneTablet};
      /* ${(props) => props.theme.desktophd.typeMixins.sans_p3}; */
      min-width: 40%;
  `}
	${media.mobile`
      /* ${(props) => props.theme.desktophd.typeMixins.sans_p3}; */
      width: auto;
      min-width: auto;
  `}
`

class DonateModule extends React.Component {
	_isMounted = false
	state = {}

	async componentDidMount() {
		this._isMounted = true
		const raw = await fetchModule('donate_module')
		const data = {}
		data.english = {
			cta: raw.english.cta ? raw.english.cta[0].text : null,
			button_cta: raw.english.button_cta
				? raw.english.button_cta[0].text
				: null,
		}
		data.spanish = {
			cta: raw.spanish.cta ? raw.spanish.cta[0].text : null,
			button_cta: raw.spanish.button_cta
				? raw.spanish.button_cta[0].text
				: null,
		}

		if (this._isMounted) {
			this.setState({ data })
		}
	}

	componentWillUnmount() {
		this._isMounted = false
	}

	render() {
		if (this.state.data) {
			let cta
			if (this.props.language === 'spanish') {
				cta = this.state.data.spanish.cta
			} else {
				cta = this.state.data.english.cta
			}

			return (
				<DonateModuleWrapper width={this.props.width}>
					<p>{cta}</p>
					<Button to="/contribute">
						{getText('links.donate', this.props.language)}
					</Button>
				</DonateModuleWrapper>
			)
		} else {
			return null
		}
	}
}

export default DonateModule
