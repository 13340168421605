import React, { Component } from 'react'
import styled from 'styled-components'
import {
	Embed,
	ImageInline,
	ImageBlock,
	Notes,
	Pullquote,
	RelatedContent,
	Sidebar,
	Tags,
	TextBlock,
} from 'Components/ArticleBody'
import Byline from 'Components/Byline'
import { Meta } from 'Components/Meta'
import { generateKey } from 'Utils/helpers'
import { fetchArticleDetail } from 'Utils/prismic-configuration'
import { media } from 'Styles/style-utils'

const PageWrap = styled.div`
	text-align: left;
	max-width: 100%;
	display: flex;
	flex-direction: column;
	word-break: break-word;
	padding-bottom: 150px;

	${media.tablet`
      padding-bottom: ${(props) => props.theme.tablet.vHeight};
  `}

	span.superscript {
		line-height: 0;
		vertical-align: super;
		font-size: smaller;
	}

	p {
		margin: 2rem 0;

		${media.tablet`
      margin: 1rem 0;
    `}
	}
`

const HeroImg = styled.img`
	width: 100%;
`

const IntroWrap = styled.div`
	width: ${(props) => props.theme.desktophd.col6};
	margin-top: ${(props) => props.theme.spacing.vspace};
	margin-left: calc(
		${(props) => props.theme.desktophd.col2} +
			${(props) => props.theme.desktophd.marginHorizontal}
	);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;

	${media.tablet`
    width: ${(props) => props.theme.tablet.col7};
    margin-left: calc(
      ${(props) => props.theme.tablet.col1} + calc(
        ${(props) => props.theme.tablet.marginHorizontal} +  ${(props) =>
		props.theme.tablet.gutter} )
    );
    margin-top: ${(props) => props.theme.tablet.vHeight};
  `};

	${media.mobile`
    width: calc(${(props) => props.theme.mobile.col5} + ${(props) =>
		props.theme.mobile.marginHorizontal});
    margin-left: auto;
    padding-left: 0;
    margin-top: ${(props) => props.theme.mobile.vHeight};
  `};
`

const Title = styled.div`
	width: 100%;
	${(props) => props.theme.desktophd.headerMixins.header_4};

	${media.tablet`
    ${(props) => props.theme.desktophd.headerMixins.header_4};
  `}
	${media.mobile`
    ${(props) => props.theme.tablet.headerMixins.header_8};
  `}
`

const Subtitle = styled.div`
	width: 100%;
	margin-top: 2rem;
	${(props) => props.theme.desktophd.headerMixins.header_3};

	${media.tablet`
    ${(props) => props.theme.tablet.headerMixins.header_8};
      margin-top: 1rem;
  `};

	${media.mobile`
    ${(props) => props.theme.mobile.headerMixins.header_6};
  `};
`

const SubtitleWrap = styled.div`
	background: black;
	color: white;
	padding: 0 ${(props) => props.theme.desktophd.marginHorizontal};
	padding-bottom: ${(props) => props.theme.spacing.vspace};
	* {
		padding: 0 ${(props) => props.theme.desktophd.col2};
	}

	${media.tablet`
    margin: 0 auto;
    padding-left: calc(${(props) => props.theme.tablet.col2} + calc(${(props) =>
		props.theme.tablet.gutter} + ${(props) =>
		props.theme.tablet.marginHorizontal} ));
    padding-bottom: ${(props) => props.theme.tablet.vHeight};

    * {
      padding: 0;
    }

  `};

	${media.mobile`
  margin: 0 auto;
    padding-left: calc(${(props) => props.theme.mobile.marginHorizontal});
        padding-bottom: ${(props) => props.theme.mobile.vHeight};
  `};
`

const IllustrationByline = styled.div`
	${(props) => props.theme.desktophd.typeMixins.sans_p5};
	color: #888888;
	margin-top: 1rem;
	margin-bottom: 4rem;
`

const ArticleBody = styled.div`
	margin-top: ${(props) => props.theme.spacing.desktop_hd_vspace};
	${media.tablet`
    margin: 0 auto;
    width: ${(props) => props.theme.tablet.col8};
    padding-left: calc(${(props) => props.theme.tablet.col1} + ${(props) =>
		props.theme.tablet.gutter});
  `};

	${media.mobile`
      width: ${(props) => props.theme.mobile.col5};
      padding-left: 0;
  `};
`

class ArticleDetail extends Component {
	state = {}

	async componentDidMount() {
		window.scrollTo(0, 0)
		const uid = this.props.match.params.id
		const newData = await fetchArticleDetail(uid)
		this.setState({ data: newData })
	}

	render() {
		// once the api data has loaded
		if (this.state.data) {
			const { seo } = this.state.data
			const { hero_image, tags, authorName, authorAvatar, authorUid, notes } =
				this.state.data.english
			let body
			let subtitle
			let title
			let blurb
			let relatedContent
			let illustration_byline

			if (this.props.language === 'english') {
				body = this.state.data.english.body
				relatedContent = this.state.data.english.relatedContent
				subtitle = this.state.data.english.subtitle
				title = this.state.data.english.title
				blurb = this.state.data.english.blurb
				illustration_byline = this.state.data.english.illustration_byline
			} else if (this.props.language === 'spanish') {
				if (this.state.data.spanish) {
					body = this.state.data.spanish.body
					subtitle = this.state.data.spanish.subtitle
					blurb = this.state.data.spanish.blurb
					title = this.state.data.spanish.title
					relatedContent = this.state.data.spanish.relatedContent
					illustration_byline = this.state.data.spanish.illustration_byline
				}
			}

			if (body) {
				return (
					<PageWrap>
						<Meta seo={seo} language={this.props.language} />
						<HeroImg src={hero_image} alt="" />
						<SubtitleWrap>
							<IllustrationByline>{illustration_byline}</IllustrationByline>
							<Subtitle>{subtitle}</Subtitle>
							<Subtitle>{blurb}</Subtitle>
						</SubtitleWrap>

						<IntroWrap>
							<Title>{title}</Title>
							<Byline
								align="left"
								authorUid={authorUid}
								authorName={authorName}
								authorAvatar={authorAvatar}
							/>
						</IntroWrap>

						<ArticleBody>
							{body.map((slice, i) => {
								switch (slice.slice_type) {
									case 'textblock':
										if (slice.primary.text[0]) {
											return (
												<TextBlock
													key={generateKey(slice.primary.text[0].text)}
													text={slice.primary.text}
												/>
											)
										} else return null

									case 'sidebar':
										return (
											<Sidebar
												key={generateKey(slice.primary.sidebar_text)}
												text={slice.primary.sidebar_text}
											/>
										)
									case 'pullquote':
										return (
											<Pullquote
												key={generateKey(slice.primary.text)}
												text={slice.primary.text}
												author={slice.primary.quote_author}
											/>
										)
									case 'image_inline':
										return (
											<ImageInline
												key={generateKey(slice.primary.image_inline.url)}
												img={slice.primary.image_inline.url}
												caption={
													slice.primary.caption[0]
														? slice.primary.caption[0].text
														: null
												}
											/>
										)
									case 'image_block':
										return (
											<ImageBlock
												key={generateKey(slice.primary.image.url + i)}
												img={slice.primary.image.url}
												caption={slice.primary.caption1[0].text}
											/>
										)
									case 'embed':
										return (
											<Embed
												key={i}
												iframe={slice.primary.iframe_code[0].text}
											/>
										)
									default:
										return null
								}
							})}
						</ArticleBody>
						<RelatedContent
							language={this.props.language}
							relatedContent={relatedContent}
						/>
						<Notes notes={notes} />
						<Tags language={this.props.language} tags={tags} />
					</PageWrap>
				)
			} else return null
		} else {
			return null
		}
	}
	// end component
}

export default ArticleDetail
