// @flow

import React from 'react'
import styled from 'styled-components'
import { media } from 'Styles/style-utils'

const Wrapper = styled.div`
	width: 100vw;
	height: 100vh;
	opacity: 0.5;
	display: ${(props) => (props.shouldDisplay ? 'flex' : 'none')};
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	z-index: 10000;
`

const Margin = styled.div`
	background: blue;
	width: 4.9vw;
	${media.tablet`
      width: ${(props) => props.theme.tablet.marginHorizontal};
  `}
	${media.mobile`
    width: ${(props) => props.theme.mobile.marginHorizontal};
  `}
`

const Column = styled.div`
	background: red;
	width: 5.5vw;
	${media.tablet`
      width: ${(props) => props.theme.tablet.col1};
  `}
	${media.mobile`
    width: ${(props) => props.theme.mobile.col1};
  `}
`

const Gutter = styled.div`
	background: yellow;
	width: 2.2vw;
	${media.tablet`
      width: ${(props) => props.theme.tablet.gutter};
  `}
	${media.mobile`
    width: ${(props) => props.theme.mobile.gutter};
  `}
`

const DesktopHdGrid = (props) => {
	const width = window.innerWidth

	if (width < 768) {
		return (
			<Wrapper shouldDisplay={props.display}>
				<Margin />
				<Column />
				<Gutter />
				<Column />
				<Gutter />
				<Column />
				<Gutter />
				<Column />
				<Gutter />
				<Column />
				<Margin />
			</Wrapper>
		)
	} else if (width < 1024) {
		return (
			<Wrapper shouldDisplay={props.display}>
				<Margin />
				<Column />
				<Gutter />
				<Column />
				<Gutter />
				<Column />
				<Gutter />
				<Column />
				<Gutter />
				<Column />
				<Gutter />
				<Column />
				<Gutter />
				<Column />
				<Gutter />
				<Column />
				<Margin />
			</Wrapper>
		)
	} else {
		return (
			<Wrapper shouldDisplay={props.display}>
				<Margin />
				<Column />
				<Gutter />
				<Column />
				<Gutter />
				<Column />
				<Gutter />
				<Column />
				<Gutter />
				<Column />
				<Gutter />
				<Column />
				<Gutter />
				<Column />
				<Gutter />
				<Column />
				<Gutter />
				<Column />
				<Gutter />
				<Column />
				<Gutter />
				<Column />
				<Gutter />
				<Column />
				<Margin />
			</Wrapper>
		)
	}
}

export default DesktopHdGrid
