import React from 'react'
import { RichText } from 'prismic-reactjs'
import styled from 'styled-components'
import { media } from 'Styles/style-utils'

import { linkResolver } from 'Utils/prismic-configuration'

const TextWrap = styled.div`
	margin: 2em auto;
	margin-left: calc(
		${(props) => props.theme.desktophd.col3} +
			calc(
				${(props) => props.theme.desktophd.gutter} +
					${(props) => props.theme.desktophd.marginHorizontal}
			)
	);
	width: ${(props) => props.theme.desktophd.col5};

	h1,
	h2,
	h3,
	h4,
	h5 {
		${(props) => props.theme.desktophd.headerMixins.header_6};
	}

	a {
		color: #c33d64;
	}

	&:last-child {
		margin-bottom: 0;
		p {
			margin-bottom: 0;
		}
	}

	${media.tablet`
      width: 100%;
      margin-left: 0;
  `}
	${media.mobile`
  `}
`

const TextBlock = (props) => {
	return <TextWrap>{RichText.render(props.text, linkResolver)}</TextWrap>
}

export default TextBlock
