import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
	width: ${(props) => props.theme.desktophd.col12};
	margin: 2rem auto;

	img {
		width: 100%;
	}
	p {
		margin-top: 0.5rem !important;
	}
`

const ImageBlock = (props) => {
	if (props.img) {
		return (
			<Wrapper>
				<img alt={props.caption || 'Inline image'} src={props.img} />
				<p>{props.caption}</p>
			</Wrapper>
		)
	} else return null
}

export default ImageBlock
