import React from 'react'

const Facebook = (props) => (
	<svg
		x="0px"
		y="0px"
		width="16px"
		height="30px"
		viewBox="0 0 16 30"
		enableBackground="new 0 0 16 30"
	>
		<title>facebook</title>
		<desc>Created with Sketch.</desc>
		<g id="Symbols">
			<g
				id="Desktop-_x2F_-Footer"
				transform="translate(-1203.000000, -137.000000)"
			>
				<g id="footer">
					<path
						id="facebook"
						d="M1207.048,144.487v2.922H1203v4.852h4.043V167h6.016v-14.734h4.503l0.479-4.852h-4.982v-3.003
				c0-1.811,0.541-2.635,2.395-2.635h2.668v-4.57c-1.162-0.14-2.331-0.209-3.501-0.206
				C1209.045,136.986,1207.048,139.577,1207.048,144.487z"
					/>
				</g>
			</g>
		</g>
	</svg>
)

export default Facebook
