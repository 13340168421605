import React from 'react'

const Instagram = (props) => (
	<svg
		x="0px"
		y="0px"
		width="30px"
		height="30px"
		viewBox="0 0 30 30"
		enableBackground="new 0 0 30 30"
	>
		<g id="Symbols">
			<g
				id="Desktop-_x2F_-Footer"
				transform="translate(-1346.000000, -137.000000)"
			>
				<g id="footer">
					<g id="instagram" transform="translate(1346.000000, 137.000000)">
						<g id="Group-3">
							<g id="Clip-2" />
							<defs>
								<filter
									id="Adobe_OpacityMaskFilter"
									filterUnits="userSpaceOnUse"
									x="0"
									y="0.009"
									width="29.991"
									height="29.991"
								>
									<feColorMatrix
										type="matrix"
										values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
									/>
								</filter>
							</defs>
							<mask
								maskUnits="userSpaceOnUse"
								x="0"
								y="0.009"
								width="29.991"
								height="29.991"
								id="mask-2"
							>
								<g filter="url(#Adobe_OpacityMaskFilter)">
									<polygon
										id="path-1"
										fill="#FFFFFF"
										points="0,0.009 29.991,0.009 29.991,29.996 0,29.996 							"
									/>
								</g>
							</mask>
							<path
								id="Fill-1"
								mask="url(#mask-2)"
								fill="#0A0B09"
								d="M14.995,0.009c-4.073,0-4.583,0.017-6.183,0.09
						c-1.596,0.073-2.686,0.326-3.64,0.697C4.187,1.18,3.35,1.693,2.517,2.526C1.683,3.36,1.17,4.196,0.787,5.182
						C0.417,6.136,0.163,7.226,0.09,8.822C0.017,10.422,0,10.932,0,15.005c0,4.072,0.017,4.583,0.09,6.182
						c0.073,1.596,0.326,2.686,0.697,3.64c0.383,0.986,0.896,1.822,1.73,2.656c0.834,0.834,1.67,1.346,2.656,1.73
						c0.954,0.371,2.044,0.624,3.64,0.697c1.599,0.073,2.11,0.09,6.183,0.09c4.072,0,4.583-0.017,6.182-0.09
						c1.596-0.073,2.686-0.326,3.64-0.697c0.986-0.383,1.822-0.896,2.656-1.73c0.834-0.834,1.346-1.67,1.73-2.656
						c0.371-0.954,0.624-2.044,0.697-3.64c0.073-1.599,0.09-2.11,0.09-6.182c0-4.073-0.017-4.583-0.09-6.183
						c-0.073-1.596-0.326-2.686-0.697-3.64c-0.383-0.986-0.896-1.822-1.73-2.656c-0.834-0.834-1.67-1.346-2.656-1.73
						c-0.954-0.371-2.044-0.624-3.64-0.697C19.578,0.027,19.068,0.009,14.995,0.009z M14.995,2.711c4.004,0,4.478,0.015,6.059,0.087
						c1.462,0.067,2.256,0.311,2.784,0.516c0.7,0.272,1.199,0.597,1.724,1.122c0.525,0.525,0.85,1.024,1.122,1.724
						c0.205,0.528,0.45,1.322,0.516,2.784c0.072,1.581,0.087,2.055,0.087,6.059c0,4.004-0.015,4.478-0.087,6.059
						c-0.067,1.462-0.311,2.256-0.516,2.784c-0.272,0.7-0.597,1.199-1.122,1.724c-0.525,0.525-1.024,0.85-1.724,1.122
						c-0.528,0.205-1.322,0.45-2.784,0.516c-1.581,0.072-2.055,0.087-6.059,0.087c-4.004,0-4.478-0.015-6.059-0.087
						C7.474,27.144,6.68,26.9,6.151,26.694c-0.7-0.272-1.199-0.597-1.724-1.122c-0.525-0.525-0.85-1.024-1.122-1.724
						c-0.205-0.528-0.45-1.322-0.516-2.784c-0.072-1.581-0.087-2.055-0.087-6.059c0-4.004,0.015-4.478,0.087-6.059
						C2.856,7.483,3.1,6.689,3.306,6.161c0.272-0.7,0.597-1.199,1.122-1.724c0.525-0.525,1.024-0.85,1.724-1.122
						C6.68,3.11,7.474,2.865,8.936,2.799C10.517,2.727,10.991,2.711,14.995,2.711z"
							/>
						</g>
						<path
							id="Fill-4"
							fill="#0A0B09"
							d="M14.995,20.003c-2.761,0-4.998-2.238-4.998-4.998c0-2.761,2.238-4.998,4.998-4.998
					c2.761,0,4.998,2.238,4.998,4.998C19.994,17.765,17.756,20.003,14.995,20.003z M14.995,7.304c-4.253,0-7.7,3.448-7.7,7.7
					c0,4.253,3.448,7.7,7.7,7.7c4.253,0,7.7-3.448,7.7-7.7C22.696,10.752,19.248,7.304,14.995,7.304z"
						/>
						<path
							id="Fill-5"
							fill="#0A0B09"
							d="M24.799,7C24.799,7.994,23.994,8.8,23,8.8C22.006,8.8,21.2,7.994,21.2,7
					S22.006,5.201,23,5.201C23.994,5.201,24.799,6.006,24.799,7"
						/>
					</g>
				</g>
			</g>
		</g>
	</svg>
)

export default Instagram
