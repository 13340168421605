// @flow

import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { media } from 'Styles/style-utils'
import { fetchSimpleArticle } from 'Utils/prismic-configuration'
import { getLanguageData } from 'Utils/getLanguageData'

const Wrapper = styled.div`
	width: ${(props) => props.theme.desktophd.col5};
	text-align: center;
	margin: calc(${(props) => props.theme.desktophd.gutter} / 2);
	margin-bottom: ${(props) => props.theme.desktophd.vSpace};
	img {
		width: 100%;
	}

	${media.tablet`
    width: ${(props) => props.theme.tablet.col4};
    margin: calc(${(props) => props.theme.tablet.gutter} / 2);
     margin-bottom: ${(props) => props.theme.tablet.vSpace};
  `}
	${media.mobile`
    width: ${(props) => props.theme.mobile.col5};
    margin: ${(props) => props.theme.mobile.vSpace} 0;
  `}
`

// const ContentType = styled.p`
// 	${(props) => props.theme.desktophd.typeMixins.sans_p1};
// 	margin-top: 0.5rem;
// 	margin-bottom: 0.5rem;

// 	${media.tablet`
//     ${(props) => props.theme.desktophd.typeMixins.sans_p3};
//   `}
// 	${media.mobile`
//     /* ${(props) => props.theme.mobile.typeMixins.sans_p1}; */
//   `}
// `

const Title = styled.h2`
	margin-top: 1.5rem;
	${(props) => props.theme.desktophd.headerMixins.header_5};

	${media.tablet`
    ${(props) => props.theme.desktophd.headerMixins.header_7};
    margin-bottom: 0.75rem;
  `}
`

const Subtitle = styled.p`
	${(props) => props.theme.desktophd.typeMixins.sans_p1};
	margin-bottom: 0.75rem;
	${media.tablet`
    ${(props) => props.theme.tablet.typeMixins.sans_p3};
  `}
	${media.mobile`
    ${(props) => props.theme.mobile.typeMixins.sans_p3};
  `}
`

const AuthorName = styled.p`
	${(props) => props.theme.desktophd.typeMixins.sans_p4};
	color: ${(props) => props.theme.colors.lightdustypink};
	${media.tablet`
    ${(props) => props.theme.tablet.typeMixins.sans_p4};
  `}
	${media.mobile`
    ${(props) => props.theme.mobile.typeMixins.sans_p4};
  `}
`

class ArticleModuleHalfWidth extends React.Component {
	_isMounted = false

	state = {}

	async componentDidMount() {
		this._isMounted = true
		const { uid } = this.props
		const raw = await fetchSimpleArticle(uid, this.props.api)
		const data = {}

		const { authorAvatar, authorName, thumbnail } = raw.english

		data.english = {
			uid,
			authorAvatar,
			authorName,
			thumbnail,
			title: raw.english.title || null,
			subtitle: raw.english.subtitle || null,
			blurb: raw.english.blurb || null,
			illustration_byline: raw.english.illustration_byline || null,
		}

		data.spanish = raw.spanish
			? {
					title: raw.spanish.title || null,
					subtitle: raw.spanish.subtitle || null,
					blurb: raw.spanish.blurb || null,
					illustration_byline: raw.spanish.illustration_byline || null,
			  }
			: null

		if (this._isMounted) {
			this.setState({ data })
		}
	}

	componentWillUnmount() {
		this._isMounted = false
	}

	render() {
		if (this.state.data) {
			const { uid, authorName, thumbnail } = this.state.data.english
			const { subtitle, title } = getLanguageData(
				this.state.data,
				this.props.language,
			)

			return (
				<Wrapper>
					<Link to={`/articles/${uid}`}>
						<img src={thumbnail} alt="" />
						{/* <ContentType>Article</ContentType> */}
						<Title>{title}</Title>
						<Subtitle>{subtitle}</Subtitle>
						<AuthorName>{authorName}</AuthorName>
					</Link>
				</Wrapper>
			)
		} else {
			return null
		}
	}
}

export default ArticleModuleHalfWidth
