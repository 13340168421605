import * as React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import { RichText } from 'prismic-reactjs'
import {
	linkResolver,
	fetchContributor,
	fetchContributorArticles,
} from '../../Utils/prismic-configuration'
import { media } from 'Styles/style-utils'
import { getText } from 'Utils/translatedText'

const ViewContainer = styled.div`
	${(props) => props.theme.layout.container};
`

const Avatar = styled.img`
	${({ theme }) => css`
		width: 280px;
		height: 280px;
		border-radius: 140px;
		overflow: hidden;
		object-fit: cover;
		margin: 0 auto calc(${theme.desktophd.vSpace} - 30px);
	`}
`

const Name = styled.h1`
	${({ theme }) => css`
		font-size: ${theme.desktophd.headerMixins.header_1};
		text-align: center;
	`}
`

const Bio = styled.div`
	${({ theme }) => css`
		width: 100%;
		max-width: ${theme.desktophd.col6};
		margin: 0 auto;
		text-align: left;
		a {
			color: ${(props) => props.theme.colors.pink};
		}

		${media.mobile`
        max-width: ${theme.mobile.col5};
    `}
	`}
`

const Articles = styled.div`
	${({ theme }) => css`
		width: ${theme.desktophd.col10};
		margin: ${theme.desktophd.vSpace} auto 0;

		${media.tablet`
    width: ${theme.tablet.col8};
  `} ${media.mobile`
    width: ${theme.mobile.col5};
  `};
	`}
`

const ArticlesGrid = styled.div`
	${({ theme }) => css`
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		grid-column-gap: ${theme.desktophd.gutter};

		${media.tablet`
    grid-template-columns: 1fr 1fr 1fr
    grid-column-gap: ${theme.tablet.gutter};
  `} ${media.mobile`
    grid-template-columns: 1fr;
    grid-column-gap: 0;
  `};
	`}
`

const ArticleTitle = styled.h3`
	${({ theme }) => css`
		${theme.desktophd.headerMixins.header_5};
	`}
`

const ArticleThumb = styled.div`
	width: 100%;
	margin-bottom: 1.5rem;

	img {
		width: 100%;
	}

	p {
		${(props) => props.theme.desktophd.typeMixins.sans_p4};
		max-width: 100%;
	}
`

class Contributor extends React.Component {
	state = {
		contributor: undefined,
		articles: [],
		loading: true,
	}

	componentDidMount = async () => {
		const { uid } = this.props.match.params
		const contributor = await fetchContributor(uid)
		const articles = await fetchContributorArticles(contributor.id)
		this.setState({
			contributor,
			articles,
			loading: false,
		})
	}

	getContent = (node) => (key) => {
		const { language } = this.props
		const currentLang = node[language]
		return (
			(currentLang ? currentLang[key] : null) ||
			(node.english ? node.english[key] : null) ||
			node[key]
		)
	}

	getContributorContent = () => {
		if (!this.state.contributor) return {}
		const get = this.getContent(this.state.contributor)
		return {
			avatarUrl: get('avatarUrl'),
			fullName: get('fullName'),
			bio: get('bio'),
		}
	}

	render() {
		const { loading, articles } = this.state
		if (loading) return null
		console.log(this.state)
		const { avatarUrl, fullName, bio } = this.getContributorContent()
		console.log(avatarUrl, fullName, bio)
		return (
			<ViewContainer>
				{avatarUrl ? <Avatar src={avatarUrl} alt={fullName} /> : null}
				<Name>{fullName}</Name>
				{bio ? <Bio>{RichText.render(bio, linkResolver)}</Bio> : null}
				{articles && articles.length ? (
					<Articles>
						<ArticleTitle>{getText('articles')}</ArticleTitle>
						<ArticlesGrid>
							{articles.map((article) => {
								console.log(article)
								const get = this.getContent(article)
								console.log(get('title'))

								const to = `/articles/${article.uid}`
								return (
									<Link to={to} key={article.uid}>
										<ArticleThumb>
											<img src={get('thumbnail')} alt={get('title')} />
											<p>{get('title')}</p>
										</ArticleThumb>
									</Link>
								)
							})}
						</ArticlesGrid>
					</Articles>
				) : null}
			</ViewContainer>
		)
	}
}

export default Contributor
