import React from 'react'
// import styled from 'styled-components'
import { fetchModule } from 'Utils/prismic-configuration'
// import { media } from 'Styles/style-utils'

// const ShopModuleWrapper = styled.div`
// 	width: ${(props) => (props.width === 'full' ? '100%' : '50%')};
// 	background-color: ${(props) => props.theme.colors.mauve};
// 	display: flex;
// 	flex-direction: ${(props) => (props.width === 'full' ? 'row' : 'column')};
// 	justify-content: center;
// 	align-items: center;
// 	padding: ${(props) => props.theme.desktophd.marginHorizontal};
// 	margin-bottom: ${(props) => props.theme.desktophd.vSpace};

// 	${media.tablet`
//     margin-bottom: ${(props) => props.theme.tablet.vSpace};
//   `}

// 	${media.mobile`
//     width: 100%;
//     margin: ${(props) => props.theme.mobile.vSpace} 0;
//   `}

//   a {
// 		color: ${(props) => props.theme.colors.pink};
// 		${(props) => props.theme.desktophd.typeMixins.sans_p2};
// 		max-width: 100%;
// 	}

// 	p {
// 		max-width: 100%;
// 	}

// 	${media.mobile`
//     flex-direction: column;
//     width: 100%;
//   `}
// `

// const Heading = styled.p`
// 	${(props) => props.theme.desktophd.typeMixins.sans_p1};
// 	margin-bottom: 48px;
// 	color: white;

// 	${media.tablet`
//     margin-bottom: ${(props) => props.theme.tablet.x};
//       ${(props) => props.theme.desktophd.typeMixins.sans_p3};
//       text-align: center;
//   `}
// `

// const Button = styled.a`
// 	${(props) => props.theme.buttonMixins.buttonOne};
// 	background: black;

// 	${media.tablet`
//       ${(props) => props.theme.buttonMixins.buttonOneTablet};
//       width: auto;
//   `}
// `

// const FlexItem = styled.div`
// 	width: ${(props) =>
// 		props.width === 'full'
// 			? props.theme.desktophd.col5
// 			: props.theme.desktophd.col8};
// 	max-width: 100%;
// 	text-align: center;
// 	display: flex;
// 	flex-direction: column;
// 	justify-content: center;
// 	align-items: center;

// 	${media.mobile`
//     img, p {
//       margin-bottom: 1rem;
//     }
//   `}
// `

class ShopModule extends React.Component {
	_isMounted = false
	state = {}

	async componentDidMount() {
		this._isMounted = true
		const raw = await fetchModule('shop_module')

		let cta
		let button_cta
		let image = raw.english.image.url

		// read the state and determine whether or not the app is in english or spanish, return the data appropriately
		if (this.props.language === 'english') {
			cta = raw.english.cta[0].text
			button_cta = raw.english.button_cta[0].text
		} else if (this.props.language === 'spanish') {
			cta = raw.spanish.cta[0].text
			button_cta = raw.spanish.button_cta[0].text
		}

		const data = { cta, button_cta, image }
		if (this._isMounted) {
			this.setState({ data })
		}
	}

	componentWillUnmount() {
		this._isMounted = false
	}

	render() {
		// SHOP is disabled
		return null
		// if (this.state.data) {
		// 	const { cta, button_cta, image } = this.state.data
		// 	return (
		// 		<ShopModuleWrapper width={this.props.width}>
		// 			<FlexItem>
		// 				<img src={image} alt="" />
		// 			</FlexItem>
		// 			<FlexItem>
		// 				<Heading>{cta}</Heading>
		// 				<Button
		// 					href="https://swag.pussypedia.net"
		// 					target="_blank"
		// 					rel="noopener noreferrer"
		// 				>
		// 					{button_cta}
		// 				</Button>
		// 			</FlexItem>
		// 		</ShopModuleWrapper>
		// 	)
		// } else {
		// 	return ' '
		// }
	}
}

export default ShopModule
