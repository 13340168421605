import Prismic from 'prismic-javascript'

export const apiEndpoint = 'https://pussypedia.prismic.io/api/v2'

export const linkResolver = function (doc) {
	// Pretty URLs for known types
	if (doc.type === 'article') return '/articles/' + doc.uid
	if (doc.type === 'tag') return '/tags/' + doc.uid
	// Fallback for other types, in case new custom types get created
	return '/' + doc.uid
}

export const fetchContributorArticles = async (id) => {
	const api = await Prismic.api(apiEndpoint)
	const response = await fetchAll(api, [
		Prismic.Predicates.at('document.type', 'article'),
		Prismic.Predicates.at('my.article.author', id),
	])
	const parsed = response.map(parseArticle)
	const articles = await fetchMissingDocuments(parsed, parseArticle)
	return articles.reduce(combineByLang, [])
}

export const fetchContributor = async (uid) => {
	const api = await Prismic.api(apiEndpoint)
	const response = await api.query(
		[
			Prismic.Predicates.at('document.type', 'person'),
			Prismic.Predicates.at('my.person.uid', uid),
		],
		{ lang: '*' },
	)
	const people = response.results.map(parsePerson)

	const withLangs = await fetchMissingDocuments(people, parsePerson)
	const person = withLangs.reduce(combineByLang, [])[0]
	return person
}

const fetchAll = async (api, query, args = {}, page = 1, prevResults = []) => {
	const response = await api.query(query, {
		lang: '*',
		pageSize: 100,
		page,
		...args,
	})
	if (response.page < response.total_pages) {
		return fetchAll(api, query, args, page + 1, [
			...prevResults,
			...response.results,
		])
	}
	return [...prevResults, ...response.results]
}

const parsePerson = (person) => {
	const { type, uid, data, id, lang } = person
	const firstName =
		data.first_name && data.first_name[0] ? data.first_name[0].text : null
	const lastName =
		data.last_name && data.last_name[0] ? data.last_name[0].text : null
	const avatarUrl = data.avatar ? data.avatar.url : null
	const bio = data.bio ? data.bio : null
	const fullName = [firstName, lastName].join(' ')
	const primaryData = {
		lang,
		firstName,
		lastName,
		fullName,
		bio,
		avatarUrl,
		type,
		uid,
	}

	const primaryKey = langKeys[person.lang]

	return {
		id,
		alternate_languages: person.alternate_languages,
		uid: person.uid,
		type: person.type,
		lang: person.lang,
		[primaryKey]: primaryData,
	}
}

export const fetchContributors = async () => {
	const api = await Prismic.api(apiEndpoint)

	const contributors = await fetchAll(api, [
		Prismic.Predicates.at('document.type', 'person'),
	]).then((all) => {
		const parsed = all.map(parsePerson)
		return fetchMissingDocuments(parsed)
	})

	return contributors.reduce(combineByLang, [])
}

export const fetchArticles = async () => {
	const api = await Prismic.api(apiEndpoint)

	const articles = await fetchAll(api, [
		Prismic.Predicates.at('document.type', 'article'),
	]).then((all) => {
		const parsed = all.map(parseArticle)
		return fetchMissingDocuments(parsed)
	})

	return articles.reduce(combineByLang, [])
}
const parseArticle = (article) => {
	const { data, type, uid, lang } = article
	const title = data.title[0] ? data.title[0].text : null
	const subtitle = data.subtitle[0] ? data.subtitle[0].text : null
	const thumbnail = data.thumbnail ? data.thumbnail.url : null
	const primaryData = { title, lang, subtitle, thumbnail, type, uid }

	const primaryKey = langKeys[article.lang]

	return {
		alternate_languages: article.alternate_languages,
		uid: article.uid,
		type: article.type,
		lang: article.lang,
		[primaryKey]: primaryData,
	}
}

export const fetchRelatedArticles = async (uid) => {
	const api = await Prismic.api(apiEndpoint)
	let tag = await fetchTagData(uid, api)
	let id = tag ? tag.id : null

	if (!id) {
		return
	}

	const tagIds = [tag.english.id, tag.spanish.id].filter(Boolean)

	const queriedContent = await api.query(
		[
			Prismic.Predicates.at('document.type', 'article'),
			Prismic.Predicates.any('my.article.tags.tag', tagIds),
		],
		{ lang: '*', pageSize: 100 },
	)

	// scrub data, return only title, subtitle, thumb
	const queriedArticles = queriedContent.results.map(parseArticle)

	const articles = await fetchMissingDocuments(queriedArticles)

	return articles.reduce(combineByLang, [])
}

export const fetchResource = async (resource, type, api) => {
	let { id, uid } = resource
	let { title, subtitle, thumbnail, resource_link, source, module_type, tags } =
		resource.data
	title = title[0] ? title[0].text : null
	subtitle = subtitle && subtitle[0] ? subtitle[0].text : null
	thumbnail = thumbnail ? thumbnail.url : null
	let link = resource_link.url || null
	source = source[0] ? source[0].text : null

	const englishData = {
		id,
		uid,
		title,
		subtitle,
		thumbnail,
		link,
		source,
		module_type,
		type,
		tags,
	}

	const alternateLanguages = resource.alternate_languages
	let spanishData
	// if there is an alternate language, collect query relevant data for spanish
	if (alternateLanguages[0]) {
		const spanishUid = alternateLanguages[0].uid
		const lang = alternateLanguages[0].lang
		const spanishDocument = await api.getByUID('resource', spanishUid, { lang })
		const data = spanishDocument ? spanishDocument.data : {}
		const title = data.title ? data.title[0].text : null
		const subtitle = data.subtitle[0] ? data.subtitle[0].text : null
		const source = data.source[0] ? data.source[0].text : null
		spanishData = { title, subtitle, source }
	}

	const finalData = {
		english: englishData,
		spanish: spanishData,
	}

	return finalData
}

export const fetchResourcesResourcesPage = async (resource, type, api) => {
	let { id, uid } = resource
	let { title, subtitle, thumbnail, resource_link, source, module_type, tags } =
		resource.data
	const spanishUid = resource.alternate_languages[0]
		? resource.alternate_languages[0].uid
		: null
	title = title[0] ? title[0].text : null
	subtitle = subtitle && subtitle[0] ? subtitle[0].text : null
	thumbnail = thumbnail ? thumbnail.url : null
	let link = resource_link.url || null
	source = source[0] ? source[0].text : null

	const englishData = {
		id,
		uid,
		title,
		subtitle,
		thumbnail,
		link,
		source,
		module_type,
		type,
		tags,
		spanishUid,
	}

	// const alternateLanguages = resource.alternate_languages
	// let spanishData
	// if there is an alternate language, collect query relevant data for spanish
	// if (alternateLanguages[0]) {
	// 	const spanishUid = alternateLanguages[0].uid
	// 	const lang = alternateLanguages[0].lang
	// 	const spanishDocument = await api.getByUID('resource', spanishUid, { lang })
	// 	const data = spanishDocument ? spanishDocument.data : {}
	// 	const title = data.title ? data.title[0].text : null
	// 	const subtitle = data.subtitle[0] ? data.subtitle[0].text : null
	// 	const source = data.source[0] ? data.source[0].text : null
	// 	spanishData = { title, subtitle, source }
	// }

	return englishData
}

export const fetchModule = async (moduletype) => {
	const api = await Prismic.api(apiEndpoint)
	const response = await api.query(
		Prismic.Predicates.at('document.type', moduletype),
	)

	if (response) {
		const document = response.results[0]

		const englishData = response.results[0].data

		const alternateLanguages = document.alternate_languages
		let spanishData

		if (alternateLanguages[0]) {
			const spanishUid = alternateLanguages[0].uid
			const lang = alternateLanguages[0].lang
			const spanishDocument = await api
				.getByUID(moduletype, spanishUid, { lang })
				.catch((e) => console.log('e', e))
			spanishData = spanishDocument ? spanishDocument.data : {}
		}

		const finalData = {
			english: englishData,
			spanish: spanishData,
		}

		// return {english:{}, spanish:{}}
		return finalData
	}
}

const head = (arr) => (arr && arr.length ? arr[0] : undefined)
const prop = (key, obj) => (obj && obj[key] ? obj[key] : undefined)

const mergeSeo = (enData = {}, esData = {}, misc = {}) => ({
	en: {
		title: prop('text', head(enData.seo_title)),
		description: prop('text', head(enData.seo_description)),
		image: enData.seo_image,
	},
	es: {
		title: prop('text', head(esData.seo_title)),
		description: prop('text', head(esData.seo_description)),
		image: esData.seo_image,
	},
	...misc,
})

// TODO: make sure fetchHome returns 100 results
export const fetchHome = async () => {
	const api = await Prismic.api(apiEndpoint)
	const response = await api.query(
		Prismic.Predicates.at('document.type', 'home', { lang: '*' }),
	)

	const homepage = response.results[0]
	const englishData = homepage.data

	// Fetch Spanish meta info as well
	let spanishData
	const altLang = homepage.alternate_languages[0]
	if (altLang) {
		const { lang, uid } = altLang
		const spanishDocument = await api.getByUID('home', uid, { lang })
		spanishData = spanishDocument ? spanishDocument.data : {}
	}

	const seo = mergeSeo(englishData, spanishData, { uri: '' })

	return {
		seo,
		body: englishData.body,
	}
}

export const fetchTopicsLanding = async () => {
	const api = await Prismic.api(apiEndpoint)
	const response = await api.query(
		Prismic.Predicates.at('document.type', 'topicslanding'),
	)

	const data = response.results[0].data || {}
	const document = response.results[0]
	const title = data.title[0].text
	const subtitle = data.subtitle[0].text
	const englishData = { title, subtitle }

	const alternateLanguages = document.alternate_languages
	let spanishData
	// if there is an alternate language, collect query relevant data for spanish
	if (alternateLanguages[0]) {
		const spanishUid = alternateLanguages[0].uid

		const lang = alternateLanguages[0].lang
		const spanishDocument = await api.getByUID('topicslanding', spanishUid, {
			lang,
		})
		const data = spanishDocument ? spanishDocument.data : {}
		const title = data && data.title ? data.title[0].text : undefined
		const subtitle = data && data.subtitle ? data.subtitle[0].text : undefined
		spanishData = { title, subtitle }
	}

	const finalData = { english: englishData, spanish: spanishData }
	return finalData
}

const langKeys = {
	'en-us': 'english',
	'es-mx': 'spanish',
}

export const fetchRelatedResources = async (uid) => {
	const api = await Prismic.api(apiEndpoint)

	let tag = await fetchTagData(uid, api)
	let id = tag ? tag.id : null

	if (!id) {
		return null
	}

	const tagIds = [tag.english.id, tag.spanish.id].filter(Boolean)

	const queriedContent = await api.query(
		[
			Prismic.Predicates.at('document.type', 'resource'),
			Prismic.Predicates.any('my.resource.tags.tag', tagIds),
		],
		{ lang: '*', pageSize: 100 },
	)

	// scrub data, return only title, subtitle, thumb
	const queriedResources = queriedContent.results.map((resource) => {
		const { data } = resource
		const title = data.title[0] ? data.title[0].text : null
		const subtitle = data.subtitle[0] ? data.subtitle[0].text : null
		const thumbnail = data.thumbnail.url
		const link = data.resource_link.url
		const { type, uid } = resource

		const primaryData = {
			title,
			subtitle,
			thumbnail,
			type,
			link,
			uid,
			lang: resource.lang,
		}

		const primaryKey = langKeys[resource.lang]

		const returnData = {
			alternate_languages: resource.alternate_languages,
			uid: resource.uid,
			type: resource.type,
			lang: resource.lang,
			[primaryKey]: primaryData,
		}

		return returnData
	})
	const resources = await fetchMissingDocuments(queriedResources)

	return resources.reduce(combineByLang, [])
}

export const fetchTagData = async (uid, api) => {
	const tagData = await api.getByUID('tag', uid).then(function (t) {
		return t
	})

	if (tagData) {
		const { id } = tagData
		const title = tagData.data.tag[0].text

		const englishData = { id, title }

		const alternateLanguages = tagData.alternate_languages
		let spanishData
		if (alternateLanguages[0]) {
			const spanishUid = alternateLanguages[0].uid
			const lang = alternateLanguages[0].lang
			const spanishDocument = await api.getByUID('tag', spanishUid, { lang })
			const data = spanishDocument ? spanishDocument.data.tag[0] : {}
			const id = spanishDocument ? spanishDocument.id : undefined
			const title = data.text
			spanishData = { id, title }
		}

		const finalData = {
			uid,
			id: id,
			english: englishData,
			spanish: spanishData,
		}
		return finalData
	} else {
		return null
	}
}

export const articleQuery = `{
  article {
    title
    subtitle
    hero_image
    thumbnail
    body
    illustration_byline
    blurb
    tags
    notes
    author{
      uid
      first_name
      last_name
      avatar
    }
    related_content {
      item{
        ...on resource {
          title
          thumbnail
          source
          resource_link
        }
      }
    }
  }
}`

export const simpleArticleQuery = `{
  article {
    title
    subtitle
    hero_image
    thumbnail
    illustration_byline
    blurb
    author{
      uid
      first_name
      last_name
      avatar
    }
  }
}`

export const fetchSimpleArticle = async (uid, api) => {
	if (uid && api) {
		const document = await api.getByUID('article', uid, {
			graphQuery: simpleArticleQuery,
			lang: 'en-us',
		})

		const data = document ? document.data : {}
		let {
			author,
			blurb,
			thumbnail,
			hero_image,
			illustration_byline,
			subtitle,
			title,
		} = data

		hero_image = data.hero_image ? data.hero_image.url : null
		thumbnail = data.thumbnail ? data.thumbnail.url : null
		title = title[0] ? title[0].text : null
		subtitle = subtitle[0] ? subtitle[0].text : null
		blurb = blurb[0] ? blurb[0].text : null
		illustration_byline = illustration_byline[0]
			? illustration_byline[0].text
			: null
		const authorName = `${
			author && author.data && author.data.first_name[0]
				? author.data.first_name[0].text
				: null
		} ${
			author && author.data && data.author.data.last_name[0]
				? data.author.data.last_name[0].text
				: null
		}`
		const authorAvatar =
			author && author.data && author.data.avatar
				? author.data.avatar.url
				: null
		const authorUid = author && author.data ? author.data.uid : undefined

		const englishData = {
			authorName,
			authorUid,
			authorAvatar,
			blurb,
			hero_image,
			thumbnail,
			illustration_byline,
			title,
			subtitle,
		}

		const alternateLanguages = document.alternate_languages
		let spanishData

		if (alternateLanguages[0]) {
			const spanishUid = alternateLanguages[0].uid

			const lang = alternateLanguages[0].lang
			const spanishDocument = await api.getByUID('article', spanishUid, {
				lang,
				graphQuery: simpleArticleQuery,
			})

			let { blurb, illustration_byline, subtitle, title } = spanishDocument
				? spanishDocument.data
				: {}

			title = title ? title[0].text : undefined
			subtitle = subtitle ? subtitle[0].text : undefined
			blurb = blurb && blurb[0] ? blurb[0].text : null
			illustration_byline =
				illustration_byline && illustration_byline[0]
					? illustration_byline[0].text
					: null

			spanishData = {
				title,
				subtitle,
				blurb,
				illustration_byline,
			}
		}

		const finalArticleDetailData = {
			english: englishData,
			spanish: spanishData,
		}

		return finalArticleDetailData
	}
}

export const fetchArticleDetail = async (uid) => {
	const api = await Prismic.api(apiEndpoint)
	const document = await api.getByUID('article', uid, {
		graphQuery: articleQuery,
		lang: 'en-us',
	})
	const data = document.data

	let {
		author,
		blurb,
		body,
		hero_image,
		thumbnail,
		illustration_byline,
		related_content,
		subtitle,
		title,
		tags,
		notes,
	} = document.data

	tags = await Promise.all(
		tags.map(async (t) => {
			if (t.tag.uid) {
				const uid = t.tag.uid
				const tagData = await fetchTagData(uid, api)
				return tagData
			}
		}),
	)

	hero_image = data.hero_image.url || null
	thumbnail = data.thumbnail ? data.thumbnail.url : null
	title = title[0] ? title[0].text : null
	subtitle = subtitle[0] ? subtitle[0].text : null
	blurb = blurb[0] ? blurb[0].text : null
	illustration_byline = illustration_byline[0]
		? illustration_byline[0].text
		: null
	const authorName = `${
		author && author.data && author.data.first_name[0]
			? author.data.first_name[0].text
			: null
	} ${
		author && author.data && data.author.data.last_name[0]
			? data.author.data.last_name[0].text
			: null
	}`

	const authorAvatar =
		author && author.data && author.data.avatar ? author.data.avatar.url : null
	const authorUid = author && author.data ? author.data.uid : undefined

	let relatedContent
	// make sure there is at least one related_content item
	if (related_content.length > 0) {
		relatedContent = related_content.map((rc) => {
			if (rc.item.data) {
				const data = rc.item.data
				let { resource_link, source, thumbnail, title } = data
				let link = resource_link ? resource_link.url : null
				source = source ? source[0].text : null
				thumbnail = thumbnail.url
				title = title[0].text

				return { title, thumbnail, source, link }
			}
			return {}
		})
	} else {
		relatedContent = null
	}

	// gather english Data
	const englishData = {
		authorName,
		authorAvatar,
		authorUid,
		blurb,
		body,
		hero_image,
		thumbnail,
		illustration_byline,
		relatedContent,
		tags,
		title,
		subtitle,
		notes,
	}

	// if there is an alternate language, collect query relevant data for spanish
	const alternateLanguages = document.alternate_languages
	let spanishData = {}

	if (alternateLanguages[0]) {
		const spanishUid = alternateLanguages[0].uid

		const lang = alternateLanguages[0].lang
		const spanishDocument = await api.getByUID('article', spanishUid, {
			lang,
			graphQuery: articleQuery,
		})

		let {
			blurb,
			body,
			illustration_byline,
			related_content,
			subtitle,
			title,
			notes,
		} = spanishDocument ? spanishDocument.data : {}

		let relatedContent
		// make sure there is at least one related_content item
		if (related_content && related_content.length > 0) {
			relatedContent = related_content.map((rc) => {
				if (rc.item.data) {
					const data = rc.item.data
					let { resource_link, source, thumbnail, title } = data
					let link = resource_link ? resource_link.url : null
					source = source ? source[0].text : null
					thumbnail = thumbnail ? thumbnail.url : null
					title = title[0].text
					return { title, thumbnail, source, link }
				}
				return {}
			})
		} else {
			relatedContent = null
		}

		title = title && title[0].text
		subtitle =
			subtitle && subtitle[0] && subtitle[0].text ? subtitle[0].text : null
		blurb = blurb && blurb[0] ? blurb[0].text : null
		illustration_byline =
			illustration_byline && illustration_byline[0]
				? illustration_byline[0].text
				: null

		spanishData = {
			title,
			subtitle,
			blurb,
			illustration_byline,
			body,
			relatedContent,
			notes,
		}
	}

	const seo = {
		en: {
			title: englishData.title,
			description: englishData.subtitle + ' ' + englishData.blurb,
			image: {
				url: englishData.hero_image,
			},
		},
		es: {
			title: spanishData.title,
			description: spanishData.subtitle + ' ' + spanishData.blurb,
		},
		uri: `/${uid}`,
		authors: [englishData.authorUid],
	}
	// console.log(seo)
	// create object for spanish and english data
	const finalArticleDetailData = {
		seo,
		english: englishData,
		spanish: spanishData,
	}

	return finalArticleDetailData
}

const fetchMissingDocuments = async (docs, parser) => {
	const api = await Prismic.api(apiEndpoint)
	const fetched = await Promise.all(
		docs.map(async (doc, index, allDocs) => {
			const fetchedAltDocs = await Promise.all(
				doc.alternate_languages.map(async (altLang) => {
					if (allDocs.find((d) => d.uid === altLang.uid)) return null
					const fetchedDoc = await api.getByUID(altLang.type, altLang.uid, {
						lang: altLang.lang,
					})
					if (parser) return parser(fetchedDoc)
					const { data } = fetchedDoc

					const docInfo = {
						title: data.title && data.title[0] ? data.title[0].text : null,
						subtitle:
							data.subtitle && data.subtitle[0] ? data.subtitle[0].text : null,
						thumbnail: data.thumbnail ? data.thumbnail.url : null,
						uid: fetchedDoc.uid,
						lang: fetchedDoc.lang,
					}
					const langKey = langKeys[fetchedDoc.lang]

					return {
						alternate_languages: fetchedDoc.alternate_languages,
						[langKey]: docInfo,
						lang: fetchedDoc.lang,
						type: fetchedDoc.type,
						uid: fetchedDoc.uid,
					}
				}),
			)
				.then((d) => d.filter(Boolean))
				.then((d) =>
					d.reduce((acc, item) => {
						return {
							...item,
							...acc,
						}
					}, {}),
				)

			return [doc, fetchedAltDocs].filter((d) => Boolean(d.uid))
		}),
	)
	return fetched.flat()
}

const combineByLang = (acc, current, index, all) => {
	const alreadyMatched = acc.some((item) =>
		item.alternate_languages.map((al) => al.uid).includes(current.uid),
	)
	if (alreadyMatched) return acc

	const matches = current.alternate_languages
		.map((altLang) => {
			const item = all.find(
				(item) => item.uid === altLang.uid && item.lang === altLang.lang,
			)
			if (!item) throw new Error(`Missing item: ${altLang.uid}`)
			const langKey = langKeys[altLang.lang]
			return item[langKey]
		})
		.reduce((acc, item, i, all) => {
			const langKey = langKeys[item.lang]

			return {
				...acc,
				[langKey]: item,
			}
		}, {})

	const currentMatched = {
		...current,
		...matches,
		uid:
			current.lang === 'en-us'
				? current.uid
				: current.alternate_languages &&
				  current.alternate_languages.length &&
				  current.alternate_languages[0].lang === 'en-us'
				? current.alternate_languages[0].uid
				: current.uid,
	}
	return [...acc, currentMatched]
}

export const fetchAllTags = async (page = 1, prevResults = []) => {
	const api = await Prismic.api(apiEndpoint)
	const response = await api.query(
		Prismic.Predicates.at('document.type', 'tag'),
		{
			pageSize: 100,
			lang: '*',
			page,
		},
	)
	const tags = response.results.map((result) => {
		const { data } = result

		const primaryData = {
			title: data.tag[0].text,
			lang: result.lang,
		}

		const primaryKey = langKeys[result.lang]

		return {
			alternate_languages: result.alternate_languages,
			uid: result.uid,
			type: result.type,
			lang: result.lang,
			[primaryKey]: primaryData,
		}
	})
	if (response.page < response.total_pages) {
		return fetchAllTags(page + 1, [...prevResults, ...tags])
	}
	return [...prevResults, ...tags]
}

export const fetchTags = async () => {
	const tags = await fetchAllTags()

	const allTags = await fetchMissingDocuments(tags)
	return allTags.reduce(combineByLang, [])
}

export const fetchTopics = async () => {
	const api = await Prismic.api(apiEndpoint)
	const response = await api.query(
		Prismic.Predicates.at('document.type', 'topic'),
		{ lang: '*', pageSize: 100 },
	)

	const topics = response.results.map((topic) => {
		let { data, tags, uid } = topic
		let { title, subtitle, thumbnail } = data
		title = title && title[0] ? title[0].text : null
		subtitle = subtitle && subtitle[0] ? subtitle[0].text : null
		thumbnail = thumbnail.url
		const primaryData = {
			lang: topic.lang,
			title,
			subtitle,
			thumbnail,
			tags,
			uid,
		}

		const primaryKey = langKeys[topic.lang]

		return {
			alternate_languages: topic.alternate_languages,
			uid: topic.uid,
			type: topic.type,
			lang: topic.lang,
			[primaryKey]: primaryData,
		}
	})

	const allTopics = await fetchMissingDocuments(topics)

	return allTopics.reduce(combineByLang, [])
}

export const fetchAbout = async () => {
	const api = await Prismic.api(apiEndpoint)
	const data = await api
		.getByUID('aboutpage', 'about')
		.then(function (aboutData) {
			return aboutData
		})

	const englishData = data.data

	const alternateLanguages = data.alternate_languages

	let spanishData
	// if there is an alternate language, collect query relevant data for spanish
	if (alternateLanguages[0]) {
		const api = await Prismic.api(apiEndpoint)
		const spanishUid = alternateLanguages[0].uid
		const lang = alternateLanguages[0].lang
		const spanishDocument = await api.getByUID('aboutpage', spanishUid, {
			lang,
		})
		const data = spanishDocument ? spanishDocument.data : {}
		spanishData = data
	}

	const finalData = {
		english: englishData,
		spanish: spanishData,
	}

	return finalData
}

export const fetchNewsletterSuccess = async () => {
	const api = await Prismic.api(apiEndpoint)
	const data = await api
		.getByUID('newsletter_success', 'newsletter-success')
		.then(function (newsletterData) {
			return newsletterData
		})

	const englishData = data.data

	const alternateLanguages = data.alternate_languages

	let spanishData
	// if there is an alternate language, collect query relevant data for spanish
	if (alternateLanguages[0]) {
		const api = await Prismic.api(apiEndpoint)
		const spanishUid = alternateLanguages[0].uid
		const lang = alternateLanguages[0].lang
		const spanishDocument = await api.getByUID(
			'newsletter_success',
			spanishUid,
			{ lang },
		)
		const data = spanishDocument ? spanishDocument.data : {}
		spanishData = data
	}

	const finalData = {
		english: englishData,
		spanish: spanishData,
	}

	return finalData
}

const fetchTextDocument = (type, uid) => async () => {
	try {
		const api = await Prismic.api(apiEndpoint)
		const response = await api.getByUID(type, uid)
		const english = response.data
		const alternateLanguages = response.alternate_languages

		let spanish = undefined

		if (alternateLanguages[0]) {
			const spanishUid = alternateLanguages[0].uid
			const lang = alternateLanguages[0].lang
			const spanishDocument = await api.getByUID(type, spanishUid, {
				lang,
			})
			const data = spanishDocument ? spanishDocument.data : null
			spanish = data
		}

		return { english, spanish }
	} catch (e) {
		console.log(e)
		return {}
	}
}

export const fetchPussyDefinition = fetchTextDocument(
	'pussy_definition',
	'pussy-definition',
)
export const fetchTermsOfUse = fetchTextDocument('aboutpage', 'terms-of-use')
export const fetchPrivacyPolicy = fetchTextDocument(
	'aboutpage',
	'privacy-policy',
)

// export const fetchPussyDefinition = async () => {
// 	const api = await Prismic.api(apiEndpoint)
// 	const data = await api.getByUID('pussy_definition', 'pussy-definition')

// 	const englishData = data.data

// 	const alternateLanguages = data.alternate_languages

// 	let spanishData
// 	// if there is an alternate language, collect query relevant data for spanish
// 	if (alternateLanguages[0]) {
// 		const spanishUid = alternateLanguages[0].uid
// 		const lang = alternateLanguages[0].lang
// 		const spanishDocument = await api.getByUID('pussy_definition', spanishUid, {
// 			lang,
// 		})
// 		const data = spanishDocument ? spanishDocument.data : null
// 		spanishData = data
// 	}

// 	const finalData = { english: englishData, spanish: spanishData }

// 	return finalData
// }

export const fetchContact = async () => {
	const api = await Prismic.api(apiEndpoint)
	const data = await api
		.getByUID('contactpage', 'contact')
		.then(function (contactData) {
			return contactData
		})

	const englishData = data.data

	const alternateLanguages = data.alternate_languages

	let spanishData
	// if there is an alternate language, collect query relevant data for spanish
	if (alternateLanguages[0]) {
		const api = await Prismic.api(apiEndpoint)
		const spanishUid = alternateLanguages[0].uid
		const lang = alternateLanguages[0].lang
		const spanishDocument = await api.getByUID('contactpage', spanishUid, {
			lang,
		})
		const data = spanishDocument ? spanishDocument.data : {}
		spanishData = data
	}

	const finalData = { english: englishData, spanish: spanishData }

	return finalData
}

export const fetchContribute = async () => {
	const api = await Prismic.api(apiEndpoint)
	const data = await api
		.getByUID('contributepage', 'contribute')
		.then(function (contributeData) {
			return contributeData
		})

	const englishData = data.data

	const alternateLanguages = data.alternate_languages

	let spanishData
	// if there is an alternate language, collect query relevant data for spanish
	if (alternateLanguages[0]) {
		const api = await Prismic.api(apiEndpoint)
		const spanishUid = alternateLanguages[0].uid
		const lang = alternateLanguages[0].lang
		const spanishDocument = await api.getByUID('contributepage', spanishUid, {
			lang,
		})
		const data = spanishDocument ? spanishDocument.data : {}
		spanishData = data
	}

	const finalData = { english: englishData, spanish: spanishData }

	return finalData
}
