import React from 'react'

const Twitter = (props) => (
	<svg
		x="0px"
		y="0px"
		width="32px"
		height="26px"
		viewBox="0 0 32 26"
		enableBackground="new 0 0 32 26"
	>
		<title>twitter</title>
		<desc>Created with Sketch.</desc>
		<g id="Symbols">
			<g
				id="Desktop-_x2F_-Footer"
				transform="translate(-1266.000000, -139.000000)"
			>
				<g id="footer">
					<path
						id="twitter"
						d="M1276.061,165c12.073,0,18.675-10.002,18.675-18.675c0-0.284-0.006-0.567-0.019-0.849
				c1.281-0.926,2.396-2.082,3.274-3.398c-1.176,0.522-2.442,0.875-3.769,1.033c1.355-0.812,2.396-2.098,2.886-3.631
				c-1.268,0.752-2.673,1.299-4.168,1.593c-1.198-1.276-2.903-2.074-4.791-2.074c-3.625,0-6.565,2.94-6.565,6.563
				c0,0.515,0.058,1.016,0.17,1.497c-5.455-0.274-10.292-2.886-13.529-6.858c-0.564,0.97-0.889,2.097-0.889,3.299
				c0,2.277,1.159,4.288,2.921,5.464c-1.076-0.033-2.088-0.329-2.973-0.821c-0.001,0.027-0.001,0.055-0.001,0.084
				c0,3.179,2.263,5.833,5.266,6.435c-0.551,0.15-1.131,0.231-1.73,0.231c-0.423,0-0.834-0.042-1.234-0.118
				c0.836,2.608,3.259,4.505,6.132,4.559c-2.247,1.76-5.077,2.81-8.152,2.81c-0.53,0-1.052-0.03-1.566-0.091
				C1268.905,163.913,1272.354,165,1276.061,165"
					/>
				</g>
			</g>
		</g>
	</svg>
)

export default Twitter
