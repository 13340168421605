import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { withRouter } from 'react-router'
import Nav from './Nav'
import { HeaderLogo, Search } from 'Components/Icons'
import { media } from 'Styles/style-utils'
import { getText } from 'Utils/translatedText'

const HeaderContainer = styled.div`
	z-index: ${(props) => props.theme.z.header};
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: ${(props) => props.theme.height.nav_desktop};
	transition: 0.25s box-shadow, 0.25s background-color;
	box-shadow: ${(props) =>
		props.scrollNav ? '5px 5px 5px rgba(0, 0, 0, 0.1)' : 'unset'};
	background-color: ${(props) => {
		if (props.expanded) {
			return '#171313'
		} else if (props.filterNavMobile) {
			return 'black'
		} else if (props.inverted) {
			if (props.scrollNav) {
				return '#171313'
			} else {
				return 'transparent'
			}
		} else if (props.scrollNav) {
			if (!props.filterNavMobile) {
				return 'white'
			}
		} else {
			return 'transparent'
		}
	}};
	.logotype-wrap {
		height: 15px;
		img {
			height: 100%;
		}

		${media.tablet`
      height: 15px;
    `}
	}
`

const SearchSpan = styled.span`
	${media.tablet`
    display: none;
  `}
`

const HeaderWrap = styled.div`
	position: fixed;
	width: ${(props) => props.theme.desktophd.col12};
	margin-left: ${(props) => props.theme.desktophd.marginHorizontal};
	height: ${(props) => props.theme.height.nav_desktop};

	color: ${(props) => {
		if (props.expanded) {
			return 'white'
		} else if (props.filterNavMobile) {
			return 'white'
		} else if (props.inverted) {
			return 'white'
		} else if (props.scrollNav) {
			return 'black'
		} else {
			return 'black'
		}
	}};

	border-bottom: ${(props) =>
		props.inverted
			? props.scrollNav
				? 'none'
				: '1px solid white'
			: props.scrollNav
			? 'none'
			: '1px solid black'};
	display: flex;
	justify-content: space-between;
	align-items: center;

	z-index: ${(props) => props.theme.z.header};
	p {
		${(props) => props.theme.desktophd.typeMixins.sans_p4};
	}

	${media.tablet`
    width: 100%;
    padding: 0 ${(props) => props.theme.tablet.marginHorizontal};
    margin: 0 auto;
  `}
	${media.mobile`
    padding: 0 ${(props) => props.theme.mobile.marginHorizontal};
  `}
`

const Column = styled.div`
	width: ${(props) => props.theme.desktophd.col4};
	display: flex;
	justify-content: ${(props) => (props.align ? props.align : 'center')};
	align-items: center;
	${media.tablet`
    width: 33%;
  `}
`

const Donate = styled.p`
	margin-right: 2em;
	color: inherit;
	a {
		color: inherit;
	}
	${media.tablet`
    display: none;
  `}
	${media.mobile`

  `}
`

const Hamburger = styled.a`
	display: flex;
	position: relative;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 30px;
	width: 30px;
	margin-left: 2rem;
	span {
		display: block;
		height: 3px;
		margin-top: 3px;
		width: 100%;
		background: ${(props) => {
			if (props.expanded) {
				return 'white'
			} else if (props.filterNavMobile) {
				return 'white'
			} else if (props.inverted) {
				return 'white'
			} else if (props.scrollNav) {
				return 'black'
			} else {
				return 'black'
			}
		}};
		transition: 0.25s all;
		top: ${(props) => (props.expanded ? '15px' : 'auto')};
		position: ${(props) => (props.expanded ? 'absolute' : 'auto')};

		&:first-child {
			transform: rotate(${(props) => (props.expanded ? '-45deg' : '0')});
		}
		&:nth-child(2) {
			display: ${(props) => (props.expanded ? 'none' : 'block')};
		}
		&:nth-child(3) {
			transform: rotate(${(props) => (props.expanded ? '45deg' : '0')});
		}
	}
`

class Header extends Component {
	state = { scrollNav: false }

	_isMounted = false

	handleScroll = (event) => {
		if (this._isMounted) {
			if (window.pageYOffset > 70 && !this.state.scrollNav) {
				window.requestAnimationFrame(() => {
					this.setState((prevState) => ({
						scrollNav: true,
					}))
				})
			} else if (window.pageYOffset < 71) {
				this.setState((prevState) => ({
					scrollNav: false,
				}))
			}
		}
	}

	componentDidMount() {
		this._isMounted = true
		window.addEventListener('scroll', this.handleScroll.bind(this))
	}

	componentWillUnmount() {
		this._isMounted = false
		window.removeEventListener('scroll', this.handleScroll.bind(this))
	}

	render() {
		return (
			<HeaderContainer
				filterNavMobile={this.props.filterNavMobile}
				inverted={this.props.inverted}
				scrollNav={this.state.scrollNav}
			>
				<HeaderWrap
					filterNavMobile={this.props.filterNavMobile}
					expanded={this.props.navExpanded}
					inverted={this.props.inverted}
					scrollNav={this.state.scrollNav}
				>
					<Column align="flex-start">
						<Link
							to="/"
							onClick={this.props.navExpanded ? this.props.toggleNav : null}
						>
							<div className="logotype-wrap">
								<img
									src={
										this.props.navExpanded || this.props.inverted
											? '/images/logotype-white.png'
											: '/images/logotype.png'
									}
									alt="PUSSYPEDIA"
								/>
								{/* <Logotype
                  inverted={this.props.navExpanded}
                  fill={this.props.navExpanded ? "white" : "black"}
                /> */}
							</div>
						</Link>
					</Column>

					<Column>
						<Link
							to="/"
							onClick={this.props.navExpanded ? this.props.toggleNav : null}
						>
							<HeaderLogo
								inverted={this.props.navExpanded}
								fill={this.props.navExpanded ? 'white' : 'black'}
							/>
						</Link>
					</Column>

					<Column align="flex-end">
						<Donate
							scrollNav={this.state.scrollNav}
							inverted={this.props.inverted}
							onClick={this.props.navExpanded ? this.props.toggleNav : null}
						>
							<Link to="/contribute">
								{getText('links.donate', this.props.language)}
							</Link>
						</Donate>

						<Link
							to="/search"
							onClick={this.props.navExpanded ? this.props.toggleNav : null}
						>
							<SearchSpan>
								<Search
									scrollNav={this.state.scrollNav}
									fill={
										this.props.inverted
											? 'white'
											: this.props.navExpanded
											? 'white'
											: 'black'
									}
								/>
							</SearchSpan>
						</Link>

						<Hamburger
							onClick={this.props.toggleNav}
							expanded={this.props.navExpanded}
							inverted={this.props.inverted}
							scrollNav={this.state.scrollNav}
							filterNavMobile={this.props.filterNavMobile}
						>
							<span />
							<span />
							<span />
						</Hamburger>
					</Column>
				</HeaderWrap>
				<Nav
					language={this.props.language}
					setLanguage={this.props.setLanguage}
					expanded={this.props.navExpanded}
					toggleNav={this.props.toggleNav}
				/>
			</HeaderContainer>
		)
	}
}

export default withRouter(Header)
