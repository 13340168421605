import React from 'react'
import { render } from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import './index.css'
import App from './App'
import registerServiceWorker from './registerServiceWorker'

const Root = () => {
	return (
		<BrowserRouter>
			<App />
		</BrowserRouter>
	)
}

render(<Root />, document.querySelector('#root'))
registerServiceWorker()
