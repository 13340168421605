import React from 'react'
import { Helmet } from 'react-helmet'

export const Meta = ({ seo, language }) => {
	const { en, es, contentType, uri, authors } = seo
	const title = language === 'spanish' && es.title ? es.title : en.title
	const image = language === 'spanish' && es.image ? es.image : en.image
	const imageUrl = image ? image.url : undefined
	const canonical = uri ? `https://www.pussypedia.net/${uri}` : undefined

	const ogAuthors = authors
		? authors.map((author) => ({
				property: 'article:author',
				content: `https://www.pussypedia.net/contributors/${author}`,
		  }))
		: []

	return (
		<Helmet
			title={title}
			meta={[
				{ name: 'description', content: en.description, lang: 'en' },
				{ name: 'description', content: es.description, lang: 'es' },
				{ property: 'og:locale', content: 'en_US' },
				{ property: 'og:locale:alternate', content: 'es_MX' },
				{ property: 'og:site_name', content: 'Pussypedia' },
				{ property: 'og:title', content: title },
				{ property: 'og:description', content: en.description },
				imageUrl ? { property: 'og:image', content: imageUrl } : null,
				{ property: 'og:type', content: contentType || 'website' },
				...ogAuthors,
				{ name: 'robots', content: 'index, follow' },
				canonical ? { property: 'og:url', content: canonical } : null,
			].filter(Boolean)}
			link={[canonical ? { rel: 'canonical', href: canonical } : null].filter(
				Boolean,
			)}
		/>
	)
}
