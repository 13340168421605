import * as React from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { fetchContributors } from '../../Utils/prismic-configuration'
import { getText } from '../../Utils/translatedText'

const ViewContainer = styled.div`
	${(props) => props.theme.layout.container};
`

const Title = styled.h1`
	${(props) => props.theme.desktophd.headerMixins.header_2};
	margin-bottom: 2.25rem;
`

const Subtitle = styled.h4`
	${(props) => props.theme.desktophd.headerMixins.header_7};
`

const GridContainer = styled.div`
	margin-top: 45px;
	${(props) => props.theme.layout.gridContainer};
	grid-row-gap: 12px;
`

const ThumbnailWrapper = styled.div`
	${({ theme }) => css`
		text-align: center;

		& a:hover {
			color: ${theme.colors.pink};
		}
	`}
`

const Name = styled.h3`
	${(props) => props.theme.desktophd.headerMixins.header_7};
`

const ContributorThumbnail = ({ contributor, language }) => {
	const to = `/contributors/${contributor.uid}`
	const currentLang = contributor[language]
	const getContent = (key) =>
		(currentLang ? currentLang[key] : null) ||
		(contributor.english ? contributor.english[key] : null) ||
		(contributor.spanish ? contributor.spanish[key] : null) ||
		contributor[key]
	return (
		<ThumbnailWrapper>
			<Name>
				<Link to={to}>
					{getContent('firstName')} {getContent('lastName')}
				</Link>
			</Name>
		</ThumbnailWrapper>
	)
}

class Contributors extends React.Component {
	state = {
		loading: true,
		contributors: [],
	}

	componentDidMount = async () => {
		const contributors = await fetchContributors()
		const sorted = contributors.sort((a, b) => {
			const aName = a.english
				? a.english.lastName || a.english.firstName
				: a.spanish
				? a.spanish.lastName || a.spanish.firstName
				: undefined
			const bName = b.english
				? b.english.lastName || b.english.firstName
				: b.spanish
				? b.spanish.lastName || b.spanish.firstName
				: undefined
			if (!aName) return 0
			if (aName < bName) return -1
			if (aName > bName) return 1
			return 0
		})
		this.setState({ contributors: sorted, loading: false })
	}

	render() {
		const { language } = this.props
		return (
			<ViewContainer>
				<Title>{getText('contributors', language)}</Title>
				<Subtitle>{getText('contributors.subtitle', language)}</Subtitle>
				<GridContainer>
					{this.state.contributors.map((c) => (
						<ContributorThumbnail
							key={c.uid}
							language={this.props.language}
							contributor={c}
						/>
					))}
				</GridContainer>
			</ViewContainer>
		)
	}
}

export default Contributors
