import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { media } from 'Styles/style-utils'

const Wrapper = styled.div`
	display: flex;
	justify-content: ${(props) =>
		props.align === 'left' ? 'flex-start' : 'center'};
	align-items: center;
	width: 100%;
	margin-top: 1.125rem;
`
const AuthorName = styled.p`
	${(props) => props.theme.desktophd.typeMixins.sans_p4};
	color: ${(props) => props.theme.colors.lightdustypink};
	margin: 0 !important;

	p {
		${media.tablet`
      margin: 1rem 0;
    `};
	}
`

const Avatar = styled.div`
	border-radius: 50%;
	margin-right: 10px;
	height: 50px;
	width: 50px;
	background: ${(props) =>
		props.background
			? `url(${props.background}) no-repeat center center`
			: null};
	background-size: contain;
	display: ${(props) => (props.background ? 'block' : 'none')};
`

const Byline = (props) => {
	const { authorName, authorAvatar, authorUid, align } = props
	const to = `/contributors/${authorUid}`
	return (
		<Wrapper align={align}>
			<Link to={to}>
				<Avatar background={authorAvatar ? authorAvatar : null} />
			</Link>
			<Link to={to}>
				<AuthorName>{authorName}</AuthorName>
			</Link>
		</Wrapper>
	)
}

export default Byline
