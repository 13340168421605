// @flow
/* eslint-disable */

import { css } from 'styled-components'
import normalized from './normalized'
import { media } from 'Styles/style-utils'

export const globalStyles = css`
	${normalized}

	@font-face {
		font-family: 'Plain';
		src: url('/fonts/Plain/Plain-Light.otf');
	}

	@font-face {
		font-family: 'LeituraNewsRoman';
		src: url('/fonts/LeituraNewsRoman/DSType - LeituraNews-Roman 1.otf');
	}

	@font-face {
		font-family: 'LeituraNewsItalic';
		src: url('/fonts/LeituraNewsItalic 1/DSType - LeituraNews-Italic 1.otf');
	}

	html {
		font-size: 18px;
		font-family: 'LeituraNewsRoman', 'Times New Roman', Times, serif;
		font-weight: 300;
	}

	form {
		margin: 0;
	}

	button,
	input,
	select,
	option,
	textarea {
		background: white;
		font-weight: 300;
		border: none;
		outline: none;
		line-height: normal;
		padding: 0;
		border-radius: 0;
		color: #454545;
	}

	label {
		color: #454545;
	}

	button {
		cursor: pointer;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p,
	li,
	ol {
		font-family: 'LeituraNewsRoman', 'Times New Roman', Times, serif;
		font-weight: 400;
		margin: 0;
	}

	ul {
		margin-block-start: 0;
		margin-block-end: 0;
		margin-inline-start: 0px;
		margin-inline-end: 0px;
		padding-inline-start: 0px;
	}

	h1 {
		font-size: 44px;
		line-height: 60px;
		letter-spacing: 0.3px;
		font-family: 'LeituraNewsRoman', 'Times New Roman', Times, serif;
		${media.tablet`
      font-size: 30px;
      line-height: 40px
    `}
		${media.mobile`
      font-size: 24px;
      line-height: 32px
    `}
	}

	h2 {
		font-size: 36px;
		line-height: 54px;
		letter-spacing: 0.3px;
		font-family: 'LeituraNewsRoman', 'Times New Roman', Times, serif;
	}

	h3 {
		font-size: 30px;
		line-height: 40px;
		letter-spacing: 0.3px;
		font-family: 'LeituraNewsRoman', 'Times New Roman', Times, serif;
		${media.tablet`
      font-size: 24px;
  `}
	}

	h4 {
		font-size: 24px;
		line-height: 30px;
		letter-spacing: 0.3px;
		font-family: 'LeituraNewsRoman', 'Times New Roman', Times, serif;
	}

	h5 {
		font-size: 20px;
		line-height: 26px;
		letter-spacing: 0.3px;
		font-family: 'LeituraNewsRoman', 'Times New Roman', Times, serif;
	}

	h6 {
		font-size: 18px;
		line-height: 36px;
		letter-spacing: 0.3px;
		font-family: 'LeituraNewsRoman', 'Times New Roman', Times, serif;
	}

	p,
	li {
		font-size: 18px;
		line-height: 1.8em;
		letter-spacing: 0.3px;
		font-family: 'LeituraNewsRoman', 'Times New Roman', Times, serif;

		${media.tablet`
      font-size: 16px;
      line-height: 24px;
  `}
	}

	ul > li + li {
		margin-top: 0.6em;
	}

	a {
		text-decoration: none;
		color: inherit;
	}

	* {
		box-sizing: border-box;
	}

	body {
		padding: 0;
	}

	#root,
	#reactRoot {
		height: 100%;
	}

	figure {
		margin: 0;
	}

	img {
		max-width: 100%;
	}

	.fade-wait-leave {
		opacity: 1;
		background: red;
		transition: all 0.5s;
	}
	.fade-wait-leave.fade-wait-leave-active {
		opacity: 0;
		transition: all 0.4s ease-in;
	}

	.fade-wait-enter {
		opacity: 1;
	}

	.fade-wait-enter.fade-wait-enter-active {
		opacity: 1;
		background: red;
		/* Delay the enter animation until the leave completes */
		transition: opacity 0.4s ease-in 0.6s;
	}

	.fade-wait-height {
		background: red;
		transition: height 10s ease-in-out;
	}

	.cross-fade-leave {
		opacity: 1;
	}
	.cross-fade-leave.cross-fade-leave-active {
		opacity: 0;
		transition: opacity 0.5s ease-in;
	}

	.cross-fade-enter {
		opacity: 0;
	}
	.cross-fade-enter.cross-fade-enter-active {
		opacity: 1;
		transition: opacity 0.5s ease-in;
	}

	.cross-fade-height {
		transition: height 10s ease-in-out;
	}
`
