import React from 'react'
import styled from 'styled-components'
import { fetchModule } from 'Utils/prismic-configuration'
import { media } from 'Styles/style-utils'
import { getText } from 'Utils/translatedText'
import { Button } from './DonateModule'
import { getLanguageData } from 'Utils/getLanguageData'

const ContributeModuleWrapper = styled.div`
	width: ${(props) => (props.width === 'full' ? '100%' : '50%')};
	min-height: 400px;
	background-color: ${(props) => props.theme.colors.darkGray};
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: ${(props) => props.theme.desktophd.marginHorizontal};
	text-align: center;
	margin-bottom: ${(props) => props.theme.desktophd.vSpace};

	${media.tablet`
    margin-bottom: ${(props) => props.theme.tablet.vSpace};
  `}

	${media.mobile`
    width: 100%;
    margin: ${(props) => props.theme.mobile.vSpace} 0;
  `}

  a {
		color: ${(props) => props.theme.colors.pink};
		${(props) => props.theme.desktophd.typeMixins.sans_p2};
	}
`

const Title = styled.p`
	${(props) => props.theme.desktophd.typeMixins.sans_p0};
	color: white;
	/* margin-bottom: 60px; */
	${media.tablet`
    ${(props) => props.theme.desktophd.typeMixins.sans_p1};
    line-height: 30px;
    text-align: center;
  `}
`

const Heading = styled.p`
	${(props) => props.theme.desktophd.typeMixins.sans_p1};
	margin-bottom: 16px;
	color: white;

	${media.tablet`
    ${(props) => props.theme.desktophd.typeMixins.sans_p3};
  `}
`

const Body = styled.p`
	${(props) => props.theme.desktophd.typeMixins.sans_p2};
	color: white;

	${media.tablet`
    ${(props) => props.theme.desktophd.typeMixins.sans_p5};
  `}
`

const FlexRow = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	a {
		margin: 0 30px;
	}
	${media.mobile`
    flex-direction: column;
  `}
`

const FlexItem = styled.div`
	padding: 20px;
	/* width: ${(props) =>
		props.width === 'full'
			? props.theme.desktophd.col4
			: props.theme.desktophd.col5}; */
	width: 100%;

	text-align: center;

	${media.mobile`
    width: 100%;
  `}
`

class ContributeModule extends React.Component {
	_isMounted = false
	state = {}

	async componentDidMount() {
		this._isMounted = true
		const raw = await fetchModule('contribute_module')

		// let cta
		// let section_1_header
		// let section_1_body
		// let section_2_header
		// let section_3_header

		const data = {}
		data.english = {
			cta: raw.english.cta[0] ? raw.english.cta[0].text : null,
			section_1_header: raw.english.section_1_header[0]
				? raw.english.section_1_header[0].text
				: null,
			section_1_body: raw.english.section_1_body[0]
				? raw.english.section_1_body[0].text
				: null,
			section_2_header: raw.english.section_2_header[0]
				? raw.english.section_2_header[0].text
				: null,
			section_3_header: raw.english.section_3_header[0]
				? raw.english.section_3_header[0].text
				: null,
		}

		data.spanish = {
			cta:
				raw.spanish.cta && raw.spanish.cta[0] ? raw.spanish.cta[0].text : null,
			section_1_header:
				raw.spanish.section_1_header && raw.spanish.section_1_header[0]
					? raw.spanish.section_1_header[0].text
					: null,
			section_1_body:
				raw.spanish.section_1_body && raw.spanish.section_1_body[0]
					? raw.spanish.section_1_body[0].text
					: null,
			section_2_header:
				raw.spanish.section_2_header && raw.spanish.section_2_header[0]
					? raw.spanish.section_2_header[0].text
					: null,
			section_3_header:
				raw.spanish.section_3_header && raw.spanish.section_3_header[0]
					? raw.spanish.section_3_header[0].text
					: null,
		}

		if (this._isMounted) {
			this.setState({ data })
		}
	}

	componentWillUnmount() {
		this._isMounted = false
	}

	render() {
		if (this.state.data) {
			const { cta, section_1_header, section_1_body } = getLanguageData(
				this.state.data,
				this.props.language,
			)
			return (
				<ContributeModuleWrapper width={this.props.width}>
					<Title>{cta}</Title>
					<FlexRow>
						<FlexItem width="full">
							<Heading>{section_1_header}</Heading>
							<Body>{section_1_body}</Body>
							<Button to="/contribute">
								{getText('links.contribute', this.props.language)}
							</Button>
						</FlexItem>
					</FlexRow>
				</ContributeModuleWrapper>
			)
		} else {
			return null
		}
	}
}

export default ContributeModule
