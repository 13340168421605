import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { media } from 'Styles/style-utils'
import { getText } from 'Utils/translatedText'

const NavWrapper = styled.div`
	width: 100vw;
	height: 100vh;
	position: fixed;
	overflow: scroll;
	top: 0;
	left: 0;
	background: black;
	color: white;
	z-index: ${(props) => props.theme.z.nav};
	display: ${(props) => (props.expanded ? 'flex' : 'none')};
	flex-direction: column;
	padding: ${(props) => props.theme.desktophd.col1};
	padding-top: calc(
		${(props) => props.theme.height.nav_desktop} +
			${(props) => props.theme.desktophd.vSpace}
	);

	a {
		margin-bottom: 0.5rem;
	}

	.inner-nav-wrapper {
		${media.tablet`
      height: auto;
    `}

		${media.mobile`
      padding-bottom: 100px;
    `}
	}

	h3 {
		${(props) => props.theme.desktophd.headerMixins.header_4};
		&:hover {
			color: ${(props) => props.theme.colors.pink};
		}

		${media.tablet`
      ${(props) => props.theme.desktophd.typeMixins.serif_p1};
      line-height: 48px;

      &:hover {
        color: white;
      }
    `}
	}

	${media.tablet`
    padding-top: calc( ${(props) => props.theme.height.nav_desktop} + ${(
		props,
	) => props.theme.tablet.vSpace});
  `}

	${media.mobile`
    padding-top: calc( ${(props) => props.theme.height.nav_desktop} + ${(
		props,
	) => props.theme.mobile.vSpace});
  `}
`

const Column = styled.div`
	width: ${(props) => props.theme.desktophd.col10};
	margin: 0 auto;
	margin-bottom: ${(props) => props.theme.desktophd.vSpace};
	display: flex;
	justify-content: space-between;

	${media.tablet`
    width: ${(props) => props.theme.tablet.col8};
  `}
	${media.mobile`
    width: ${(props) => props.theme.mobile.col4};
    flex-direction: column;
    margin-bottom: 0;
  `}
`

const Block = styled.div`
	width: ${(props) => props.theme.desktophd.col4};

	h3 {
		margin-bottom: 0.5rem;
	}

	${media.tablet`
    width: ${(props) => props.theme.tablet.col4};
  `}
	${media.mobile`
    width: ${(props) => props.theme.mobile.col4};
  `}
`

const LanguageWrap = styled.div`
	display: flex;
	align-items: center;

	select {
		margin-left: 20px;
		background: ${(props) => props.theme.colors.middleGray};
		${(props) => props.theme.desktophd.typeMixins.sans_p2};
		color: white;
		width: 175px;
		height: 50px;
	}
`

// TODO: UPDATE ROUTES
const Nav = (props) => (
	<NavWrapper expanded={props.expanded}>
		<div className="inner-nav-wrapper">
			<Column>
				<Block>
					<Link
						to="/interactive-model"
						onClick={props.expanded ? props.toggleNav : null}
					>
						<h3>{getText('links.3dPussy', props.language)}</h3>
					</Link>
					<Link to="/tags" onClick={props.expanded ? props.toggleNav : null}>
						<h3>{getText('tags.indexLabel', props.language)}</h3>
					</Link>
					<Link to="/topics" onClick={props.expanded ? props.toggleNav : null}>
						<h3>{getText('links.topics', props.language)}</h3>
					</Link>
				</Block>

				<Block>
					<Link to="/about" onClick={props.expanded ? props.toggleNav : null}>
						<h3>{getText('links.about', props.language)}</h3>
					</Link>
					<Link to="/contact" onClick={props.expanded ? props.toggleNav : null}>
						<h3>{getText('links.contact', props.language)}</h3>
					</Link>
				</Block>
			</Column>
			<Column>
				<Block>
					<Link
						to="/contribute"
						onClick={props.expanded ? props.toggleNav : null}
					>
						<h3>{getText('links.donate', props.language)}</h3>
					</Link>
				</Block>
				<Block>
					<Link to="/search" onClick={props.expanded ? props.toggleNav : null}>
						<h3>{getText('links.search', props.language)}</h3>
					</Link>
					<LanguageWrap>
						<h3>{getText('language', props.language)}</h3>
						<select
							value={props.language}
							onChange={(e) => {
								props.setLanguage(e)
								props.toggleNav()
							}}
						>
							<option value="english">
								{getText('languages.english', props.language)}
							</option>
							<option value="spanish">
								{getText('languages.spanish', props.language)}
							</option>
						</select>
					</LanguageWrap>
				</Block>
			</Column>
		</div>
	</NavWrapper>
)

export default Nav
