import Cookies from 'js-cookie'

export const setCookie = (cookieName, value, expires = 7) => {
	Cookies.set(cookieName, JSON.stringify(value), { expires })
}

export const getCookie = (cookieName) => {
	const json = Cookies.get(cookieName)
	if (json) return JSON.parse(json)
	return null
}
