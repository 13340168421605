import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { media } from 'Styles/style-utils'
import { SansP4, SansP5 } from 'Styles/type'
import { Facebook, Instagram, Twitter } from 'Components/Icons'
import { getText } from 'Utils/translatedText'

const FooterWrapper = styled.div`
	background-color: ${(props) => props.theme.colors.lightpink};
	color: black;
	display: flex;
	justify-content: space-between;
	padding: 96px ${(props) => props.theme.desktophd.marginHorizontal};

	.logotype-wrap {
		height: 15px;
		img {
			height: 100%;
		}
	}

	${media.tablet`
    padding: ${(props) => props.theme.tablet.vSpace}  ${(props) =>
		props.theme.tablet.marginHorizontal};
  `}

	${media.mobile`
    padding: ${(props) => props.theme.mobile.vSpace} ${(props) =>
		props.theme.mobile.marginHorizontal};
    flex-direction: column;
  `}
`

const FooterFlex = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	p {
		margin-right: 32px;
		display: inline-block;
	}
	${media.mobile`
    display: none;
  `}
`

const MobileFooterFlex = styled(FooterFlex)`
	display: none;

	${media.mobile`
    display: flex;
  `}
`

const ColPussypedia = styled.div`
	flex-grow: 1;
`

const PussypediaInner = styled.div`
	margin-bottom: 48px;
	width: ${(props) => props.theme.desktophd.col4};

	& > ${SansP5} {
		margin-bottom: 1em;
		line-height: 1.5em;
	}

	${media.tablet`
    width: ${(props) => props.theme.tablet.col4};
  `}

	${media.mobile`
    width: ${(props) => props.theme.mobile.col4};
    margin-bottom: ${(props) => props.theme.mobile.vSpace};
  `}
`

const ColSiteMap = styled.div`
	width: ${(props) => props.theme.desktophd.col4};
	margin-right: ${(props) => props.theme.desktophd.gutter};
	display: flex;
	${media.tablet`
    flex-direction: column;
      width: ${(props) => props.theme.tablet.col3};
  `}
	${media.mobile`
    width: 100%;
    margin-bottom: ${(props) => props.theme.mobile.vSpace};

  `}
`

const InnerHalf = styled.div`
	width: 50%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;

	${media.tablet`
    width: 100%;
  `}
`
const ColSocials = styled.div`
	width: ${(props) => props.theme.desktophd.col2};
	text-align: right;
	svg {
		margin-left: 18px;
	}
	${media.tablet`
    display: none;
  `}
	${media.mobile`
    text-align: center;
    display: block;
    width: ${(props) => props.theme.mobile.col4};
    margin: 0 auto;
    margin-bottom: ${(props) => props.theme.mobile.vSpace};
  `}
`

const TabletSocials = styled.div`
	display: none;
	margin: ${(props) => props.theme.tablet.vSpace} 0;
	svg {
		margin-right: 30px;
	}
	${media.tablet`
    display: block;
  `}
	${media.mobile`
    display: none;
  `}
`

const LanguageWrap = styled.div`
	display: flex;
	align-items: center;
	position: relative;
	z-index: 10;

	${media.mobile`
    justify-content: space-between;
    width: 100%;
  `}

	select {
		margin-left: 20px;
		background: ${(props) => props.theme.colors.middleGray};
		${(props) => props.theme.desktophd.typeMixins.sans_p2};
		color: white;
		width: 125px;
		height: 40px;
		font-size: 12px;

		${media.tablet`
        ${(props) => props.theme.desktophd.typeMixins.sans_p5};
    `}
	}
`

const currentYear = new Date().getFullYear()

const Footer = (props) => (
	<FooterWrapper>
		<ColPussypedia>
			<PussypediaInner>
				<div className="logotype-wrap">
					<img src="/images/logotype.png" alt="PUSSYPEDIA" />
				</div>
				<br />
				<SansP5>{getText('description', props.language)}</SansP5>
				<SansP5>{getText('disclaimer', props.language)}</SansP5>
				<img src="/images/by-nc-nd.svg" alt="Creative Commons" />
			</PussypediaInner>

			<TabletSocials>
				<Facebook />
				<Twitter />
				<Instagram />
			</TabletSocials>
			<FooterFlex>
				<SansP4>© {currentYear} Pussypedia</SansP4>
				<SansP4>
					<Link to="/terms-of-use">
						{getText('links.terms', props.language)}
					</Link>
				</SansP4>
				<SansP4>
					<Link to="/privacy-policy">
						{getText('links.privacy', props.language)}
					</Link>
				</SansP4>
			</FooterFlex>
		</ColPussypedia>
		<ColSiteMap>
			<InnerHalf>
				<Link to="/interactive-model">
					<SansP4>{getText('links.3dPussy', props.language)}</SansP4>
				</Link>
				<Link to="/topics">
					<SansP4>{getText('links.topics', props.language)}</SansP4>
				</Link>
				{/*<Link to="/contributors">
				 	<SansP4>{getText('contributors', props.language)}</SansP4>
				 </Link> */}
				<Link to="/about">
					<SansP4>{getText('links.about', props.language)}</SansP4>
				</Link>

				<Link to="/contact">
					<SansP4>{getText('links.contact', props.language)}</SansP4>
				</Link>
			</InnerHalf>
			<InnerHalf>
				<Link to="/donate">
					<SansP4>{getText('links.donate', props.language)}</SansP4>
				</Link>
				<Link to="/search">
					<SansP4>{getText('links.search', props.language)}</SansP4>
				</Link>

				<LanguageWrap>
					<SansP4>{getText('language', props.language)}</SansP4>
					<select
						value={props.language}
						onChange={(e) => {
							props.setLanguage(e)
						}}
					>
						<option value="english">
							{getText('languages.english', props.language)}
						</option>
						<option value="spanish">
							{getText('languages.spanish', props.language)}
						</option>
					</select>
				</LanguageWrap>
			</InnerHalf>
		</ColSiteMap>
		<ColSocials>
			<a
				rel="noopener noreferrer"
				target="_blank"
				href="https://www.facebook.com/pwordpedia"
			>
				<Facebook />
			</a>
			<a
				rel="noopener noreferrer"
				target="_blank"
				href="https://twitter.com/pwordpedia"
			>
				<Twitter />
			</a>
			<a
				rel="noopener noreferrer"
				href="https://www.instagram.com/pwordpedia/"
				target="_blank"
			>
				<Instagram />
			</a>
		</ColSocials>
		<MobileFooterFlex>
			<SansP4>© 2018 Pussypedia</SansP4>
			<SansP4>{getText('links.terms', props.language)}</SansP4>
			<SansP4>{getText('links.privacy', props.langauge)}</SansP4>
		</MobileFooterFlex>
	</FooterWrapper>
)

export default Footer
