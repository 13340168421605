// @flow

import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Byline from 'Components/Byline'
import { media } from 'Styles/style-utils'
import { fetchSimpleArticle } from 'Utils/prismic-configuration'
import { getLanguageData } from 'Utils/getLanguageData'

const Wrapper = styled.div`
	width: ${(props) => props.theme.desktophd.col12};
	background: url(${(props) => props.hero_image}) no-repeat center center;
	background-size: cover;
	text-align: center;
	padding-bottom: ${(props) => props.theme.desktophd.vSpace};

	img {
		width: 100%;
	}

	${media.tablet`
    width: ${(props) => props.theme.tablet.col8};
    margin: ${(props) => props.theme.tablet.vSpace} 0;
  `}
	${media.mobile`
    width: ${(props) => props.theme.mobile.col4};
    margin: ${(props) => props.theme.mobile.vSpace} 0;
  `}
`

const Title = styled.h3`
	margin-top: 1.5rem;
	margin-bottom: 2rem;
`

const Subtitle = styled.p`
	${(props) => props.theme.desktophd.typeMixins.sans_p2};
	margin-bottom: 25px;

	${media.tablet`
    ${(props) => props.theme.tablet.typeMixins.sans_p2};
  `}
	${media.mobile`
    ${(props) => props.theme.mobile.typeMixins.sans_p2};
  `}
`

const Small = styled.p`
	${(props) => props.theme.desktophd.typeMixins.sans_p5};
	color: ${(props) => props.theme.colors.lightGray};
	margin-top: 0.5rem;
	margin-bottom: 1.5rem;

	${media.tablet`
    ${(props) => props.theme.tablet.typeMixins.sans_p5};
  `}
	${media.mobile`
    ${(props) => props.theme.mobile.typeMixins.sans_p5};
  `}
`

class ArticleModuleFullBleed extends React.Component {
	_isMounted = false

	state = {}

	async componentDidMount() {
		this._isMounted = true
		const { uid } = this.props
		const raw = await fetchSimpleArticle(uid, this.props.api)
		const data = {}

		const { authorAvatar, authorUid, authorName, hero_image } = raw.english

		data.english = {
			uid,
			authorAvatar,
			authorName,
			authorUid,
			hero_image,
			title: raw.english.title || null,
			subtitle: raw.english.subtitle || null,
			blurb: raw.english.blurb || null,
			illustration_byline: raw.english.illustration_byline || null,
		}

		data.spanish = raw.spanish
			? {
					title: raw.spanish.title || null,
					subtitle: raw.spanish.subtitle || null,
					blurb: raw.spanish.blurb || null,
					illustration_byline: raw.spanish.illustration_byline || null,
			  }
			: null

		if (this._isMounted) {
			this.setState({ data })
		}
	}
	componentWillUnmount() {
		this._isMounted = false
	}

	render() {
		if (this.state.data) {
			const { uid, authorAvatar, authorName, authorUid, hero_image } =
				this.state.data.english
			const { subtitle, title, illustration_byline } = getLanguageData(
				this.state.data,
				this.props.language,
			)
			return (
				<Wrapper>
					<Link to={`/articles/${uid}`}>
						<img src={hero_image} alt="" />
						<Small>{illustration_byline}</Small>
						{/* <ContentType>Article</ContentType> */}
						<Title>{title}</Title>
						<Subtitle>{subtitle}</Subtitle>
						<Byline
							authorUid={authorUid}
							authorName={authorName}
							authorAvatar={authorAvatar}
						/>
					</Link>
				</Wrapper>
			)
		} else {
			return null
		}
	}
}

export default ArticleModuleFullBleed
