// @flow

import React, { Component } from 'react'
import styled from 'styled-components'
import Prismic from 'prismic-javascript'
import { media } from 'Styles/style-utils'

import {
	ArticleModuleHero,
	ArticleModuleFullWidth,
	ArticleModuleFullBleed,
	ArticleModuleHalfWidth,
} from 'Components/ArticleModule'
import {
	ContributeModule,
	DonateModule,
	MailingListModule,
	PussyDefinitionModule,
	ShopModule,
} from 'Components/Modules'
import { Meta } from 'Components/Meta'

import { apiEndpoint, fetchHome } from 'Utils/prismic-configuration'

const HomepageWrapper = styled.div`
	padding-top: calc(
		${(props) => props.theme.height.header} +
			${(props) => props.theme.desktophd.vSpace}
	);
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	& > *:last-child {
		margin-bottom: 0;
	}

	${media.tablet`
    padding-top: calc(
      ${(props) => props.theme.height.header} +
        ${(props) => props.theme.tablet.vSpace}
    );
  `} ${media.mobile`
    padding-top: calc(
      ${(props) => props.theme.height.header} +
        ${(props) => props.theme.mobile.vSpace}
    );
  `};
`

class Homepage extends Component {
	state = {}

	api = null

	async componentDidMount() {
		window.scrollTo(0, 0)

		this.api = await Prismic.api(apiEndpoint)

		const { body, seo } = await fetchHome()
		this.setState({ seo, slices: body })
	}

	render() {
		if (this.state.slices) {
			return (
				<HomepageWrapper>
					<Meta seo={this.state.seo} language={this.props.language} />
					{this.state.slices.map((slice, index) => {
						switch (slice.slice_type) {
							case 'contribute':
								return (
									<ContributeModule
										language={this.props.language}
										key={`contribute${index}`}
										width={slice.primary.width}
									/>
								)
							case 'donate':
								return (
									<DonateModule
										language={this.props.language}
										key={`donate${index}`}
										width={slice.primary.width}
									/>
								)
							case 'mailing_list':
								return (
									<MailingListModule
										language={this.props.language}
										key={`mailing_list${index}`}
										width={slice.primary.width}
									/>
								)
							case 'pussy_definition':
								return (
									<PussyDefinitionModule
										language={this.props.language}
										key={`pussy_definition${index}`}
										width={slice.primary.width}
									/>
								)
							case 'shop':
								return (
									<ShopModule
										data={this.state.shop}
										language={this.props.language}
										key={`shop${index}`}
										width={slice.primary.width}
									/>
								)
							case 'article_module':
								switch (slice.primary.module_type) {
									case 'hero':
										if (slice.primary && slice.primary.article.uid) {
											return (
												<ArticleModuleHero
													api={this.api}
													language={this.props.language}
													key={`${slice.primary.article.uid}hero${index}`}
													uid={slice.primary.article.uid}
													module_type={slice.primary.module_type}
												/>
											)
										} else {
											return null
										}

									case 'full_bleed':
										if (slice.primary && slice.primary.article.uid) {
											return (
												<ArticleModuleFullBleed
													api={this.api}
													language={this.props.language}
													key={`${slice.primary.article.uid}hero${index}`}
													uid={slice.primary.article.uid}
													module_type={slice.primary.module_type}
												/>
											)
										} else {
											return null
										}

									case 'full_width':
										if (slice.primary && slice.primary.article.uid) {
											return (
												<ArticleModuleFullWidth
													api={this.api}
													language={this.props.language}
													key={`${slice.primary.article.uid}hero${index}`}
													uid={slice.primary.article.uid}
													module_type={slice.primary.module_type}
												/>
											)
										} else {
											return null
										}

									case 'half_width':
										if (slice.primary && slice.primary.article.uid) {
											return (
												<ArticleModuleHalfWidth
													api={this.api}
													language={this.props.language}
													key={`${slice.primary.article.uid}hero${index}`}
													uid={slice.primary.article.uid}
													module_type={slice.primary.module_type}
												/>
											)
										} else {
											return null
										}
									default:
										return null
								}
							default:
								return null
						}
					})}
				</HomepageWrapper>
			)
		} else return ''
	}
}

export default Homepage
